import React from "react";
import styles from "../../../../styles/catv.module.css";
function CustomerInfo() {
  return (
    <>
        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td width="20" align="left" valign="top">
                <br />
              </td>
              <td align="left" valign="top">
                <p>
                  <b>
                    <span className={styles.sectionhead}>
                      CATV Licensee Contact Information
                      <br />
                      <br />
                    </span>
                  </b>
                  Listed below is the current Licensee contact information on
                  record with National Grid. Please review to insure your
                  contact information is accurate and up to date. Licensees may
                  submit corrections or updates to their contact information via
                  the email link to the right. National Grid utilizes the
                  licensee information below for all communication with the
                  Licensees (except for application specific correspondence). IT
                  IS INCUMBENT ON THE LICENSEE TO CONFIRM AND MAINTAIN THEIR
                  CONTACT INFORMATION REPORTED ON NATIONAL GRID&#8217;S WEBPAGE.
                  Licensee failure to confirm and maintain correct contact
                  information may result in licensee failing to recieve
                  important information relative to their attachments, the
                  Agreement, or the National Grid pole attachment licensing
                  process.
                </p>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr align="left" valign="top">
                      <td>
                        <p>
                          <b>Atlantic Broadband LLC </b>
                          <br />
                          24 Main St.
                          <br />
                          Bradford, PA 16701 <br />
                          Michael Papasergi (814) 368-8590 <br />
                          <br />
                          <b>Berkshire Cable</b> <br />
                          PO Box 405 <br />
                          19 Broad St. <br />
                          Kinderhook, NY 12106 <br />
                          Peter Mercer (518) 392-1265 <br />
                          <br />
                          <b>Castle Cable TV</b> <br />
                          PO Box 339 <br />
                          Hammond, NY 13646 <br />
                          Donald Ceresoli, Jr. (315) 324-5911 <br />
                          <br />
                          <b>Charter, Commmmunications Plattsburgh </b> <br />
                          PO Box 570 <br />
                          66 Bridge St. <br />
                          Plattsburgh, NY 12901 <br />
                          Daniel Rushford (800) 285-8545 <br />
                          <br />
                          <b>Charter Commmunications Chatham </b> <br />
                          207 Tuckie Road <br />
                          North Windham, CT 06256 <br />
                          Robert Brown (060) 456-8346 ext.3088 <br />
                          <br />
                          <b>Citizens Cablevision </b> <br />
                          PO Box 217 <br />
                          Hammond, NY 13646 <br />
                          Donald Ceresoli, Jr. (315) 324-5911 <br />
                          <br />
                          <b>CH Communications </b> <br />
                          9507 Cherokee Trail <br />
                          Crossville, TN 38572 <br />
                          Chuck Himmelrick (866) 788-5261 <br />
                          <br />
                          <b>DWS Construction</b> <br />
                          PO Box 1113 <br />
                          4 Balsam Ave. <br />
                          Tupper Lake, NY 12986 <br />
                          Terry LaQuay (800) 371-9139
                          <br />
                        </p>
                      </td>

                      <td width="10">&nbsp;</td>
                      <td>
                        <p>
                          <b>El-Mar Communications </b> <br />
                          82 Boss St. <br />
                          Bolivar, NY 14715 <br />
                          Bernie Adams (585) 928-2800 <br />
                          <br />
                          <b>Haefele TV </b> <br />
                          PO Box 312 <br />
                          24 E. Tioga St. <br />
                          Spencer, NY 14883 <br />
                          Lee Haefele (607) 589-6235 <br />
                          <br />
                          <b>Hamilton County Cable TV</b> <br />
                          239 Assembly Pt. Road <br />
                          Lake George, NY 12845 <br />
                          Paul Schoenwolf (518) 381-4832 <br />
                          <br />
                          <b>Mid-Hudson Cablevision</b> <br />
                          PO Box 399 <br />
                          200 Jefferson Heights <br />
                          Catskill, NY 12414 <br />
                          James Reynolds (518) 943-6653 <br />
                          <br />
                          <b>MidTel Cable TV</b> <br />
                          103 Cliff St. <br />
                          PO Box 191 <br />
                          Middleburgh, NY 12122 <br />
                          James Becker (518) 827-5211 <br />
                          <br />
                          <b>Princetown Cable </b> <br />
                          878 Ennis Rd. <br />
                          Schenectady, NY 12306 <br />
                          James D'Allaird (518) 887-5500 <br />
                          <br />
                          <b>Time Warner Cable Albany Division</b> <br />
                          1021 Highbridge Rd. <br />
                          Schenectady, NY 12303 <br />
                          Charlie O'Brien (518) 242-8832 <br />
                          <br />
                          <b>Time Warner Cable Massena Division</b> <br />
                          277 Andrews St. <br />
                          Massena, NY 13662 <br />
                          315-764-0244 <br />
                          <br />
                          {/* Add other rows of licensee contact information in a similar manner */}
                        </p>
                      </td>
                      <td width="10">&nbsp;</td>
                      <td>
                        <p>
                          <b>Time Warner Cable Binghamton Division</b> <br />
                          483 Plaza Dr. <br />
                          Vestal, NY 13850 <br />
                          Jeff Bohne (607) 644-0025, Ext. 8241 <br />
                          <br />
                          <b>Time Warner Cable- Buffalo </b> <br />
                          355 Chicago St. <br />
                          Buffalo, NY 14204 <br />
                          (716) 827-9444 <br />
                          <br />
                          <b>Time Warner Cable – Glens Falls</b> <br />
                          227 Dix Ave <br />
                          Glens Falls, NY 12801 <br />
                          (518) 793-3835 <br />
                          <br />
                          <b>
                            Time Warner Cable – WNY Division (Rochester){" "}
                          </b>{" "}
                          <br />
                          71 Mt. Hope Ave. <br />
                          Rochester, NY 14620 <br />
                          Jeannie Cotton (Jeannie.cotton@twcable.com) <br />
                          (585) 756-1301 <br />
                          <br />
                          <b>Time Warner Cable – Saranac Lake </b> <br />
                          P.O. Box 707 <br />
                          488 Broadway <br />
                          Saranac Lake, NY 12983 <br />
                          Technical Supervisor: <br />
                          (510) 891-1296 <br />
                          <br />
                          <b>Time Warner Cable Syracuse Division</b> <br />
                          6005 Pair Lakes Rd. <br />
                          East Syracuse, NY 13057 <br />
                          Hendy Hryckiewicz <br />
                          (315) 634-6352 <br />
                          <br />
                          <b>Time Warner Cable Utica </b> <br />
                          1000 Firehouse Rd. <br />
                          Utica, NY 13502 <br />
                          Linda Henry (315) 797-9724 <br />
                          <br />
                          <b>Valstar </b> <br />
                          PO Box 352 <br />
                          210 Main St. <br />
                          Germantown, NY 12526 <br />
                          Joel Bohnsack (518) 537-6257 <br />
                          <br />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td width="136" align="left" valign="top">
                <br />
                <a href="index.asp">
                  <img
                    src="/images/catv/button_back.gif"
                    width="51"
                    height="15"
                    border="0"
                    alt="back"
                  />
                </a>
                <br />
                <br />
                <a href="../update_contactinfo.asp?type-CATV">
                  <img
                    src="/images/catv/contact_card.gif"
                    width="132"
                    height="99"
                    border="0"
                    alt="update"
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
    </>
  );
}

export default CustomerInfo;
