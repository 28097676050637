
import React from 'react';
import Helmet from 'react-helmet';
import imgSpacer from '../../../../assets/images/spacer.gif';
import Img_download from '../../../../assets/images/download.gif';
import Img_bullet_square from '../../../../assets/images/bullet_square.gif';

function CustoDial() {
    return (
        <div>
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div style={{ paddingLeft: '20px', fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                <div className='row'>
                    <div style={{ width: '95%' }}>
                        <div style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                            <span className="Catv_PageHead"><b>CLEC - Custodial Areas with Verizon</b></span>
                        </div>
                        Within the joint use pole agreement between National Grid and Verizon NY, Inc., custodial areas have been established in reference to billing of Third Party pole attachments on jointly owned poles. Custodial Areas are assigned by tax district (municipality) within the common NMPC-Verizon service areas. Custodial assignments do not exist with any other of National Grid's joint pole owners. Notwithstanding custodial assignment, application for attachment must be submitted to each pole owner (National Grid and Verizon) and each pole owner grants a license for the attachment.
                    </div>
                </div>


                <div className='row'>
                    <img src={imgSpacer} width="20" alt="space" height="20" />
                    <p>
                        <b>Verizon / National Grid Custodial Assignments </b>
                        <br />
                        NG = National Grid revised 11/20/07
                    </p>

                    <div style={{ paddingLeft: '30px', maxWidth: '300px' }}>
                        <div className="row">
                            <div style={{ maxWidth: '1%' }} >
                                <img src={Img_bullet_square} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />
                            </div>

                            <div style={{ maxWidth: '76%' }} >
                                <b>View Alphabetized List Sort</b>
                            </div>
                            <div style={{ maxWidth: '10%', paddingLeft: '5px' }} >
                                <a href="../../../../xls/1207/Custodial Assignment List - Alpha Sort(rev.11_20_07).xls">
                                    <img src={Img_download} width="22" height="22" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div className="row" style={{ paddingTop: '10px' }}>
                            <div style={{ maxWidth: '1%' }} >
                                <img src={Img_bullet_square} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />
                            </div>

                            <div style={{ maxWidth: '76%' }} >
                                <b>View Tax District List Sort</b>
                            </div>
                            <div style={{ maxWidth: '10%', paddingLeft: '5px' }} >
                                <a href="../../../../xls/1207/Custodial List - Taxdistr Sort(rev.11_20_07).xls">
                                    <img src={Img_download} width="22" height="22" alt="download" border="0" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustoDial;