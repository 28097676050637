
import React ,{ useState ,useRef} from "react";
import myImage from './../../assets/images/metro-gas-map.jpg';
import xml2js from 'xml2js';
import { Helmet } from 'react-helmet';
import '../../styles/GasLeakMap.css';

function GasLeakMapNYM() {
  const [iframeSrc, setIframeSrc] = useState('');
  const [zipCodeValue, setZipCodeValue] = useState('');
  const [error, setError] = useState(null);
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    // When the iframe content loads, scroll to it
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validateZipCode = (zip) => {
    return /^\d{5}$/.test(zip);
  };

  const handleInputChange = (event) => {
    setZipCodeValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick(); // Trigger the "Find" button click
    }
  };
  
  const displayError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError('');
  };

  const loadAndParseXML = (zipCode) => {
    const cacheKey = 'cachedXmlData';
    const cacheTimestampKey = 'cachedXmlDataTimestamp';
  
    // Check if cached data exists and if it's not older than 30 minutes
    const cachedXmlData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
    if (cachedXmlData && cachedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(cachedTimestamp) < 30 * 60 * 1000) {
        // Use the cached data and return the result of parseXmlData
        return parseXmlData(cachedXmlData, zipCode);
      }
    }
  
    // If no valid cached data, fetch and parse the XML data
    return fetch('./xml/ApplicationMessage.xml')
      .then((response) => response.text())
      .then((xmlData) => {
        // Cache the parsed data and timestamp
        localStorage.setItem(cacheKey, xmlData);
        localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
        return parseXmlData(xmlData, zipCode); // Return the result of parseXmlData
      })
      .catch((error) => {
        console.error('Error fetching XML data:', error);
        return false; // Return false in case of an error
      });
  };
  

  // Parse and store the XML data
  const parseXmlData = (xmlData, zipCode) => {
    return new Promise((resolve, reject) => {
      xml2js.parseString(xmlData, (err, result) => {
        if (err) {
          console.error('Error parsing main XML:', err);
          reject(err); // Reject the Promise if there's an error
        }
  
        const safetyAndOutageTag = 'SafetyandOutage';
        const gasLeakMapTag = 'GasLeakMap';
        const zipCodeTag = 'ZipCodeNYM';
  
        if (
          result &&
          result.Errors &&
          result.Errors[safetyAndOutageTag] &&
          result.Errors[safetyAndOutageTag][0] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0][zipCodeTag]
        ) {
          const zipCodes = result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0][zipCodeTag][0];
          const zipCodeArray = zipCodes.split(',').map((zipcode) => zipcode.trim());
          resolve(zipCodeArray.includes(zipCode)); // Resolve the Promise with the result
        } else {
          resolve(false); // Resolve with false if the data is not as expected
        }
      });
    });
  };

  // Function to load and parse the ExternalLinks.xml file
  const loadAndParseExternalLinksXML = (zipCode) => {
    const cacheKey = 'cachedExternalLinksXmlData';
    const cacheTimestampKey = 'cachedExternalLinksXmlDataTimestamp';
    // Check if cached data exists and if it's not older than 30 minutes
    const cachedXmlData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
    if (cachedXmlData && cachedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(cachedTimestamp) < 30 * 60 * 1000) {
        // use the cached data
        parseExternalLinksXmlData(cachedXmlData,zipCode);
        return;
      }
    }
    // If no valid cached data, fetch and parse the XML data
    fetch('./xml/LinksToExternalURL.xml')
      .then((response) => response.text())
      .then((xmlData) => {
        // Parse and store the XML data
        parseExternalLinksXmlData(xmlData,zipCode);
        // Cache the parsed data and timestamp
        localStorage.setItem(cacheKey, xmlData);
        localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
      })
      .catch((error) => {
        console.error('Error fetching ExternalLinks XML data:', error);
      });
  };

  const parseExternalLinksXmlData = (xmlData,zipCode) => {
    xml2js.parseString(xmlData, (err, result) => {
      if (err) {
        console.error('Error parsing ExternalLinks XML:', err);
        return;
      }
      const commonTag = 'Common';
      const gasLeakMapNYMTag = 'GasLeakMapNYM';
      if (result && result.ExternalLinks && result.ExternalLinks[commonTag]) {
        const gasLeakMapNYMData = result.ExternalLinks[commonTag][0][gasLeakMapNYMTag][0];
        setIframeSrc(gasLeakMapNYMData + zipCode);
      }
    });
  };

  const handleButtonClick = () => {
    if (validateZipCode(zipCodeValue)) {
        clearError();
     loadAndParseXML(zipCodeValue)
     .then((result) => {
       if (result) {
        loadAndParseExternalLinksXML(zipCodeValue);
       } else {
        displayError("The zip code you entered is not part of National Grid’s gas service territory. Check with your gas service provider for more information.");
       }
     })
    } else {
      displayError("Please enter a valid 5-digit Zip Code.");
    }
  };


return (
  <div className="row body">
      <Helmet>
        <title>Gas leak map</title>
      </Helmet>
      <div className="col">
  <div className="row" style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif', padding: '12px 180px 0px 20px',color: 'rgb(102, 102, 102)' }}>
    <div className="whitespacer"></div>
    <div className="col-md-6">
      <div className="twoColoumContentArea section">
        <div style={{ fontSize: '17px', fontWeight: 300, lineHeight: '22px' }}>
          Our gas leak map shows all reported outdoor gas leaks on streets in National Grid's gas service territory. All gas leaks are made safe immediately and are repaired or inspected periodically based on their severity.
        </div>
        <div className="whitespacer"></div>
        <div>
          By entering this part of our site, you understand that your information, including, but not limited to, your IP address may be disclosed to a third party. By entering your zip code and clicking "View Map" below, you consent to this tracking and subsequent disclosure.
          <div className="whitespacer"></div>
          Search by zip code to find all the leaks in your neighborhood.
        </div>
        <div className="whitespacer"></div>
        <div id="dvErrorSection" className={error ? 'error' : 'hidden'}>
   <h4>There are errors or missing information in your form.</h4> 
   <div className="noramBlacktext"></div> 
   {error && <span>{error}</span>}
  </div>
          <div className="twoColoumContentArea section">
          <div className="rows row1">
      <div className="col2" style={{width: '360px'}}>
      <div className="description">
         <div className="floatLeft">
         <input type="text" className="textbox_gasleak" placeholder="Enter Zip Code" id="zipCode" name="zipCode" value={zipCodeValue} onChange={handleInputChange} onKeyPress={handleEnterKeyPress}/>
         </div>
         <div className="floatLeft paddingLeft10px" style={{verticalAlign: 'middle'}}>
         <button className='button_gasleak' onClick={handleButtonClick}>View Map</button>< br/>
      </div>
      <div className="whitespacer"></div>
    </div>
    <div className="whitespacer"></div>
    <div className="whitespacer"></div>
    <div className="whitespacer"></div>
</div>
</div>

            <div className="rows row1">
              <div className="col2">
                <div className="description">
                  <div className="floatLeft">
                    For more information, visit our <a href="https://www.nationalgridus.com/NY-Home/Natural-Gas-Safety" style={{ fontSize: '13px' }} target="_blank" rel="noreferrer">Gas Safety</a> questions? See common <a href="./pdf/NationalGrid_FAQs_Mapping_NYC.pdf" target="./pdf/NationalGrid_FAQs_Mapping_NYC.pdf" rel="noreferrer" style={{ fontSize: '13px' }}>FAQs</a>.
                  </div>
                  <div className="whitespacer" style={{ clear: 'both' }}></div>
                </div>
              </div>
            </div>
      </div>
    </div>
        </div>
        <div className="col-md-1"></div>
    <div className="col-md-3">
      <div className="d-flex justify-content-center">
        <div className="center_content">
          <h3 className="GasLeak_h3">Smell Gas? Act Fast.</h3>
          <div className="whitespacer"></div>
          <p>
            If you smell gas, we urge you to report it, even if you think it's already reflected on this map. There could be a new or worsening leak, and we need to check it out right away. Call 911 or 1-718-643-4050 in Metro NY.
          </p>
          <div className="whitespacer"></div>
          <p>We are available 24 hours a day, 7 days a week.</p>
        </div>
      </div>
    </div>
    <div className="cBoth"></div>
      <div>
        <img className="img-fluid float-left" src={myImage} alt="Gas Service Territory" />
      </div>
    <div ref={iframeRef}>
        {!iframeSrc || error ? (
          <div className="whitespacer"></div>
        ) : (
          <iframe
            title="Embedded Content"
            src={iframeSrc}
            width="925px"
            height="720px"
            onLoad={handleIframeLoad}
          />
        )}
      </div>
  </div>
</div>
</div>
  );
}
export default GasLeakMapNYM;
