import React from "react";
import Img_bullet_triangle from '../../../assets/images/bullet_triangle.gif';
import styles from '../../../styles/catv.module.css'

const SafetyComponent = () => {
  return (
    <div>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="20" align="left" valign="top">
              &nbsp;
            </td>
            <td align="left" valign="top">
              <p>
                <b>
                  <br />
                  <span className={styles.sectionhead}>Safety</span>
                </b><br />
                The products delivered by National Grid's
                companies&#8212;electricity and natural gas&#8212;are
                indispensable. They power businesses, light cities and towns,
                and drive both necessities and conveniences at home. Yet,
                improperly handled, they can be dangerous. Safety is thus
                critical to all that we do. For the general public, it's
                providing education on how to use energy safely and to recognize
                and respond to risky situations
                <br /> <br />
                For us, safety is not just a priority. It's a value that guides
                our actions. Our company's vision is zero injuries every day.
              </p>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="vision_principles" className={styles.anchor}>
                        Vision and Guiding Principles
                      </a>
                    </b>{" "}
                    - Our vision and guiding principles are based on the belief
                    that every injury is preventable
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/pdf/non_html/Contractor_Safety_Requirements_040105.pdf" className={styles.anchor}>
                      Contractor Safety Requirements
                      </a>
                    </b>{" "}
                    (pdf)
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/pdf/non_html/shared_zapZone.pdf" className={styles.anchor}>
                      Danger Zone 
                      </a> -
                    </b>{" "}
                    Contractors and Electrical Safety (pdf)
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/commitment/d3-11_worksafeform" className={styles.anchor}>
                      Work Safely
                      </a>
                    </b>{" "}
                    - Order your copy of our free Safety Video
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/pdf/non_html/job_brief.pdf" className={styles.anchor}>
                      Job Briefing
                      </a>
                    </b>{" "}
                    (pdf)
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="safety_before_entering" className={styles.anchor}>
                      Before entering an enclosed space you must
                      </a>
                    </b>{" "}
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/pdf/non_html/general_rules_for_all_03105.doc" target="_blank" rel="noreferrer" className={styles.anchor}>
                      General Rules for All Electrical Workers and Contractors
                      </a>
                    </b>{" "}
                    (.doc)
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                  <td width="25" align="right" valign="top">
                    <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" />
                  </td>
                  <td>
                    <b>
                      <a href="/pdf/non_html/rf_procedure.pdf" className={styles.anchor}>
                      Wireless Radio Frequency (RF) Safety Procedure
                      </a>
                    </b>{" "}
                    (pdf)
                  </td>
                </tr>
                <tr> 
                    <td align="right" valign="top">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
              </table>
              <p>&nbsp; </p>
              <p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SafetyComponent;
