
import React from 'react';
import Img_rfp_photos from '../../assets/images/rfp_photos.jpg';
import Img_diamond_bullet from '../../assets/images/diamond_bullet.gif';
import Img_shared_bullet_triangle from '../../assets/images/shared_bullet_triangle.gif';
import Helmet from 'react-helmet';
import '../../styles/Energy.css'
function Index() {
    return (
        <div>

            <Helmet>
                <title>National Grid</title>
            </Helmet>
            <div id="EnergyIndex">
                <div className='row'>
                    <div>
                        <div id="leftnav" style={{ width: '140px' }}>
                            <div>
                                <img src={Img_rfp_photos} border="0" alt="Transmission" width="160" height="310" />
                            </div>
                        </div>

                        <div style={{ paddingTop: '10px' }}>
                            <div>
                                <table style={{ width: "87%" }}>
                                    <tr>
                                        <td>
                                            <b>Welcome</b>
                                            <p className='EnergyIndex_p'>
                                                This site is for Wholesale Energy Suppliers of Last Resort Service and Basic Service.
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <table style={{ width: "87%" }}>
                                    <tr>
                                        <td width="20" align="right" valign="top">
                                            <img src={Img_diamond_bullet} alt="img" width="9" height="12" /> &nbsp;
                                        </td>

                                        <td>
                                            The content within this site is intended to assist wholesale energy suppliers and potential wholesale energy suppliers in providing energy supply services to the following Distribution Companies operating in:
                                        </td>
                                    </tr>
                                </table>

                                {/*<div className="row">*/}
                                {/*    <div className="col-md-12">*/}
                                {/*        <img src={Img_diamond_bullet} alt="img" width="9" valign="top" height="12" /> The content within this site is intended to assist wholesale energy suppliers and potential wholesale energy suppliers in providing energy supply services to the following Distribution Companies operating in:*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>
                            <div style={{ paddingLeft: '19%', paddingTop: '10px', paddingBottom: '10px' }}>
                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="https://www1.nationalgridus.com/StateLandingMA" rel="noreferrer" target="_blank" className='EnergyIndex_a'>Massachusetts</a>
                                </div>

                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="https://www1.nationalgridus.com/StateLandingMA" rel="noreferrer" target="_blank" className='EnergyIndex_a'>Nantucket</a>
                                </div>

                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="https://www1.nationalgridus.com/StateLandingRI" rel="noreferrer" target="_blank" className='EnergyIndex_a'>Rhode Island</a>
                                </div>
                            </div>


                            <div>
                                <table style={{ width: "87%" }}>
                                    <tr>
                                        <td width="20" align="right" valign="top">
                                            <img src={Img_diamond_bullet} alt="img" width="9" height="12" /> &nbsp;
                                        </td>
                                        <td>
                                            Additional information may be found in the For Energy Suppliers section of each company's website:
                                        </td>
                                    </tr>
                                </table>
                                {/*<div className="row">*/}
                                {/*    <div className="col-md-12">*/}
                                {/*        <img src={Img_diamond_bullet} alt="img" width="9" valign="top" height="12" />&nbsp;Additional information may be found in the For Energy Suppliers section of each company's website:*/}
                                {/*    </div>*/}
                                {/*</div>*/}



                            </div>

                            <div style={{ paddingLeft: '15%', paddingTop: '10px', paddingBottom: '10px' }}>
                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="/masselectric/energy_supplier/index" target="_blank" className='EnergyIndex_a'>Massachusetts &#8212; Energy Suppliers Information</a>
                                </div>

                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="/masselectric/energy_supplier/index" target="_blank" className='EnergyIndex_a'>Nantucket &#8212; Energy Suppliers Information</a>
                                </div>

                                <div>
                                    <img src={Img_shared_bullet_triangle} alt="img" width="6" height="10" /> <a href="/narragansett/energy_supplier/index" target="_blank" className='EnergyIndex_a'>Rhode Island &#8212; Energy Suppliers Information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Index;