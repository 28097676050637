import React from "react";
import "../../styles/transmission.css"
import Img_SpierFalls from '../../assets/images/SpierFalls.jpg';
import Helmet from 'react-helmet';
function SpierRotterdam() {
    return (
        <div>
            <div id="EmfBody">
                <div className='row'>
                    <div id="wrapper" style={{ maxWidth: '1010px' }}>
                        <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5; font-family: Arial, Helvetica, sans-serif' }} >
                            <title>Spier Falls to Rotterdam 115kV Transmission Line Project</title>
                        </Helmet>
                        <div>&nbsp;</div>
                        <div id="leftnav" style={{ minWidth: '17%' }}>
                            <div id="links">
                                <span className="leftnav">
                                    <p>&nbsp;</p>
                                </span>
                            </div>
                        </div>

                        <div id="actual_content_env" className="clearfix">
                            <div className="innerContent" style={{ maxWidth: '570px' }}>
                                <span className="pagehead">Spier Falls to Rotterdam 115kV Transmission Line Project</span>
                                <p style={{ textAlign: 'center', paddingRight: '10%' }}>
                                    <img src={Img_SpierFalls} alt="Laser" height="100px" width="40%" style={{ position: 'relative' }}></img>
                                </p>
                                <p>
                                    On October 14, 2013, National Grid's Spier Falls to Rotterdam 115kV Transmission Line Project was completed and placed into service. This Project was critically important to the delivery of safe, reliable power to the region surrounding Saratoga Springs and north, as electric needs increased over the years. This Project included a new 115kV transmission line (built parallel to existing 115kV transmission lines) that runs 33 miles from National Grid's Rotterdam Substation in the Town of Rotterdam, New York, to its Spier Falls Substation in the Town of Moreau, New York. Additionally, a 4.2 mile line was built in the Town of Ballston, New York. The new lines provide additional reliability and service to approximately 45,000 commercial and residential customers.
                                </p>
                                <p>
                                    The Spier Falls to Rotterdam Project was granted its Certificate of Environmental Compatibility and Public Need (Certificate) by the New York State Public Service Commission (PSC) on February 24, 2011, giving National Grid the ability to build the Project. The last phase of the project was put into service on October 30, 2013. Since that time, National Grid has sought to amend the Certificate with smaller projects that would continue to provide the region with reliable power. These projects include the Sodeman Road Substation Project and the Lasher Road Substation Project.
                                </p>
                            </div>

                            <div className="right_side" style={{ maxWidth: '180px' }}>
                                <span className="subHeadingText">Related Information</span>
                                <div className="rightside_content">
                                    <p>
                                        <b>New York State Public Service Commission Case Number: </b><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterSeq=33427&MNO=10-T-0080" rel="noreferrer">10-T-0080</a>
                                    </p>
                                    <p>
                                        <a href="/transmission/sode_substation" target="_blank">Sodeman Substation</a>
                                    </p>
                                    <p>
                                        <a href="/transmission/lasher_substation" target="_blank">Lasher Substation</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpierRotterdam
