import React from "react";
import styles from "../../../styles/catv.module.css";
import phone from "../../../assets/images/phone.gif";
import letter from "../../../assets/images/letter.gif";

const ContractorList = () => {
  return (
    <div>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
        <tbody>
            <tr>
                <td width={20} valign="top" align="left">&nbsp;</td>
                <td align="left" valign="top">
                    <p>
                        <b>
                            <span className={styles.sectionhead}>National Grid Qualified Contractor List</span>
                        </b>
                    </p>
                    <br />
                </td>
            </tr>
          <tr>
            <td>&nbsp;</td>
            <td align="left" valign="top" bgcolor="#006666">
              <b>
                <font color="#FFFFFF">&nbsp;Aerial </font>
              </b>
              <font color="#FFFFFF">
                <b>&lt; 69 Kv</b>
              </font>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td align="left" valign="top">
              <ul>
                <br />
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Asplundh Construction</span> &nbsp;&nbsp; 1325 Highway 315, Wilkes-Barre, PA 18705
                  <br />
                  Jay Locke&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 560-871-9942&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>rlocke@asplundh.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>D&D Power Inc</span> &nbsp;&nbsp; 425 New Karner Road,Suite 1, Albany, New York 12205
                  <br />
                  Kevin Symons&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 518-894-7200&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>ksymons@danddpower.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Harlan Electric </span> &nbsp;&nbsp;The L E Meyers Co., 22384 Network Place, Chicago, IL 606731
                  <br />
                  Steve Campana&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 978-208-3449&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>SCampana@myrgroup.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Northline Utilities, LLC</span> &nbsp;&nbsp; 2328 Route 9N, PO Box 656, Ausable Forks, NY 12912
                  <br />
                  Dave Walker&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 518-647-8198 ext. 217&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>dwalker@northlinellc.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>O'Connell Electric Company, Inc.</span> &nbsp;&nbsp; 830 Phillips Road, Victor, NY 14564-9747
                  <br />
                  Dave Emmi&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 585-295-6805&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>dave.emmi@oconnellelectric.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>McDonough Electric Construction Corporation</span> &nbsp;&nbsp;  10 Commercial Ave., Bedford, MA 01730
                  <br />
                  Kevin McDonough&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 617-908-4247&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>kevin@mcdonoughelectric.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Grattan Line Construction Corporation</span> &nbsp;&nbsp; 35 Sullivan Road, North Billerica, MA 01862
                  <br />
                  Chuck Rubeski&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 978-663-7723&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>crubeski@grattanline.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Riggs Distler & Co</span> &nbsp;&nbsp;  4 Esterbrook, NJ 08003-4002
                  <br />
                  Dan Butler&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 860-883-6103&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}> DButler@riggsdistler.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Three Phase Line Construction Inc.</span> &nbsp;&nbsp; 25 Main St. P.O. Box 18, Farmington, NH 03835
                  <br />
                  Jill Middaugh&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 603-755-9610&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>bids@3phaseline.com</a>
                  <br />
                  <br />
                </li>
                <li className={styles.square} >
                  <span className={styles.sectionhead}>Proline Power, LLC</span> &nbsp;&nbsp;  3777 Lucinda Drive, Skaneateles, NY
                  <br />
                  Michael Engwer&nbsp;
                  <img src={phone} align="absbottom" width="18" height="17" alt="Phone" /> Tel: 518-669-7600&nbsp;
                  <img src={letter} align="absbottom" width="18" height="17" alt="Mail" /> &nbsp;Mail:&nbsp;
                  <a  href="mailto:rlocke@asplundh.com" style={{fontFamily:"verdena, sans-serif", fontSize:"11px", textDecoration:"underLine",color:"#00345b"}}>Michael@prolinepwr.com</a>
                  <br />
                  <br />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ContractorList;
