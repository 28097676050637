import React from "react";
import "../../styles/transmission.css"
import IMG_MohicanBattenkill from '../../assets/images/MohicanBattenkill.jpg';
import Helmet from 'react-helmet';
function MohicanRebuild() {
    return (
        <div>
            <div id="EmfBody">
                <div className='row'>
                    <div id="wrapper" style={{ maxWidth: '1010px' }}>
                        <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5; font-family: Arial, Helvetica, sans-serif' }} >
                            <title>Mohican-Battenkill 115kV Rebuild Project</title>
                        </Helmet>
                        <div>&nbsp;</div>
                        <div id="leftnav" style={{ minWidth: '17%' }}>
                            <div id="links">
                                <span className="leftnav">
                                    <p>&nbsp;</p>
                                </span>
                            </div>
                        </div>

                        <div id="actual_content_env" className="clearfix">
                            <div className="innerContent" style={{ maxWidth: '570px' }}>
                                <span className="pagehead">Mohican-Battenkill 115kV Rebuild Project</span>
                                <p style={{ textAlign: 'center', paddingRight:'10%' }}>
                                    <img src={IMG_MohicanBattenkill} alt="Laser" height="143px" width="40%" style={{ position: 'relative', minWidth: '230px' }}></img>
                                </p>

                                <p>
                                    The Mohican - Battenkill Rebuild Project is critically important to the delivery of safe, reliable power to Washington and Saratoga counties as electric needs have increased over the past few years. This region surrounding Saratoga Springs is the largest growth area in terms of electric load in National Grid's upstate New York service territory and the increased demands are being placed on an aging electrical system. In addition, the anticipated expansion of GLOBALFOUNDRIES will place further demands on the system. This Project involves the rebuilding and reconductoring (re-stringing new wires) of approximately 14 miles of two existing 115kV electric transmission lines from the Mohican Substation in Moreau to the Battenkill Substation in Greenwich. The Project traverses portions of the towns of Moreau, Fort Edward, Greenwich and Easton. The old lattice structures were replaced with self-weathering steel monopoles with an average height of 85 feet. The Project is being performed under the Public Service Commission's Article VII laws and is subject to state oversight and permitting. Construction began in January 2016 and is anticipated to conclude in 2018. The new lines were placed in service on April 28, 2017.
                                </p>
                            </div>

                            <div className="right_side" style={{ maxWidth: '170px' }}>
                                <span className="subHeadingText">Links</span>
                                <div className="rightside_content">
                                    <p>
                                        <b>New York State Public Service Commission Case Number: </b><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterCaseNo=11-T-0068&submit=Search" target="_blank" rel="noreferrer">11-T-0068</a>
                                    </p>
                                    <p>
                                        <b>Website: </b><a href="http://capitalregionreliability.com/lasher-road.htm" target="_blank" rel="noreferrer">www.capitalregionreliability.com/</a>
                                    </p>
                                    <p>
                                        <b>Project Email: </b><a href="mailto:info@capitalregionreliability.com" target="_blank" rel="noreferrer">info@capitalregionreliability.com</a>
                                    </p>
                                    <p>
                                        <b>Project Hotline:</b>
                                        <br /> 866-706-5097
                                    </p>
                                    <p>
                                        <b>Project Facebook Page:</b>
                                        <br />@NationalGridMohicanBattenkill
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MohicanRebuild
