

import React from 'react';
import Helmet from 'react-helmet';
import Img_button_back from '../../../../assets/images/button_back.gif';
import Img_contact_card from '../../../../assets/images/contact_card.gif';


function Customer_Info() {
    return (
        <div >
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div className='row' style={{ marginLeft: '10px', fontSize: '11px', fontFamily: 'Verdana, sans-serif' }}>
                <div style={{ minWidth: '88%', width: '550px' }}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <p>
                                    <span className="Catv_PageHead"><b>Joint Pole Owners List</b></span>
                                </p>
                            </div>
                            <div className='row'>
                                <div style={{ minWidth: '33%', width: '250px' }}>
                                    <p>
                                        <b>Windstream Communications </b> <br />
                                        201 East Fourth Street<br />
                                        P.O. Box 850<br />
                                        Jamestown, NY 14702-0850 <br />
                                        (716) 661-5415<br />
                                        <br />
                                        <b>Armstrong Telephone Co. </b><br />
                                        136 East Front Street<br />
                                        P.O. Box K<br />
                                        Addison, NY 14801 <br />
                                        (607) 359-2211<br />
                                        <br />
                                        <b> Berkshire Telephone Co. </b><br />
                                        19 Broad St.<br />
                                        PO Box 405        <br />
                                        Kinderhook, NY 12106 <br />
                                        Brian Kelley <br />
                                        (518) 758-9900<br />
                                        <br />
                                        <b>Chautauqua &amp; Erie Telephone Corp.</b> <br />
                                        30 Main Street<br />
                                        P.O. Box B<br />
                                        Westfield, NY 14787 <br />
                                        (716) 326-2121<br />
                                        <br />
                                        <b>Chazy &amp; Westport Telephone Co. </b><br />
                                        2 Champlain Ave.<br />
                                        Westport, NY 12993 <br />
                                        Gerald R. Forcier <br />
                                        (518) 962-8211<br />
                                        <br />
                                        <b>Citizens Communications Co.of NY, Inc.</b><br />
                                        137 Harrison Street<br />
                                        Johnstown, NY 12095 <br />
                                        Peter Czechowicz <br />
                                        (518) 773-6521<br />
                                        <br />
                                        <b>Citizens Telephone of Hammond </b><br />
                                        PO Box 217<br />
                                        Hammond, NY 13646 <br />
                                        Donald Ceresoli, Jr. <br />
                                        (315) 324-5911<br />
                                        <br />
                                        <b> Crown Point Telephone Co.</b> <br />
                                        Main Street<br />
                                        P.O. Box 275<br />
                                        Crown Point, NY 12928-0275 <br />
                                        (518) 597-3300<br />
                                        <br />
                                    </p>
                                </div>

                                <div style={{ minWidth: '33%', width: '250px' }}>
                                    <p>
                                        <b>Dunkirk Fredonia Telephone Company </b><br />
                                        40 Temple St.<br />
                                        PO Box 209<br />
                                        Fredonia, NY 14063 <br />
                                        Wade Weatherlow <br />
                                        (716) 673-3031 <br />
                                        <br />
                                        <b>Edwards Telephone Co. </b><br />
                                        d/b/a TDS Telecom <br />
                                        P.O. Box 36<br />
                                        Edwards, NY 13635 <br />
                                        (315) 562-3211<br />
                                        <br />
                                        <b>  Empire Telephone Corp.</b><br />
                                        34 Main Street<br />
                                        P.O. Box 349<br />
                                        Prattsburg, NY 14873 <br />
                                        (607) 522-3712<br />
                                        <br />
                                        <b>Frontier Communications of Ausable Valley, Inc. </b><br />
                                        310 Front Street<br />
                                        Keeseville, NY 12944 <br />
                                        Gene Gengle <br />
                                        (716) 834-7211<br />
                                        <br />
                                        <b>Frontier Communications of Rochester, Inc. </b><br />
                                        180 South Clinton Avenue<br />
                                        Rochester, NY 14646-0995 <br />
                                        Mary Ann Murray <br />
                                        (716) 777-7944<br />
                                        <br />
                                        <b>Frontier Communications of Seneca Gorham, Inc. </b><br />
                                        71 Main Street<br />
                                        P.O. Box 278<br />
                                        Bloomfield, NY 14469-0278 <br />
                                        (716) 657-7111<br />
                                        <br />
                                        <b>Germantown Telephone Co. </b><br />
                                        210 Main St.<br />
                                        PO Box 352<br />
                                        Germantown, NY 12526 <br />
                                        Bruce Bohnsack <br />
                                        (518) 537-6257<br />
                                        <br />
                                        <b>  Middleburg Telephone Corp. </b><br />
                                        103 Cliff St. <br />
                                        P.O. Box 191  <br />
                                        Middleburgh, NY 12122 <br />
                                        James Becker <br />
                                        (518) 827-5211<br />
                                        <br />
                                        <b> Newport Telephone Co. </b><br />
                                        3087 Bridge Street<br />
                                        Newport, NY 13416 <br />
                                        L. Theodore Foss <br />
                                        (315) 845-8112<br />
                                        <br />
                                        <br />
                                    </p>
                                </div>

                                <div style={{ minWidth: '32%', width: '250px' }}>

                                    <p>
                                        <b>Nicholville Telephone Co. </b><br />
                                        Water Street<br />
                                        P.O. Box 122<br />
                                        Nicholville, NY 12965-0122 <br />
                                        (315) 328-4411 <br />
                                        <br />
                                        <b>Ogden Telephone Co. (Citizens) </b><br />
                                        21 West Avenue<br />
                                        Spencerport, NY 14559 <br />
                                        (716) 352-3451 <br />
                                        <br />
                                        <b>Oneida County Rural Telephone Co. </b><br />
                                        9560 Main St.<br />
                                        Holland Patent, NY 13354 <br />
                                        Peter McCarthy <br />
                                        (315) 865-5201<br />
                                        <br />
                                        <b>Pattersonville Telephone Co. </b><br />
                                        277 Main Street <br />
                                        Rotterdam Junction, NY 12150 <br />
                                        (518) 887-2121<br />
                                        <br />
                                        <b>State Telephone Co.</b> <br />
                                        46 Reed Street<br />
                                        Coxsackie, NY 12051 <br />
                                        (518) 731-6128<br />
                                        <br />
                                        <b>Taconic Telephone Co. </b><br />
                                        Atten: Gerald Blass<br />
                                        1 Taconic Place<br />
                                        Chatham, NY 12037 <br />
                                        (518) 392-1210<br />
                                        <br />
                                        <b>Township Telephone Co. </b><br />
                                        d/b/a TDS Telecom <br />
                                        P.O. Box 70<br />
                                        Chaumont, NY 13622 <br />
                                        (315) 649-2411<br />
                                        <br />
                                        <b>Verizon </b><br />
                                        1095 Ave. of Americas<br />
                                        New York, NY 10036 <br />
                                        James Slavin <br />
                                        (212) 395-7208<br />
                                        <br />
                                        <b>Vernon Telephone Co. </b><br />
                                        d/b/a TDS Telecom <br />
                                        Curtis Road<br />
                                        P.O. Box 900, Vernon, NY 13476 <br />
                                        Bill Nicholson <br />
                                        (315) 829-2211</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div style={{ minWidth: '12%', width: '130px', paddingRight: '10px' }}>
                    <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <a href="pole">
                            <img src={Img_button_back} width="51" height="15" border="0" alt="backbutton" />
                        </a>
                    </div>
                    <a href="../update_contactinfo?type=Joint Pole Owners">
                        <img src={Img_contact_card} width="120" height="89" border="0" alt="card" />
                    </a>
                </div>
            </div>

            <p>&nbsp;</p>
        </div>
    );
}
export default Customer_Info;