import React from 'react';
import styles from  '../../../styles/ngww2.module.css';

function EdiNyNy() {
    return (<>
                <div style = {{width:"520px"}}>
                <table width="480" border="0" cellpadding="0" cellspacing="0">
					<tr>
						<td width="480" valign="top">
							<div className={styles.pagetitle}>EDI Processing Schedule</div>
						</td>
					</tr>
				</table>			
			
		        <img alt=''  src="../assets/images/c.gif" width="1" height="5" border="0" />			
			    <table width="520" border="0" cellspacing="0" cellpadding="0">
				<tr> 					
					<td width="520">
						<div className={styles.typetitle}>National Grid sends outbound files to Marketers</div>
						<br/>

                        <table className={`${styles.grayBorder} ${styles.tablemain}`}>
                        <tbody>
                        <tr>
                        <td className={styles.grayBorder}><strong>&#160;814</strong></td>
                        <td className={styles.grayBorder}>&#160;<strong>248/820</strong></td>
                        <td className={styles.grayBorder}><strong>&#160;824</strong></td>
                        <td className={styles.grayBorder}><strong>867</strong></td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;6:00 AM</td>
                        <td>
                        8:00 AM (820) Daily Non-POR<br/>
                        </td>
                        <td className={styles.grayBorder}>&#160;8:00 AM</td>
                        <td className={styles.grayBorder}>&#160;6:00 AM</td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;10:00 AM</td>
                        <td className={styles.grayBorder}></td>
                        <td className={styles.grayBorder}></td>
                        <td className={styles.grayBorder}>&#160;8:00 AM</td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;12:00 PM</td>
                        <td className={styles.grayBorder}>
                        9:00 AM (568)<br/>
                        </td>
                        <td className={styles.grayBorder}></td>
                        <td className={styles.grayBorder}>10:00 AM</td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;12:00 PM</td>
                        <td className={styles.grayBorder}></td>
                        <td className={styles.grayBorder}></td>
                        <td className={styles.grayBorder}>12:00 PM</td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;4:00 PM</td>
                        <td className={styles.grayBorder}>
                        4:00 PM (820) 20th of the month POR<br/>
                        </td>
                        <td className={styles.grayBorder}>4:00 PM</td>
                        <td className={styles.grayBorder}>4:00 PM</td>
                        </tr>
                        </tbody>
                        </table>
								
					</td>						
				</tr>
			</table>						
            
            <div><img alt=''  src="../assets/images/c.gif" width="1" height="15" /></div>
						
			<table width="520" border="0" cellspacing="0" cellpadding="0">
				<tr> 					
					<td width="520">
						<div className={styles.typetitle}>National Grid must receive data prior to these times for same day processing:</div>
						<br/>

                        <table cellspacing="1" cellpadding="1" width="95%" border="1"  className={`${styles.grayBorder} ${styles.tablemain}`}>
                        <tbody>
                        <tr>
                        <td  className={styles.grayBorder}>&#160;<strong>810</strong></td>
                        <td  className={styles.grayBorder}><strong>&#160;814</strong></td>
                        <td className={styles.grayBorder}><strong>&#160;824</strong></td>
                        </tr>

                        <tr>
                        <td className={styles.grayBorder}>&#160;5:00 PM</td>
                        <td className={styles.grayBorder}>&#160;4:00 PM</td>
                        <td className={styles.grayBorder}>&#160;9:00 PM</td>
                        </tr>
                        </tbody>
                        </table>
								
					</td>						
				</tr>
			</table>						
            
            <div><img alt=''  src="../assets/images/c.gif" width="1" height="15" /></div>
						
			<table width="520" border="0" cellspacing="0" cellpadding="0">
				<tr> 					
                    <td width="520">
                        <div className={styles.typetitle}>If the enrollment/drop deadline occurs on a weekend or holiday, EDI transactions must be sent on the previous working day.</div>
                    </td>						
				</tr>
			</table>                
        </div>
    </>);
}

export default EdiNyNy;