
import React from 'react';
import IMG_12_transmission from '../../assets/images/12_transmission.jpg';
import Img_13_transmission from '../../assets/images/13_transmission.jpg';
import Helmet from 'react-helmet';
import '../../styles/Env.css'

function Index() {
    return (
        <div>
            <div id="EmfBody">

                <div className='row' style={{ paddingLeft: '10px' }}>

                    <div id="wrapper" style={{ maxWidth: '995px' }}>
                        <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5; font-family: Arial, Helvetica, sans-serif' }} >
                            <title>National Grid - Transmission</title>
                        </Helmet>
                        <div>&nbsp;</div>

                        <div id="leftnav" style={{ minWidth: '18%', width: '180px' }}>
                            <div className='row'>
                                <div className="LeftSideBox">
                                    <img src={Img_13_transmission} border="0" alt="Transmission" width="178px" height="192px" />
                                </div>
                            </div>
                            <div className='row' id="actual_content_index" >
                                <ul>
                                    <li>
                                        <a href="c3-4_connected">Getting Connected</a>
                                    </li>
                                    <li>
                                        <a href="c3-8_standocs">Operations Documentation</a>
                                    </li>
                                    <li>
                                        <a href="c3-7_accountmgmt">Contact Your Account Manager</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div id="actual_content_env" className="clearfix">
                            <div className="innerContent" style={{ maxWidth: '570px', paddingLeft: '2px' }}>
                                <span className="pagehead">Transmission</span>
                                <strong>Electricity Transmission:</strong> National Grid owns and operates an electricity transmission system of approximately 8,600 miles spanning upstate New York, Massachusetts, Rhode Island, New Hampshire and Vermont to customers that include local electric companies, municipalities and large commercial enterprises. Our US electricity transmission facilities operate at voltages ranging from 69 kV to 345 kV, utilizing overhead lines, nearly 90 miles of underground cable and 524 substations. We are the largest electricity transmission service provider in New England and New York by reference to the length of these high voltage transmission lines.
                                <p>
                                    <strong>Canadian interconnector:</strong> National Grid owns and operates a direct current transmission line rated at 450 kV that is a key section of an interconnector between New England and Canada.
                                </p>
                                <p>
                                    <a href="c2-2_northeast" className="subhead">Northeast Operations</a>
                                    <div className="textindent">
                                        This section contains helpful information for new and existing transmission customers and general information about our Northeast operations, the heart of our U.S. transmission activities.
                                    </div>
                                </p>
                                <p>
                                    <b style={{ fontSize: '20px', color: 'blue' }}>CAPITAL PROJECTS: </b>
                                </p>
                                <p>
                                    <a href="c5-2_strategy" className="subhead">Auburn Transmission Project</a>
                                    <div className="textindent">Located in Cayuga and Ondondaga Counties, New York</div>
                                </p>

                                <p>
                                    <a href="c3-3_documents" className="subhead">New York Energy Solution</a>
                                    <div className="textindent">Located in the Capital District, Mohawk Valley, and Hudson Valley, New York</div>
                                </p>
                                <p>
                                    <a href="spier_rotterdam" className="subhead">Spier Falls to Rotterdam 115kV Transmission Line Project</a>
                                    <div className="textindent">Located in Saratoga and Schenectady Counties, New York</div>
                                </p>
                                <p>
                                    <a href="sode_substation" className="subhead">Sodeman Road Substation Project</a>
                                    <div className="textindent">Located in Saratoga County, New York</div>
                                </p>
                                <p>
                                    <a href="lasher_substation" className="subhead">Lasher Road Substation Project</a>
                                    <div className="textindent">Located in Saratoga County, New York </div>
                                </p>
                                <p>
                                    <a href="mohican_rebuild" className="subhead">Mohican-Battenkill 115kV Rebuild Project </a>
                                    <div className="textindent">Located in Washington and Saratoga Counties, New York</div>
                                </p>
                                <p>
                                    <a href="aquidneck_reliability" className="subhead">Aquidneck Island Reliability Project - "OnIsland"</a>
                                    <div className="textindent">Located on Aquidneck Island, Rhode Island</div>
                                </p>
                                <p>
                                    <a href="Golden_Rock" className="subhead">Golden Rock Transmission & Substation Project</a>
                                    <div className="textindent">Located in Salem, NH & Methuen, MA</div>
                                </p>
                            </div>


                            <div className="rightnav" >
                                <div className="RightSideBox">
                                    <img src={IMG_12_transmission} border="0" alt="Transmission" width="178px" height="192px" />
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div >
    );
}
export default Index;