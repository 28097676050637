import React from 'react';
import styles from  '../../../styles/ngww2.module.css';

function AllowanceMaMa() {
    return (<>
            <div className={styles.threecoloumlayout} style={{height:"250px"}}> 
              <div className={styles.column2}> 
                <table style={{width:"520px"}} border="0" cellpadding="0" cellspacing="0">
                <tr>
                    <td style={{width:"520px"}} valign="top">
                        <div className={styles.pagetitle}>Company Gas Allowance</div>                    
                    </td>
                </tr>
                </table>

                <table style={{width:"520px"}} border="0" cellspacing="0" cellpadding="0">
                    <tr> 
                        <td style={{width:"520px"}} valign="top">            
                            <img alt="" src="../assets/images/c.gif" width="1" height="25" border="0" />
                        </td>
                    </tr>
                </table>
                <table style={{width:"520px"}} border="0" cellspacing="0" cellpadding="0">
                <tr> 
                <td style={{width:"520px"}} valign="top"> 
                    <p className={styles.typetitle}>Effective November 1, 2011, the Company Gas Allowances are as follows:</p>
                                
                    <p><p>
                        <table style={{width:"300px"}} border="0" align="center" valign="top">
                        <tr><td>Boston Gas Company</td><td>3.3%</td></tr>
                        <tr><td>Colonial Gas Company</td><td>3.3%</td></tr>
                        <tr><td>EnergyNorth Natural Gas</td><td>1.4%</td></tr>
                        </table>
                    </p></p>                        
                    
                </td>
                </tr>
                </table>
            </div> 

            <div className={styles.column3}>  
                <div className={styles.genericSecondaryContent}>
                <table style={{width:"165px"}} cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                    <tr>
                        <td style={{width:"165px"}}>
                            <div className={styles.modheader}>Contact National Grid</div>                    
                        </td>
                    </tr>
                </table>
                </div>
                <table style={{width:"165px"}} cellpadding="0" cellspacing="0" border="0">                    
                    <tr>
                        <td style={{width:"165px"}} valign="top" align="left">
                            <a href="https://www.nationalgridus.com/NY-Home/Default.aspx" target="_blank"  rel="noreferrer" className={`${styles.contextuallinkcontact}  ${styles.contextuallinkcontact_a}`}>
                                General Inquiry
                            </a>
                        </td>
                    </tr>
                </table>
                        
                <table style={{width:"165px"}} cellpadding="0" cellspacing="0" border="0" >
                    <tr>                    
                        <td style={{width:"165px"}}>
                            <div className={styles.modheader}>Helpful Resources</div>                    
                        </td>                
                    </tr>
                </table>                          
                            
                <table style={{width:"165px"}} cellpadding="0" cellspacing="0" border="0" >
                    <tr>                
                        <td style={{width:"165px"}} valign="top" align="left">
                            <a href='https://www.nationalgridus.com/NY-Home/Default.aspx' target="_blank"  rel="noreferrer" className={`${styles.contextuallink} ${styles.contextuallinkcontact_a}`} >Learn More about Energy Products and Services for Your Business</a>
                        </td>                    
                    </tr>
                </table>
            </div> 
            </div>
    </>);
}

export default AllowanceMaMa;