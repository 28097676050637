
import React from 'react';
import Helmet from 'react-helmet';
import Img_download_button from '../../../../assets/images/download_button.jpg';
import Img_bullet_triangle from '../../../../assets/images/bullet_triangle.gif';
import Img_shared_bullet_diamond from '../../../../assets/images/shared_bullet_diamond.gif';
import Img_grn_arrow_app from '../../../../assets/images/grn_arrow_app.gif';
import Img_view_list from '../../../../assets/images/view_list.gif';

const A_Style = {
    color: '#00345B',
    fontSize: '11px',
    fontfamily: 'Verdana, sans - serif',
    textDecoration: 'underline',
    fontWeight: 'normal'

};



function Pole() {
    return (
        <div>
            <Helmet bodyAttributes={{ style: 'background-color : #FFF; font-family: Verdana, sans-serif;FONT-SIZE: 11px' }} >
                <title>National Grid</title>
            </Helmet>
            <div style={{ paddingLeft: '20px', fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                <div className='row'>
                    <div className='col-10'>
                        <p style={{ margin: '1em' }}>
                            <span className="Catv_PageHead"><b>CLEC-Distribution Pole</b></span>
                            <br />
                            If you aren't already signatory to a<b> Standard Distribution Pole Agreement</b> click on the Agreement below and follow the instructions.
                        </p>

                    </div>
                </div >
                <div className='row' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <div className='col-10' style={{ paddingLeft: '30px' }} >

                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '70%', minWidth: '250px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Standard Distribution Pole Agreement</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '30%' }}>
                                <a href="../../../doc/CLEC Agreement.doc" style={{ paddingLeft: '10px' }}>
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '70%', minWidth: '250px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Third Party Instructions</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '30%' }}>
                                <a href="../../../doc/1207/ATT_2_-NM_Third_Party_Instructions_-_Distribution_01_09_23_1.doc" style={{ paddingLeft: '10px' }}>
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>


                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '70%', minWidth: '250px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Discontinuance Form</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '30%' }}>
                                <a href="../../../doc/1207/Notice_of_Discontinuance_of_Use_of_Poles_-_Updated_2020.doc" style={{ paddingLeft: '10px' }}>
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '70%', minWidth: '250px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Joint Pole Owners List</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '30%' }}>
                                <a href="tele_info" style={{ paddingLeft: '20px' }}>
                                    <img src={Img_view_list} width="110" height="45" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>
                        <div style={{ paddingLeft: '12px', paddingTop: '3px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', color: 'black', paddingTop: '5px' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ width: '50%', minWidth: '230px' }}>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} /> <b>Pole Attachment Application</b>
                            </div>
                            <div style={{ textAlign: 'right', width: '50%' }}>
                                <a href="../../../xls/2205/NGRID_Application_Packet_-_May_20212_1 (5).xlsx">
                                    <img src={Img_grn_arrow_app} width="224" height="49" border="0" alt="click here" />
                                </a>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} /> <b><a href="custodial" style={{ color: '#00345B' }}>Custodial Areas</a></b>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#00345B' }}>Rates and Fees Information</b>
                            </div>
                        </div>

                        <div className='row divrowpadding'>
                            <p style={{ paddingLeft: '40px' }}>
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />  <a href="../../../pdf/posting/Website_Service Rate Summary Sheet (2007).pdf" target='_blank' style={A_Style}>Service Rate Summary Sheet</a> (.pdf)
                                <br />
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />  <a href="../../../pdf/posting/2016 Make-Ready Summary Sheet.pdf" target='_blank' style={A_Style}>Make-Ready Summary Sheet</a> (.pdf)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Pole;