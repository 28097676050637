import React from 'react';
import ConnectsHeaderLayout from './header/ConnectsHeaderLayout';
import ConnectsFooterLayout from './footer/ConnectsFooterLayout';

const ConnectsLayout = ({ component, showFooter = true }) => {
  return (
    <div>
      <div className="container">
        <ConnectsHeaderLayout />        
        {component}
        {<ConnectsFooterLayout/> } 
      </div>
    </div>
  );
}

export default ConnectsLayout;
