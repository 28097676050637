import React from 'react'
import { Link } from 'react-router-dom';
import  '../../styles/transmission.css';
import MA_YOP from "../transmission/non_html/2024_MA_National_Grid_YOP.pdf"
import MA_ROW_YOP from "../transmission/non_html/2024 MA YOP Herbicide Labels.pdf"
import MA_ROW_YOP_H from "../transmission/non_html/2024 MA ROW YOP Maps.pdf"
import MA_ROW_YOP_V from "../transmission/non_html/2024-2028 MA National Grid 5 Year VMP.pdf"
import MA_ROW_YOP_N from "../transmission/non_html/2023 YOP Maps.pdf"
import NG_YOP from "../transmission/non_html/National Grid 2023 YOP.pdf"
import NG_VMP from "../transmission/non_html/National Grid VMP 2019-2023.pdf"
import NG_GreenBk from "../transmission/non_html/shared_construction_greenbk.pdf"
import FY2008 from "../transmission/non_html/transmission_ntwk_perf_rpt2008.pdf"


function Standocs8()
{
  function NY1()
  {
    window.open('http://www.nyiso.com/services/documents/manuals/pdf/planning_manuals/teiman.pdf', 'mywindow', "location=0");
  }
  function NY2()
  {
    window.open('http://www.nyiso.com/services/planning.html#studies', 'mywindow', "location=0");
  }
  function NY3()
  {
    window.open('http://www.nyiso.org/public/documents/tariffs/market_services.jsp', 'mywindow', "location=0");
  }
  function NY4()
  {
    window.open('https://gridforce.my.site.com/electric/s/article/Electric-Specifications', 'mywindow', "location=0");
  }
  function NE1()
  {
    window.open('http://www.iso-ne.com/regulatory/tariff/sect_2/index.html', 'mywindow', "location=0");
  }
  function NE2()
  {
    window.open('http://www.iso-ne.com/trans/nwtrns_inter/index.html', 'mywindow', "location=0");
  }
  function NE3()
  {
    window.open('http://www.iso-ne.com/rules_proceds/operating/isone/index.html', 'mywindow', "location=0");
  }
  function ISONE()
  {
    window.open('http://www.iso-ne.com/' , 'mywindow' , "location=0")
  }
  function ISONY()
  {
    window.open('http://www.nyiso.org/' , 'mywindow' , "location=0")
  }

    return(
        <div className = "row">
          <div className = "col-md-2 col-lg-2">

          </div>
        <div className = "col-sm-1 col-md-6 col-lg-7 col-8">
        <div className = "container mt-3">
            <span className = "pagehead">Operations Documentation</span>            
           <p> <small>The information provided below will assist you in connecting to the transmission system. <br></br>
                   Please <a href="https://www9.nationalgridus.com/transmission/c3-7_accountmgmt.asp">Contact your Account Manager</a> for additional information</small></p>
                 
    <small>     
    <li >Massachusetts, New Hampshire and Rhode Island areas:</li>
    <ul>
        <li><a href={MA_YOP}>2024 MA National Grid YOP</a>(pdf)</li>
        <li><a href={MA_ROW_YOP}>2024 MA ROW YOP Maps</a>(pdf)</li>
        <li><a href={MA_ROW_YOP_H}>2024 MA YOP Herbicide Labels</a>(pdf)</li>
        <li><a href={MA_ROW_YOP_V}>2024-2028 MA National Grid 5 Year VMP</a>(pdf)</li>
        <li><a href={MA_ROW_YOP_N}>2023 MA YOP Maps</a>(pdf)</li>
        <li><a href={NG_YOP}>2023 Right-of-Way Yearly Operational Plan (for Massachusetts only)</a> (pdf)</li>
		    <li><a href={NG_VMP}>Five Year Vegetation Management Plan 2019 - 2023</a> (pdf)</li>
        <li><a href={NG_GreenBk}>Information and Requirements for Electrical Service</a> (pdf)</li>
        <li><Link onClick = {NE1}>ISO New England Inc. Transmission, Markets and Services Tariff</Link>*(pdf) </li>
        <li><Link onClick = {NE2}>ISO-New England interconnections documents</Link>* </li>
        <li><Link onClick = {NE3}>ISO-NE Operating Procedures (for Procedures #17 and #18)</Link>* </li>
    </ul>
    <br></br>
      <li>New York State:</li>
      <ul>
        
        <li><Link onClick = {NY4} >National Grid in New York Electric Specifications</Link>*</li>
        <li><Link onClick = {NY1} >NYISO Transmission Expansion and Interconnection Manual</Link>  *(pdf)</li>
        <li><Link onClick = {NY2} >NYISO System Reliability Impact Study Criteria and Procedures</Link>*</li>
        <li><Link onClick = {NY3} >NYISO Market Administration and Control Area Services Tariff</Link></li>
      </ul>
      <br></br>
      <li> Applicable to National Grid's New York State and New England areas: </li>      
        <ul>
          <li><a href={FY2008}>Transmission Network Performance Report - Fiscal Year 2008</a> (pdf)</li> 
          <li><a href="https://www.nationalgridus.com/Media/ProNet/shared_constr_esb756.pdf" target="_blank"  rel = "noopener noreferrer">Requirements for Parallel Generation Connected to a National Grid Owned EPS</a> (pdf) (ESB 756) </li>
        </ul>
	<br></br>
    <span style = {{color: "#999999", fontsize: "1.2em"}} >* Please note that by using this link, you will be leaving nationalgrid.com's website. Please review our <a href="https://www9.nationalgridus.com/0-1_privacypolicy.asp">Privacy Policy</a> for information on other websites.</span><br></br>
    <span style = {{color: "#999999", fontsize: "1.2em"}}  >Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. Download <a href="http://get.adobe.com/reader/">Adobe Reader</a>, free software to view and print PDF files.</span>
    </small>
      </div>
      </div>
      <div className = "col-lg-3 col-md-3 col-sm-1 col-3 mt-5">
            <hr style ={{backgroundColor : "black" , height: "2px", width : "75%"}}></hr>
        <p style = {{marginLeft : "50px" , marginBottom : "0" , marginTop : "0" ,  fontsize: "1.4em" ,
    fontWeight: "bold"}}><small>Related Information</small></p>
        <hr style = {{ width : "75%"}}></hr>
        <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "0px" }}><small>System Operators:</small></p>
        <p style = {{marginLeft : "50px" ,  marginTop : "0px" , marginBottom : "0px"}}><small>ISO New England / NEPOOL</small></p>
        <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "0px"}}>(<Link onClick = {ISONE}><small>www.iso-ne.com*</small></Link>)</p>
        <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "0px"}}><small>New York ISO</small></p>
        <p style = {{marginLeft : "50px" , marginBottom : "0px" ,  marginTop : "0px" }}>(<Link onClick = {ISONY}><small>www.nyiso.org*</small></Link>)</p>
      </div>
      </div>
    );
}

export default Standocs8;