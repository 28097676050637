

import React from 'react';
import Helmet from 'react-helmet';
import Img_button_back from '../../../../assets/images/button_back.gif';
import Img_contact_card from '../../../../assets/images/contact_card.gif';


const A_Style = {
    color: '#00345B',
    fontSize: '11px',
    fontfamily: 'Verdana, sans - serif',
    textDecoration: 'underline',
    fontWeight: 'normal'

};
function Customer_Info() {
    return (
        <div >
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div className='row' style={{ marginLeft: '10px', fontSize: '11px', fontFamily: 'Verdana, sans-serif' }}>
                <div style={{ minWidth: '88%', width: '550px' }}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <p>
                                    <span className="Catv_PageHead"><b>CLEC Licensee Contact Information</b></span>
                                </p>
                                <div style={{ padding: '10px' }}>
                                    Listed below is the current Licensee contact information on record with National Grid. Please review to insure your contact information is accurate and up to date. Licensees may submit corrections or updates to their contact information via the email link to the right. National Grid utilizes the Licensee information below for all communication with the Licensees (except for application specific correspondence). IT IS INCUMBENT ON THE LICENSEE TO CONFIRM AND MAINTAIN THEIR CONTACT INFORMATION REPORTED ON NATIONAL GRID'S WEBPAGE. Licensee failure to confirm and maintain correct contact information may result in Licensee failing to receive important information relative to their attachments, the Agreement, or the National Grid pole attachment licensing process.
                                </div>
                            </div>

                            <div className='row'>
                                <div style={{ minWidth: '48%', width: '250px' }}>
                                    <p>
                                        <b>Citizens Telephone of Hammond</b> <br />
                                        PO Box 217<br />
                                        Hammond, NY 13646 <br />
                                        Donald Ceresoli, Jr. (315) 324-5911<br />
                                        <br />
                                        <b>DFT Local Service Corporation</b> <br />
                                        40 Temple St.<br />
                                        PO Box 209<br />
                                        Fredonia, NY 14063 <br />
                                        W. Scott Washington (716) 673-3973<br />
                                        <br />
                                        <b>Elantic Telecom </b><br />
                                        P.O. Box 25459 <br />
                                        Richmond, VA 23260 <br />
                                        Bill McBride(Syracuse) (315) 414-0127<br />
                                        <br />
                                        <b>Dunkirk &amp; Fredonia Telephone Company</b> <br />
                                        40 Temple St.<br />
                                        PO Box 209<br />
                                        Fredonia, NY 14063<br />
                                        Wade Weatherlow (716) 673-3031<br />
                                        <br />
                                        <b>Empire State Independent Network, LLC </b><br />
                                        80 State St 7th Floor <br />
                                        Albany, NY 12207 <br />
                                        Chuck Mason<br />
                                        <a href="mailto:cmazon@I-O-N.com" style={A_Style}>cmazon@I-O-N.com</a><br />
                                        (518) 269-7657 <br />
                                        <b>Additional Information: </b> ESIN LLC was purchased by ION HoldCo LLC
                                        <br /><br />
                                        <b>Energy East Telecommunications</b><br />
                                        81 State Street<br />
                                        Stephens Square-5th Flr.<br />
                                        Binghamton, NY 13901 <br />
                                        Toby Wollin (607) 721-1741<br />
                                        <br />
                                    </p>
                                </div>

                                <div style={{ minWidth: '25%', width: '250px' }}>
                                    <p>
                                        <b>FairPoint Communications </b><br />
                                        1 Taconic Place<br />
                                        Chatham, NY 12037 <br />
                                        Wm. Mulrein (518) 392-1246<b><br />
                                            <br />
                                            Fibertech Networks</b> <br />
                                        300 Meridian Centre<br />
                                        Rochester, NY 14618 <br />
                                        Kim Lonobile<br />
                                        (866) 697-5100<br />
                                        <br />
                                        <b>Gemini Networks NY, Inc. </b><br />
                                        280 Trumbull St.<br />
                                        Hartford, CT <br />
                                        Richard Rowlenson (860) 293-4281<br />
                                        <br />
                                        <b>InSITE Solutions, LLC</b> <br />
                                        3 Muir Woods Court<br />
                                        Annapolis, MD 21403 <br />
                                        Michael Davis (410) 598-9291<br />
                                        <br />
                                        <b>MCI Worldcom </b> <br />
                                        2400 Glenville<br />
                                        Richardson, TX 75802 <br />
                                        Joe Loughmiller (972) 729-7888<br />
                                        <b><br />
                                            NEON Optica (Communications)</b> <br />
                                        2200 West Park Dr.<br />
                                        Westborough, MA 01581 <br />
                                        Scott Richardson (508) 616-7892<br />
                                        <br />
                                        <b>SLIC Network Solutions </b><br />
                                        51 Main Street, Suite 102<br />
                                        Potsdam, NY 13676 <br />
                                        Phil Wagschal (315) 274-9050<br />
                                        Email: <a href="mailto:sales@slic.com" style={A_Style}>sales@slic.com</a>
                                    </p>
                                </div>

                                <div style={{ minWidth: '22%', width: '250px' }}>
                                    <p>
                                        <b>AT&amp;T GRE Lease Administration </b><br />
                                        1099 Jay Street, Bldg. B<br />
                                        Box B13<br />
                                        Rochester, NY 14611 <br />
                                        Eric Finnemore (585) 613-9913<br />
                                        <br />
                                        <b>Tech Valley Communications </b><br />
                                        87 State St.<br />
                                        Albany, NY 12207 <br />
                                        Michael Ryan (518) 694-8710<br />
                                        <br />
                                        <b>Level 3  </b><br />
                                        76 9th Ave, Suite 304 <br />
                                        New York, NY 10011<br />
                                        Kevin Gerson (917) 305-2387<br />
                                        Email: <a href="mailto:kevin.gerson@level3.com" style={A_Style}>kevin.gerson@level3.com</a>
                                        <br />
                                        <br />
                                        <b>Verizon Buffalo </b><br />
                                        65 Franklin St., Room 1400<br />
                                        Buffalo, NY 14202 <br />
                                        Linda Bidney <br />
                                        <br />
                                        <b>Verizon Albany </b><br />
                                        11 Wards Lane<br />
                                        Menands, NY 12204 <br />
                                        Keith Rodgers (518)471-2881
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div style={{ minWidth: '12%', width: '130px', paddingRight: '10px' }}>
                    <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <a href="index">
                            <img src={Img_button_back} width="51" height="15" border="0" alt="backbutton" />
                        </a>
                    </div>
                    <a href="../update_contactinfo?type=CLEC">
                        <img src={Img_contact_card} width="120" height="89" border="0" alt="card" />
                    </a>
                </div>
            </div>

            <p>&nbsp;</p>
        </div>
    );
}
export default Customer_Info;