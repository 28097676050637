import React from 'react';
import Helmet from 'react-helmet';
import c5_2_transmission from '../../assets/images/c5-2_strategy.jpg';
import StrategyInfo from './c5-2_strategy_info.js'
import '../../styles/Env.css'


function Strategy() {
    return (
        <div>
            <div id="EmfBody">

                <div className='row'>

                <div id="wrapper" style={{ maxWidth: '1050px' }}>
                        <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5; font-family: Arial, Helvetica, sans-serif' }} >
                            <title>Our Strategy</title>
                        </Helmet>
                        <div id="leftnav" style={{ minWidth: '18%' }}>
                
                
                    <div>&nbsp;</div>
                    
                            
                    </div>
            
        

                        <div id="actual_content_env" className="clearfix">
                            <div className="innerContent" style={{ maxWidth: '570px' }}>
                                <span className="pagehead">Auburn Transmission Project</span>
                               
                              <p>
                                <div id="realtive" style={{ minWidth: '18%', width: '180px' }}>
                            <div className='row'>
                                <div className="LeftSideBox">
                                    <img id="strategy_img" src={c5_2_transmission} border="0" alt="Transmission" width="200px" height="87px"  />
                                </div>
                            </div> 
                            </div>
                            <br></br>
                        
                         New York State Electric and Gas Corporation (NYSEG), and Niagara Mohawk Power Corporation d/b/a National Grid, are constructing a new 14.5-mile, 115-kilovolt (kV) electric transmission line (and other modifications to existing electric transmission lines) from the City of Auburn, NY to the Town of Elbridge, NY routed along existing rights of way in the Towns of Throop, Brutus, Sennett and Elbridge, the Village of Elbridge, and the City of Auburn.<br></br>
<br></br>
This project, known as the Auburn Transmission Project (ATP) will address system reliability issues in the City of Auburn and the surrounding area. The project will increase transfer capacity between NYSEG’s State Street Substation and National Grid’s Elbridge Substation; provide an alternate source of supply; and address system reliability issues by eliminating transmission limitations to the area. The project will benefit NYSEG’s customers who are currently supporting the Cayuga Generating Facility in the Town of Lansing. The project is needed regardless of Cayuga’s operating status.<br></br>
<br></br>
To learn more about the ATP, ask questions or register a complaint please click on the link to the right to view the project website.
      
</p>
      

                                
                            </div>

                            <div className="right_side" style={{ maxWidth: '180px' }}>
                                <span className="subHeadingText">Links</span>
                                <div className="rightside_content">
                                    <p style={{paddingLeft: '10px'}}>
                                        <StrategyInfo />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                
        </div>
        </div>
        </div>
    );
}
export default Strategy;