
import React from 'react';
import img_nymap_counties_layered1 from '../../../assets/images/nymap_counties_layered1.gif';
import img_conduit_county from '../../../assets/images/conduit_county.jpg';
import Helmet from 'react-helmet';


function SelectCounty() {

    return (
        <div>
            <Helmet bodyAttributes={{ style: 'background-color : #FFFFFF' }} />

            <div id="Layer1" style={{ position: 'absolute', width: '200px', height: ':115px', top: '1px', left: '17px', zIndex: '5' }}>
                <img src={img_conduit_county} alt="Country" width="331" height="75" />
            </div>
            <div id="outer_shell" width="692px" style={{ height: 482, zIndex: '4', paddingTop: 3, paddingLeft: 1}} >
                <img src={img_nymap_counties_layered1} width="692px" paddingright="10px" aspectratio="auto 692/482" height="482px" border="0" useMap="#mapny" id="ny" alt="Country" />

                <map name="mapny">
                    <area shape="poly" coords="355,260,367,260" href="#" alt="" />
                    <area shape="poly" coords="374,263,376,260,376,254,388,259,397,267,401,271,400,280,406,281,410,322,370,325,361,315,349,315,351,292,347,292,349,260,367,260" href="municipality2?county=Onondaga" alt="" />
                    <area shape="poly" coords="332,232" href="#" alt="" />
                    <area shape="poly" coords="329,237,322,240,324,260,326,280,322,283,322,288,322,297,323,305,325,313,323,319,322,324,323,332,326,341,330,345,343,345,355,346,363,347,372,348,371,336,369,324,362,316,351,315,350,293,346,292,349,264,346,256,335,257,334,245,334,233,332,230,329,235,324,239" href="municipality2?county=Cayuga" alt="" />
                    <area shape="poly" coords="292,219" href="#" alt="" />
                    <area shape="poly" coords="409,322" href="#" alt="" />
                    <area shape="poly" coords="409,323,411,337,411,354,414,371,411,374,371,374,371,355,372,349,371,334,372,325" href="municipality2?county=Cortland" alt="" />
                    <area shape="poly" coords="412,331,459,327,457,329,473,327,471,335,470,345,470,351,466,358,465,364,466,371,462,380,464,385,462,387,463,394,461,402,461,404,440,404,438,397,416,396,415,374" href="municipality2?county=Chenango" alt="" />
                    <area shape="poly" coords="334,230,337,225,341,222,345,220,353,215,361,214,367,214,371,212,375,209,379,196,393,198,394,194,420,196,421,225,416,224,408,246,410,247,410,255,409,264,407,269,402,268,396,264,391,260,385,256,376,253,376,256,375,262,373,262,368,260,368,257,363,257,350,258,349,255,335,257" href="municipality2?county=Oswego" alt="" />
                    <area shape="poly" coords="399,267,412,271,414,269,420,269,422,269,425,269,435,277,435,281,437,284,438,288,445,286,448,301,458,302,456,309,478,308,478,313,475,315,477,320,472,324,473,326,460,329,461,326,411,330,406,280,401,279" href="municipality2?county=Madison" alt="" />
                    <area shape="poly" coords="418,224,422,226,443,232,487,203,493,244,485,247,486,249,484,253,490,257,492,255,495,258,486,271,486,277,478,281,483,306,478,306,477,308,459,309,458,303,458,300,449,301,446,287,439,287,436,280,435,276,425,267,419,267,414,270,410,270,410,268,412,246,410,245" href="municipality2?county=Oneida" alt="" />
                    <area shape="poly" coords="407,93,453,120,441,135,444,138,447,139,446,142,449,144,446,147,442,147,442,155,434,155,420,167,410,166,411,180,420,182,420,195,395,193,393,198,379,195,379,193,377,192,377,186,376,178,372,172,370,167,366,163,363,159,369,162,373,165,376,166,378,162,383,160,386,158,386,155,383,154,378,154,378,150,385,142,384,134,379,136,372,137,369,142,365,139,363,134,366,126,375,117,385,112,393,108,401,104,405,99" href="municipality2?county=Jefferson" alt="" />
                    <area shape="poly" coords="409,93,492,142,543,135,542,125,544,123,531,15,523,14,522,5,515,4,504,9,500,12,497,12,477,26,461,38,447,51,435,65,429,65,428,71,424,77,419,85,415,90" href="municipality2?county=St Lawrence" alt="" />
                    <area shape="poly" coords="593,4,523,4,523,13,528,13,530,14,546,122,544,125,542,126,545,134,588,127,583,91,592,89,604,86,609,85,604,64,603,64,600,39,596,38,595,30,596,21" href="municipality2?county=Franklin" alt="" />
                    <area shape="poly" coords="614,84,625,84,633,84,636,79,641,78,644,74,650,73,650,70,654,66,660,66,662,65,659,57,660,52,661,48,660,45,659,41,659,37,663,23,660,19,661,12,663,8,666,4,596,4,593,5,596,14,597,21,597,25,596,29,597,36,601,38,604,41,603,46,603,50,603,55,604,60,609,82" href="municipality2?county=Clinton" alt="" />
                    <area shape="poly" coords="482,138,454,121,442,135,447,138,445,140,449,145,445,147,441,148,441,154,434,155,422,167,413,167,411,180,419,181,422,224,441,231,489,201" href="municipality2?county=Lewis" alt="" />
                    <area shape="poly" coords="517,240,523,224,514,140,492,143,482,138,490,201,495,245,489,247,487,250,487,255,489,255,493,254,496,256,496,259,488,272,487,279,481,282,483,304,482,308,484,309,489,309,494,302,513,312,516,304,527,308,530,286,526,277,527,271,528,269,532,267,530,264,534,261,534,251,518,241" href="municipality2?county=Herkimer" alt="" />
                    <area shape="poly" coords="518,139,572,130,573,146,564,154,581,173,585,168,590,173,594,173,598,175,596,178,596,183,581,184,591,251,583,252,582,258,574,258,574,255,551,256,519,240,525,226,513,140" href="municipality2?county=Hamilton" alt="" />
                    <area shape="poly" coords="662,67,655,68,651,72,650,74,644,77,642,79,637,81,634,85,611,84,583,90,588,127,571,131,573,146,565,154,581,170,590,169,594,172,600,174,597,177,597,182,662,170,662,164,665,163,662,159,665,144,663,123,661,120,662,107,663,94,663,87,662,80,661,73" href="municipality2?county=Essex" alt="" />
                    <area shape="poly" coords="582,185,589,184,658,171,656,175,654,175,657,178,654,187,656,192,653,195,648,199,645,205,644,206,645,209,649,241,642,241,641,245,640,245,637,244,634,247,630,251,627,250,626,249,620,247,624,244,620,237,618,234,616,229,590,234" href="municipality2?county=Warren" alt="" />
                    <area shape="poly" coords="535,252,544,255,549,257,569,255,573,257,575,259,583,259,584,253,591,252,596,288,554,290,530,284,528,277,528,274,532,274,532,268,532,267" href="municipality2?county=Fulton" alt="" />
                    <area shape="poly" coords="590,236,614,230,622,241,620,249,621,252,622,252,626,251,628,257,630,254,634,253,636,246,639,245,640,245,641,242,644,242,648,243,646,245,647,248,646,250,649,253,647,256,649,257,648,261,648,265,651,270,650,275,649,281,648,290,643,292,642,294,641,299,642,303,644,308,644,314,640,309,635,314,632,315,628,312,625,310,622,308,619,303,618,294,614,299,602,293,597,288" href="municipality2?county=Saratoga" alt="" />
                    <area shape="poly" coords="662,169,662,169,662,174,657,173,655,175,657,179,657,184,656,188,656,192,653,197,650,201,647,206,646,212,646,219,648,230,648,238,647,243,647,251,650,259,653,270,651,275,650,289,646,292,664,291,668,289,678,290,679,288,682,273,681,248,683,224,681,210,678,201,676,200,674,202,671,206,667,205,666,202,664,198,662,191,665,180" href="municipality2?county=Washington" alt="" />
                    <area shape="poly" coords="530,308,531,289,529,283,540,287,553,290,597,288,598,301,578,315,578,317,561,320,547,313,540,313" href="municipality2?county=Montgomery" alt="" />
                    <area shape="poly" coords="468,388,477,384,481,379,485,379,489,378,492,373,496,370,499,370,504,366,506,367,511,362,518,361,518,360,522,359,529,358,535,359,543,339,543,335,542,335,540,331,540,328,540,322,538,319,540,313,517,306,513,314,497,304,495,303,491,307,490,309,484,311,481,309,481,308,479,310,476,315,479,318,473,325,471,330,472,333,471,336,468,348,470,353,467,357,466,360,465,364,466,369,466,375,463,378,463,381,464,384" href="municipality2?county=Otsego" alt="" />
                    <area shape="poly" coords="537,358" href="#" alt="" />
                    <area shape="poly" coords="537,359,544,368,552,373,563,378,569,379,572,376,574,378,582,375,586,374,585,370,586,359,586,353,585,348,588,343,591,337,592,331,589,326,585,326,579,326,575,323,580,319,580,314,571,318,560,319,551,314,547,314,543,314,541,318,541,329,539,333,543,337,541,346" href="municipality2?county=Schoharie" alt="" />
                    <area shape="poly" coords="592,325,586,325,579,325,579,322,582,319,582,314,599,302,598,290,613,299,616,297,620,306,620,308,624,311,629,315,630,317" href="municipality2?county=Schenectdady" alt="" />
                    <area shape="poly" coords="587,371,634,362,633,354,635,350,636,345,636,341,636,337,640,334,637,332,642,319,643,315,640,310,634,317,628,319,593,326,593,334,587,344,585,352,586,362,587,369" href="municipality2?county=Albany" alt="" />
                    <area shape="poly" coords="636,361,676,354,687,322,679,303,679,290,670,291,667,290,663,293,650,294,646,294,644,297,643,303,645,307,644,315,642,321,638,330,641,334,638,337,638,346,635,351,634,356" href="municipality2?county=Rensselaer" alt="" />
                    <area shape="poly" coords="660,428,664,418,668,418,668,380,677,364,677,358,676,353,635,362,634,368,633,372,635,380,634,390,629,394,628,398,625,404,621,409,620,414,623,419,627,416,636,421,643,426,651,427" href="municipality2?county=Columbia" alt="" />
                    <area shape="poly" coords="142,236,126,241,119,239,113,243,106,242,102,245,97,249,92,249,85,250,81,251,77,252,79,257,77,259,75,263,76,269,77,274,81,275,82,280,88,280,93,284,93,279,100,283,101,285,106,282,109,280,116,279,123,279,130,279,131,277,134,280,138,282,140,283" href="municipality2?county=Niagara" alt="" />
                    <area shape="poly" coords="145,273,192,273,189,237,178,237,167,237,160,237,153,233,145,236,143,238,142,251,142,269,141,274" href="municipality2?county=Orleans" alt="" />
                    <area shape="poly" coords="192,240,193,273,201,273,196,284,201,287,200,293,219,294,219,300,245,300,245,287,257,287,255,250,251,252,246,252,241,255,235,253,228,249,222,244,214,244,198,239,192,237" href="municipality2?county=Monroe" alt="" />
                    <area shape="poly" coords="228,252,222,254,223,251,225,249" href="#" alt="" />
                    <area shape="poly" coords="245,351,243,338,232,338,230,327,234,325,234,313,237,313,236,305,234,302,244,300,246,288,259,288,269,289,270,287,279,286,280,289,295,291,299,292,299,302,296,314,299,325,261,327,259,331,257,338,257,342,256,352" href="municipality2?county=Ontario" alt="" />
                    <area shape="poly" coords="208,366,216,366,219,359,225,357,226,351,243,351,241,340,231,339,229,327,233,326,231,313,235,313,237,309,233,301,219,301,218,294,201,295,199,296,199,299,196,299,196,304,194,307,193,340,191,344,187,349,186,354,184,358,203,359" href="municipality2?county=Livingston" alt="" />
                    <area shape="poly" coords="142,273,140,311,181,311,182,308,192,308,196,304,197,299,199,296,199,292,198,289,196,288,193,287,198,273" href="municipality2?county=Genesee" alt="" />
                    <area shape="poly" coords="137,311" href="#" alt="" />
                    <area shape="poly" coords="136,321,141,323,140,359,158,360,159,358,183,359,185,351,188,347,192,343,194,325,192,309,182,308,180,312,139,311,137,311,137,321" href="municipality2?county=Wyoming" alt="" />
                    <area shape="poly" coords="81,280,82,287,86,292,87,297,89,300,90,305,92,312,95,316,95,321,90,322,88,327,83,330,82,330,79,330,72,340,68,347,72,352,74,355,81,355,84,358,84,364,91,367,96,370,100,366,105,366,109,366,112,364,117,364,122,362,125,361,130,360,138,358,140,357,139,324,136,322,136,312,138,311,140,281,137,282,132,281,127,282,123,281,121,280,112,280,105,283,104,285,100,285,96,282,91,284,90,283,85,282" href="municipality2?county=Erie" alt="" />
                    <area shape="poly" coords="2,390,3,433,75,432,75,357,71,357,70,353,68,349,65,348,62,349,45,359,44,365,38,370,32,370,26,374,22,378,18,380,11,381,9,384,5,388" href="municipality2?county=Chautauqua" alt="" />
                    <area shape="poly" coords="76,433,75,357,82,357,85,364,94,370,99,368,104,367,110,368,114,366,119,365,121,363,128,360,141,359,152,359,156,360,155,433" href="municipality2?county=Cattaraugus" alt="" />
                    <area shape="poly" coords="157,434,217,433,217,366,207,366,203,361,201,359,160,359,158,361,156,363" href="municipality2?county=Alleghany" alt="" />
                </map>

            </div>
        </div>
    );
}
export default SelectCounty;
