import React from 'react';
import imgNationalGrid from '../../assets/images/NationalGrid.png';

const ConnectsHeaderLayout =()=>{
    return (
        <>
        <header className="NG-mainHeader">          
        <div className='row' >
                <div className="col-2"></div>
                <div className="col-10" style={{borderBottom: "5px solid #e5e5e5"}}>
                <span className='NG-header-RegionTitle float-start'>Massachusetts</span>
                <span className='float-end px-4'>
                    <a className='Ng-Connect-Privacy' href='https://www1.nationalgridus.com/CorporatePrivacyPolicy' alt='Privacy Policy'>Home</a> &nbsp;&nbsp;
                    <a className='Ng-Connect-Privacy' href='https://www1.nationalgridus.com/CorporatePrivacyPolicy' alt='Unsolicited Emails'>Contact Us</a>
                </span>
                </div>
                
            </div>
            <nav className="navbar navbar-expand-md NG-fixedMenu">            
                <div className="container-fluid" style={{marginLeft:'-15px'}}>
                <a href='https://www1.nationalgridus.com'>
                  <img src={imgNationalGrid} alt="National Grid Logo" className="img-fluid"/>
                </a>                
                
              </div>
                
            </nav>
            
            
        </header>           
        
        </>
    );
}
export default ConnectsHeaderLayout;