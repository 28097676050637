import React from 'react';
import imgNationalGrid from '../../assets/images/NationalGrid.png'

const HeaderLayout =()=>{
    return (
        <>
        <header class="NG-mainHeader">
            <div class="NG-topNav d-flex justify-content-between">
              <ul class="NG-businessCagegory">
                <li class="NG-active"><i class="fa fa-map-marker NG-pointer" aria-hidden="true"></i> <p>Upstate New York
                  <span><i class="fa fa-caret-right" aria-hidden="true"></i> Change Location</span></p>
                </li>
                <li><i class="fa fa-bomb" aria-hidden="true"></i> <p>Gas and Electric</p></li>
                <li><i class="fa fa-braille" aria-hidden="true"></i> <p class="me-2">For your Business</p> <i class="fa fa-caret-down" aria-hidden="true"></i></li>
              </ul>
              <ul class="NG-businessCagegory">
                <li><i class="fa fa-bomb" aria-hidden="true"></i> <p>Gas Emergencies</p></li>
                <li><i class="fa fa-bolt" aria-hidden="true"></i> <p>Power Outages</p></li>
                <li><p>Our Company</p></li>
                <li><p>Contact Us</p></li>
                <li><i class="fa fa-search" aria-hidden="true"></i></li>
              </ul>
            </div>
            <nav class="navbar navbar-expand-md NG-fixedMenu">
              <div class="container-fluid">
                <a href='https://www1.nationalgridus.com'>
                  <img src={imgNationalGrid} alt="National Grid Logo" class="img-fluid"/>
                </a>
                <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse NG-collapse" id="navbarCollapse">
                  <ul class="NG-navNav navbar-nav ms-auto mb-2 mb-md-0">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="https://www1.nationalgridus.com"><span>Your Account</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www1.nationalgridus.com"><span>Billing & Payments</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www1.nationalgridus.com"><span>Save Energy & Money</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www1.nationalgridus.com"><span>Safety & Outages</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www1.nationalgridus.com"><span>Energy Maketplace</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </li>
                    <li class="nav-item">
                      <button class="btn btn-primary NG-greenBTN" type="button"> <i class="fa fa-credit-card" aria-hidden="true"></i> Pay Your Bill</button>
                    </li>
                    <li class="nav-item">
                      <button class="btn btn-primary NG-blueBTN" type="button"><i class="fa fa-user" aria-hidden="true"></i> Sign In/Register</button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
           {/* <div class="NG-breadCrumb">
           <div class="container-fluid">
             <div class="row">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                 <nav aria-label="breadcrumb" class="breadcrumb">
                     <ol class="NG-Obreadcrumb breadcrumb">
                       <li class="breadcrumb-item"><a href="#">Home</a></li>
                       <li class="breadcrumb-item"><a href="#">Billing & Payments</a></li>
                       <li class="breadcrumb-item"><a href="#">Meters and Rates</a></li>
                       <li class="breadcrumb-item"><a href="#">Rates </a></li>
                       <li class="breadcrumb-item active" aria-current="page">Supply Costs</li>
                     </ol>
                   </nav>
               </div>
             </div>
           </div>
         </div> */}
         </>
    );
}
export default HeaderLayout;