export const townList =
{
    Albany:
        [
            <option value="Albany, City Of">Albany, City Of</option>,
            <option value="Altamont, Village of T Guilderland">Altamont, Village of T Guilderland</option>,
            <option value="Berne, Town Of">Berne, Town Of</option>,
            <option value="Bethlehem, Town Of">Bethlehem, Town Of</option>,
            <option value="Coeymans, Town Of">Coeymans, Town Of</option>,
            <option value="Cohoes, City Of">Cohoes, City Of</option>,
            <option value="Colonie, Town Of">Colonie, Town Of</option>,
            <option value="Colonie, Village of T Colonie">Colonie, Village of T Colonie</option>,
            <option value="Green Island, Village of T Green Island">Green Island, Village of T Green Island</option>,
            <option value="Guilderland, Town Of">Guilderland, Town Of</option>,
            <option value="Knox, Town Of">Knox, Town Of</option>,
            <option value="Menands, Village of T Colonie">Menands, Village of T Colonie</option>,
            <option value="New Scotland, Town Of">New Scotland, Town Of</option>,
            <option value="Ravena, Village of T Coeymans">Ravena, Village of T Coeymans</option>,
            <option value="Voorheesville, Village of T New Scotland">Voorheesville, Village of T New Scotland</option>,
            <option value="Watervliet, City Of">Watervliet, City Of</option>
        ],
    Alleghany:
        [
            <option value="Alma, Town Of">Alma, Town Of</option>,
            <option value="Andover, Town Of">Andover, Town Of</option>,
            <option value="Andover, Village of T Andover">Andover, Village of T Andover</option>,
            <option value="Bellmont, Town Of">Bellmont, Town Of</option>,
            <option value="Centerville, Town Of">Centerville, Town Of</option>,
            <option value="Cuba, Town Of">Cuba, Town Of</option>,
            <option value="Cuba, Village of T Cuba">Cuba, Village of T Cuba</option>,
            <option value="Friendship, Town Of">Friendship, Town Of</option>,
            <option value="Genesee, Town Of">Genesee, Town Of</option>,
            <option value="Independence, Town Of">Independence, Town Of</option>,
            <option value="New Hudson, Town Of">New Hudson, Town Of</option>,
            <option value="Scio, Town Of">Scio, Town Of</option>,
            <option value="Wellsville, Town Of">Wellsville, Town Of</option>,
            <option value="Wellsville, Village of T Wellsville">Wellsville, Village of T Wellsville</option>,
            <option value="Willing, Town Of">Willing, Town Of</option>,
            <option value="Wirt, Town Of">Wirt, Town Of</option>
        ],
    Cattaraugus:
        [
            <option value="Allegany, Town Of">Allegany, Town Of</option>,
            <option value="Allegany, Village of T Allegany">Allegany, Village of T Allegany</option>,
            <option value="Ashford, Town Of">Ashford, Town Of</option>,
            <option value="Carrollton, Town Of">Carrollton, Town Of</option>,
            <option value="Cattaraugus, Village of T New Albion">Cattaraugus, Village of T New Albion</option>,
            <option value="Cold Spring, Town Of">Cold Spring, Town Of</option>,
            <option value="Delevan, Village of T Yorkshire">Delevan, Village of T Yorkshire</option>,
            <option value="East Otto, Town Of">East Otto, Town Of</option>,
            <option value="Ellicottville, Town Of">Ellicottville, Town Of</option>,
            <option value="Ellicottville, Village of T Ellicott">Ellicottville, Village of T Ellicott</option>,
            <option value="Farmersville, Town Of">Farmersville, Town Of</option>,
            <option value="Franklinville, Town Of">Franklinville, Town Of</option>,
            <option value="Franklinville, Village of T Franklin">Franklinville, Village of T Franklin</option>,
            <option value="Freedom, Town Of">Freedom, Town Of</option>,
            <option value="Great Valley, Town Of">Great Valley, Town Of</option>,
            <option value="Hinsdale, Town Of">Hinsdale, Town Of</option>,
            <option value="Humphrey, Town Of">Humphrey, Town Of</option>,
            <option value="Ischua, Town Of">Ischua, Town Of</option>,
            <option value="Limestone, Village of T Carrollton">Limestone, Village of T Carrollton</option>,
            <option value="Little Valley, Town Of">Little Valley, Town Of</option>,
            <option value="Little Valley, Village of T Little Valley">Little Valley, Village of T Little Valley</option>,
            <option value="Lyndon, Town Of">Lyndon, Town Of</option>,
            <option value="Machias, Town Of">Machias, Town Of</option>,
            <option value="Mansfield, Town Of">Mansfield, Town Of</option>,
            <option value="Napoli, Town Of">Napoli, Town Of</option>,
            <option value="New Albion, Town Of">New Albion, Town Of</option>,
            <option value="Olean, City Of">Olean, City Of</option>,
            <option value="Olean, Town Of">Olean, Town Of</option>,
            <option value="Otto, Town Of">Otto, Town Of</option>,
            <option value="Perrysburg, Town Of">Perrysburg, Town Of</option>,
            <option value="Portville, Town Of">Portville, Town Of</option>,
            <option value="Portville, Village of T Portville">Portville, Village of T Portville</option>,
            <option value="Randolph, Town Of">Randolph, Town Of</option>,
            <option value="Randolph, Village of T Randolph">Randolph, Village of T Randolph</option>,
            <option value="Red House, Town Of">Red House, Town Of</option>,
            <option value="Salamanca, City Of">Salamanca, City Of</option>,
            <option value="Salamanca, Town Of">Salamanca, Town Of</option>,
            <option value="South Valley, Town Of">South Valley, Town Of</option>,
            <option value="Yorkshire, Town Of">Yorkshire, Town Of</option>
        ],
    Cayuga:
        [
            <option value="Brutus, Town Of">Brutus, Town Of</option>,
            <option value="Mentz, Town Of">Mentz, Town Of</option>,
            <option value="Montezuma, Town Of">Montezuma, Town Of</option>,
            <option value="Niles,Town Of">Niles,Town Of</option>,
            <option value="Owasco, Town Of">Owasco, Town Of</option>,
            <option value="Sennett, Town Of">Sennett, Town Of</option>,
            <option value="Sterling, Town Of">Sterling, Town Of</option>,
            <option value="Throop, Town Of">Throop, Town Of</option>
        ],
    Chautauqua:
        [
            <option value="Arkwright, Town Of">Arkwright, Town Of</option>,
            <option value="Bemus Point, Village of T Ellery">Bemus Point, Village of T Ellery</option>,
            <option value="Brocton, Village of T Portland">Brocton, Village of T Portland</option>,
            <option value="Busti, Town Of">Busti, Town Of</option>,
            <option value="Carroll, Town Of">Carroll, Town Of</option>,
            <option value="Cassadaga, Village of T Stockton">Cassadaga, Village of T Stockton</option>,
            <option value="Charlotte, Town Of">Charlotte, Town Of</option>,
            <option value="Chautauqua, Town Of">Chautauqua, Town Of</option>,
            <option value="CLymer, Town Of">Clymer, Town Of</option>,
            <option value="Dunkirk, City Of">Dunkirk, City Of</option>,
            <option value="Dunkirk, Town Of">Dunkirk, Town Of</option>,
            <option value="Ellery, Town Of">Ellery, Town Of</option>,
            <option value="Ellicott, Town Of">Ellicott, Town Of</option>,
            <option value="Falconer Village of T Ellicott">Falconer Village of T Ellicott</option>,
            <option value="French Creek, Town Of">French Creek, Town Of</option>,
            <option value="Fredonia, Village of T Pomfret">Fredonia, Village of T Pomfret</option>,
            <option value="Gerry, Town Of">Gerry, Town Of</option>,
            <option value="Hanover, Town Of">Hanover, Town Of</option>,
            <option value="Harmony, Town Of">Harmony, Town Of</option>,
            <option value="Jamestown, City Of">Jamestown, City Of</option>,
            <option value="Kiantone, Town Of">Kiantone, Town Of</option>,
            <option value="Lakewood, Village of T Busti">Lakewood, Village of T Busti</option>,
            <option value="Mayville, Village of T Chautauqua">Mayville, Village of T Chautauqua</option>,
            <option value="Mina, Town Of">Mina, Town Of</option>,
            <option value="North Harmony, Town Of">North Harmony, Town Of</option>,
            <option value="Panama, Village of T Harmony">Panama, Village of T Harmony</option>,
            <option value="Poland, Town Of">Poland, Town Of</option>,
            <option value="Pomfret, Town Of">Pomfret, Town Of</option>,
            <option value="Portland, Town Of">Portland, Town Of</option>,
            <option value="Ripley, Town Of">Ripley, Town Of</option>,
            <option value="Sheridan, Town Of">Sheridan, Town Of</option>,
            <option value="Sherman, Town Of">Sherman, Town Of</option>,
            <option value="Sherman, Village of T Sherman">Sherman, Village of T Sherman</option>,
            <option value="Silver Creek, Village of T Hanover">Silver Creek, Village of T Hanover</option>,
            <option value="Sinclairville, Village of T Gerry">Sinclairville, Village of T Gerry</option>,
            <option value="Sinclairville, Village of T Charlotte">Sinclairville, Village of T Charlotte</option>,
            <option value="Stockton, Town Of">Stockton, Town Of</option>,
            <option value="Westfield, Town Of">Westfield, Town Of</option>,
            <option value="Westfield, Village of T Westfield">Westfield, Village of T Westfield</option>
        ],
    Chenango:
        [
            <option value="Lincklaen, Town Of">Lincklaen, Town Of</option>
        ],
    Clinton:
        [
            <option value="Blackbrook, Town Of">Blackbrook, Town Of</option>,
            <option value="Clinton, Town Of">Clinton, Town Of</option>,
            <option value="Saranac, Town Of">Saranac, Town Of</option>
        ],
    Columbia:
        [
            <option value="Chatham, Town Of">Chatham, Town Of</option>,
            <option value="Claverack, Town Of">Claverack, Town Of</option>,
            <option value="Gallatin, Town Of">Gallatin, Town Of</option>,
            <option value="Germantown, Town Of">Germantown, Town Of</option>,
            <option value="Ghent, Town Of">Ghent, Town Of</option>,
            <option value="Greenport, Town Of">Greenport, Town Of</option>,
            <option value="Hudson, City Of">Hudson, City Of</option>,
            <option value="Kinderhook, Town Of">Kinderhook, Town Of</option>,
            <option value="Kinderhook, Village of T Kinderhook">Kinderhook, Village of T Kinderhook</option>,
            <option value="Livingston, Town Of">Livingston, Town Of</option>,
            <option value="Stockport, Town Of">Stockport, Town Of</option>,
            <option value="Stuyvesant, Town Of">Stuyvesant, Town Of</option>,
            <option value="Taghkanic, Town Of">Taghkanic, Town Of</option>,
            <option value="Valatie, Village of T Kinderhook">Valatie, Village of T Kinderhook</option>
        ],
    Cortland:
        [
            <option value="Cortland, City  Of">Cortland, City  Of</option>,
            <option value="Cortlandville, Town Of">Cortlandville, Town Of</option>,
            <option value="Cuyler, Town Of">Cuyler, Town Of</option>,
            <option value="Homer, Town Of">Homer, Town Of</option>,
            <option value="Homer, Village of  T Cortland">Homer, Village of  T Cortland</option>,
            <option value="Homer, Village of  T Homer">Homer, Village of  T Homer</option>,
            <option value="McGraw, Village of  T Cortland">McGraw, Village of  T Cortland</option>,
            <option value="Preble, Town Of">Preble, Town Of</option>,
            <option value="Scott, Town Of">Scott, Town Of</option>,
            <option value="Solon, Town Of">Solon, Town Of</option>,
            <option value="Truxton, Town Of">Truxton, Town Of</option>,
            <option value="Virgil, Town Of">Virgil, Town Of</option>
        ],
    Dutchess:
        [
            <option value="Hyde Park, Town Of">Hyde Park, Town Of</option>,
            <option value="Milan, Town Of">Milan, Town Of</option>,
            <option value="Pleasant Valley, Town Of">Pleasant Valley, Town Of</option>,
            <option value="Poughkeepsie, City Of">Poughkeepsie, City Of</option>,
            <option value="Red Hook, Town Of">Red Hook, Town Of</option>
        ],
    Erie:
        [
            <option value="Akron, Village of T Newstead">Akron, Village of T Newstead</option>,
            <option value="Amherst, Town Of">Amherst, Town Of</option>,
            <option value="Angola, Village of T Evans">Angola, Village of T Evans</option>,
            <option value="Blasdell, Village of T Hamburg">Blasdell, Village of T Hamburg</option>,
            <option value="Boston, Town Of">Boston, Town Of</option>,
            <option value="Brant, Town Of">Brant, Town Of</option>,
            <option value="Buffalo, City Of">Buffalo, City Of</option>,
            <option value="Cheektowaga, Town Of">Cheektowaga, Town Of</option>,
            <option value="Collins, Town Of">Collins, Town Of</option>,
            <option value="Concord, Town Of">Concord, Town Of</option>,
            <option value="Depew, Village of T Cheektowaga">Depew, Village of T Cheektowaga</option>,
            <option value="Depew, Village of T Lancaster">Depew, Village of T Lancaster</option>,
            <option value="Eden, Town Of">Eden, Town Of</option>,
            <option value="Evans, Town Of">Evans, Town Of</option>,
            <option value="Farnham, Village of T Brant">Farnham, Village of T Brant</option>,
            <option value="Grand Island, Town Of">Grand Island, Town Of</option>,
            <option value="Hamburg, Town Of">Hamburg, Town Of</option>,
            <option value="Kenmore, Village of T Tonawanda">Kenmore, Village of T Tonawanda</option>,
            <option value="Lackawanna, City Of">Lackawanna, City Of</option>,
            <option value="Newstead, Town Of">Newstead, Town Of</option>,
            <option value="North Collins, Town Of">North Collins, Town Of</option>,
            <option value="North Collins, Village of T North Collins">North Collins, Village of T North Collins</option>,
            <option value="Orchard Park, Town Of">Orchard Park, Town Of</option>,
            <option value="Sardinia, Town Of">Sardinia, Town Of</option>,
            <option value="Sloan, Village of T Cheektowaga">Sloan, Village of T Cheektowaga</option>,
            <option value="Tonawanda, City Of">Tonawanda, City Of</option>,
            <option value="Tonawanda, Town Of">Tonawanda, Town Of</option>,
            <option value="V Hamburg T Hamburg">Village of Hamburg T Hamburg</option>,
            <option value="V Lancaster T Lancaster">Village of Lancaster T Lancaster</option>,
            <option value="West Seneca, Town Of">West Seneca, Town Of</option>,
            <option value="Williamsville, Village of T Amherst">Williamsville, Village of T Amherst</option>,
            <option value="Williamsville, Village of T Cheektowaga">Williamsville, Village of T Cheektowaga</option>
        ],
    Essex:
        [
            <option value="Bloomingdale, Village of T St Arman">Bloomingdale, Village of T St Arman</option>,
            <option value="Crown Point, Town Of">Crown Point, Town Of</option>,
            <option value="Lake Placid, Village of T North Elba">Lake Placid, Village of T North Elba</option>,
            <option value="Minerva, Town Of">Minerva, Town Of</option>,
            <option value="Moriah, Town Of">Moriah, Town Of</option>,
            <option value="North Elba, Town Of">North Elba, Town Of</option>,
            <option value="North Hudson, Town Of">North Hudson, Town Of</option>,
            <option value="Port Henry, Village of T Moriah">Port Henry, Village of T Moriah</option>,
            <option value="Saranac Lake, Village of T Harrietstown">Saranac Lake, Village of T Harrietstown</option>,
            <option value="Saranac Lake, Village of T North Elba">Saranac Lake, Village of T North Elba</option>,
            <option value="Saranac Lake, Village of T St Arman">Saranac Lake, Village of T St Arman</option>,
            <option value="Schroon, Town Of">Schroon, Town Of</option>,
            <option value="St Arman, Town Of">St Arman, Town Of</option>,
            <option value="Ticonderoga, Town Of">Ticonderoga, Town Of</option>,
            <option value="Ticonderoga, Village Of"  >Ticonderoga, Village Of</option>,
            <option value="Westport, Town Of">Westport, Town Of</option>,
            <option value="Westport, Village Of"  >Westport, Village Of</option>
        ],
    Franklin:
        [
            <option value="Altamont, Town Of">Altamont, Town Of</option>,
            <option value="Bangor, Town Of">Bangor, Town Of</option>,
            <option value="Bombay, Town Of">Bombay, Town Of</option>,
            <option value="Brandon, Town Of">Brandon, Town Of</option>,
            <option value="Brighton, Town Of (Franklin County)">Brighton, Town Of (Franklin County)</option>,
            <option value="Brushton, Village of T Moriah">Brushton, Village of T Moriah</option>,
            <option value="Constable, Town Of">Constable, Town Of</option>,
            <option value="Dickinson, Town Of">Dickinson, Town Of</option>,
            <option value="Duane, Town Of">Duane, Town Of</option>,
            <option value="Fort Covington, Town Of">Fort Covington, Town Of</option>,
            <option value="Fort Covington, Village Of"  >Fort Covington, Village Of</option>,
            <option value="Franklin, Town Of">Franklin, Town Of</option>,
            <option value="Harrietstown, Town Of">Harrietstown, Town Of</option>,
            <option value="Malone, Town Of">Malone, Town Of</option>,
            <option value="Malone, Village of T Malone">Malone, Village of T Malone</option>,
            <option value="Moira, Town Of">Moira, Town Of</option>,
            <option value="Santa Clara, Town Of">Santa Clara, Town Of</option>,
            <option value="Tupper Lake, Village of T Altamont">Tupper Lake, Village of T Altamont</option>,
            <option value="Waverly, Town Of">Waverly, Town Off</option>,
            <option value="Westville, Town Of">Westville, Town Of</option>
        ],
    Fulton:
        [
            <option value="Bleecker, Town Of">Bleecker, Town Of</option>,
            <option value="Broadalbin, Town Of">Broadalbin, Town Of</option>,
            <option value="Broadalbin, Village of T Broadalbin">Broadalbin, Village of T Broadalbin</option>,
            <option value="Broadalbin, Village of T Mayfield">Broadalbin, Village of T Mayfield</option>,
            <option value="Caroga, Town Of">Caroga, Town Of</option>,
            <option value="Dolgeville, Village of T Manhiem">Dolgeville, Village of T Manhiem</option>,
            <option value="Dolgeville, Village of T Oppenheim">Dolgeville, Village of T Oppenheim</option>,
            <option value="Ephratah, Town Of">Ephratah, Town Of</option>,
            <option value="Gloversville, City Of">Gloversville, City Of</option>,
            <option value="Johnstown, City Of">Johnstown, City Of</option>,
            <option value="Johnstown, Town Of">Johnstown, Town Of</option>,
            <option value="Mayfield, Town Of">Mayfield, Town Of</option>,
            <option value="Mayfield, Village of T Mayfield">Mayfield, Village of T Mayfield</option>,
            <option value="Northhampton, Town Of">Northhampton, Town Of</option>,
            <option value="Northville, Village of T Northampton">Northville, Village of T Northampton</option>,
            <option value="Oppenheim, Town Of (Fulton County)">Oppenheim, Town Of (Fulton County)</option>,
            <option value="Perth, Town Of">Perth, Town Of</option>,
            <option value="Stratford, Town Of (Fulton County)">Stratford, Town Of (Fulton County)</option>
        ],
    Genesee:
        [
            <option value="Alabama, Town Of">Alabama, Town Of</option>,
            <option value="Alexander, Town Of">Alexander, Town Of</option>,
            <option value="Alexander, Village of T Alexander">Alexander, Village of T Alexander</option>,
            <option value="Attica, Village of T Alexander">Attica, Village of T Alexander</option>,
            <option value="Batavia, City Of">Batavia, City Of</option>,
            <option value="Batavia, Town Of">Batavia, Town Of</option>,
            <option value="Bergen, Town Of">Bergen, Town Of</option>,
            <option value="Bergen, Village of T Bergen">Bergen, Village of T Bergen</option>,
            <option value="Bethany, Town Of">Bethany, Town Of</option>,
            <option value="Byron, Town Of">Byron, Town Of</option>,
            <option value="Corfu, Village of T Pembroke">Corfu, Village of T Pembroke</option>,
            <option value="Darien, Town Of">Darien, Town Of</option>,
            <option value="Elba, Town Of">Elba, Town Of</option>,
            <option value="Elba, Village of T Elba">Elba, Village of T Elba</option>,
            <option value="Leroy, Town Of">Leroy, Town Of</option>,
            <option value="Leroy, Village of T Leroy">Leroy, Village of T Leroy</option>,
            <option value="Oakfield, Town Of">Oakfield, Town Of</option>,
            <option value="Oakfield, Village of T Oakfield">Oakfield, Village of T Oakfield</option>,
            <option value="Pavilion, Town Of">Pavilion, Town Of</option>,
            <option value="Pembroke, Town Of">Pembroke, Town Of</option>,
            <option value="Stafford, Town Of">Stafford, Town Of</option>
        ],
    Greene:
        [
            <option value="Athens, Town Of">Athens, Town Of</option>,
            <option value="Athens, Village of T Athens">Athens, Village of T Athens</option>,
            <option value="Coxsackie, Town Of">Coxsackie, Town Of</option>,
            <option value="New Baltimore, Town Of">New Baltimore, Town Of</option>
        ],
    Hamilton:
        [
            <option value="Arietta, Town Of (Hamilton County)">Arietta, Town Of (Hamilton County)</option>,
            <option value="Benson, Town Of">Benson, Town Of</option>,
            <option value="Hope, Town Of">Hope, Town Of</option>,
            <option value="Indian Lake, Town Of">Indian Lake, Town Of</option>,
            <option value="Inlet, Town Of">Inlet, Town Of</option>,
            <option value="Lake Pleasant, Town Of">Lake Pleasant, Town Of</option>,
            <option value="Long Lake, Town Of">Long Lake, Town Of</option>,
            <option value="Morehouse, Town Of">Morehouse, Town Of</option>,
            <option value="Speculator Village of T Lake Pleasant">Speculator Village of T Lake Pleasant</option>,
            <option value="Wells, Town Of">Wells, Town Of</option>
        ],
    Herkimer:
        [
            <option value="Cold Brook, Village of T Russia">Cold Brook, Village of T Russia</option>,
            <option value="Columbia, Town Of">Columbia, Town Of</option>,
            <option value="Danube, Town Of (Herkimer County)">Danube, Town Of (Herkimer County)</option>,
            <option value="Fairfield, Town Of">Fairfield, Town Of</option>,
            <option value="Frankfort, Town Of">Frankfort, Town Of</option>,
            <option value="Frankfort, Village of T Frankfort">Frankfort, Village of T Frankfort</option>,
            <option value="German Flatts, Town Of">German Flatts, Town Of</option>,
            <option value="Herkimer, Town Of">Herkimer, Town Of</option>,
            <option value="Herkimer, Village of T Herkimer">Herkimer, Village of T Herkimer</option>,
            <option value="Ilion, Village of T German Flatts">Ilion, Village of T German Flatts</option>,
            <option value="Ilion, Village of T Frankfort">Ilion, Village of T Frankfort</option>,
            <option value="Litchfield, Town Of">Litchfield, Town Of</option>,
            <option value="Little Falls, City Of">Little Falls, City Of</option>,
            <option value="Little Falls, Town Of">Little Falls, Town Of</option>,
            <option value="Manheim, Town Of (Herkimer County)">Manheim, Town Of (Herkimer County)</option>,
            <option value="Middleville, Village of T Fairfield">Middleville, Village of T Fairfield</option>,
            <option value="Middleville, Village of T Newport">Middleville, Village of T Newport</option>,
            <option value="Mohawk, Village of T German Flatts">Mohawk, Village of T German Flatts</option>,
            <option value="Newport, Town Of">Newport, Town Of</option>,
            <option value="Newport, Village of T Newport">Newport, Village of T Newport</option>,
            <option value="Norway, Town Of">Norway, Town Of</option>,
            <option value="Ohio, Town Of">Ohio, Town Of</option>,
            <option value="Oppenheim, Town Of (Herkimer County)">Oppenheim, Town Of (Herkimer County)</option>,
            <option value="Poland, Village of T Newport">Poland, Village of T Newport</option>,
            <option value="Poland, Village of T Russia">Poland, Village of T Russia</option>,
            <option value="Russia, Town Of">Russia, Town Of</option>,
            <option value="Salisbury, Town Of">Salisbury, Town Of</option>,
            <option value="Schuyler, Town Of">Schuyler, Town Of</option>,
            <option value="Stark, Town Of  (Herkimer County)">Stark, Town Of (Herkimer County)</option>,
            <option value="Stratford, Town Of (Herkimer County)">Stratford, Town Of (Herkimer County)</option>,
            <option value="Warren, Town Of">Warren, Town Of</option>,
            <option value="Webb, Town Of">Webb, Town Of</option>
        ],
    Jefferson:
        [
            <option value="Adams, Town Of">Adams, Town Of</option>,
            <option value="Adams, Village of T Adams">Adams, Village of T Adams</option>,
            <option value="Alexandria Bay, Village of T Alexander">Alexandria Bay, Village of T Alexander</option>,
            <option value="Alexandria, Town Of">Alexandria, Town Of</option>,
            <option value="Antwerp, Town Of">Antwerp, Town Of</option>,
            <option value="Antwerp, Village of T Antwerp">Antwerp, Village of T Antwerp</option>,
            <option value="Black River, Village of T Leray">Black River, Village of T Leray</option>,
            <option value="Black River, Village of T Rutland">Black River, Village of T Rutland</option>,
            <option value="Brownville, Town Of">Brownville, Town Of</option>,
            <option value="Brownville, Village of T Brownville">Brownville, Village of T Brownville</option>,
            <option value="Cape Vincent, Town Of">Cape Vincent, Town Of</option>,
            <option value="Cape Vincent, Village of T Cape Vincent">Cape Vincent, Village of T Cape Vincent</option>,
            <option value="Carthage, Village of T Wilna">Carthage, Village of T Wilna</option>,
            <option value="Champion, Town Of">Champion, Town Of</option>,
            <option value="Chaumont, Village of T Lyme">Chaumont, Village of T Lyme</option>,
            <option value="Clayton, Town Of">Clayton, Town Of</option>,
            <option value="Clayton, Village of T Clayton">Clayton, Village of T Clayton</option>,
            <option value="Deferiet, Village of T Wilna">Deferiet, Village of T Wilna</option>,
            <option value="Dexter, Village of T Brownville">Dexter, Village of T Brownville</option>,
            <option value="Ellisburg, Town Of">Ellisburg, Town Of</option>,
            <option value="Ellisburg, Village of T Ellisburg">Ellisburg, Village of T Ellisburg</option>,
            <option value="Evans Mills, Village of T Leray">Evans Mills, Village of T Leray</option>,
            <option value="Glen Park, Village of T Brownville">Glen Park, Village of T Brownville</option>,
            <option value="Glen Park, Village of T Pamelia">Glen Park, Village of T Pamelia</option>,
            <option value="Henderson, Town Of">Henderson, Town Of</option>,
            <option value="Herrings, Village of T Wilna">Herrings, Village of T Wilna</option>,
            <option value="Hounsfield, Town Of">Hounsfield, Town Of</option>,
            <option value="Leray, Town Of">Leray, Town Of</option>,
            <option value="Lorraine, Town Of">Lorraine, Town Of</option>,
            <option value="Lyme, Town Of">Lyme, Town Of</option>,
            <option value="Mannsville, Village of T Ellisburg">Mannsville, Village of T Ellisburg</option>,
            <option value="Orleans, Town Of">Orleans, Town Of</option>,
            <option value="Pamelia, Town Of">Pamelia, Town Of</option>,
            <option value="Philadelphia, Town Of">Philadelphia, Town Of</option>,
            <option value="Philadelphia, Village of T Philadelphia">Philadelphia, Village of T Philadelphia</option>,
            <option value="Rodman, Town Of">Rodman, Town Of</option>,
            <option value="Rutland, Town Of">Rutland, Town Of</option>,
            <option value="Sackets Harbor, Village of T Haunsfield">Sackets Harbor, Village of T Haunsfield</option>,
            <option value="Theresa, Town Of">Theresa, Town Of</option>,
            <option value="Theresa, Village of T Theresa">Theresa, Village of T Theresa</option>,
            <option value="Watertown, City Of">Watertown, City Of</option>,
            <option value="Watertown, Town Of">Watertown, Town Of</option>,
            <option value="West Carthage, Village of T Champion">West Carthage, Village of T Champion</option>,
            <option value="Wilna, Town Of">Wilna, Town Of</option>,
            <option value="Worth, Town Of">Worth, Town Of</option>
        ],
    Lewis:
        [
            <option value="Castorland, Village of T Denmark">Castorland, Village of T Denmark</option>,
            <option value="Constableville, Village of T West Turin">Constableville, Village of T West Turin</option>,
            <option value="Copenhagen, Village of T Denmark">Copenhagen, Village of T Denmark</option>,
            <option value="Croghan, Town Of">Croghan, Town Of</option>,
            <option value="Croghan, Village of T Croghan">Croghan, Village of T Croghan</option>,
            <option value="Croghan, Village of T New Bremen">Croghan, Village of T New Bremen</option>,
            <option value="Denmark, Town Of">Denmark, Town Of</option>,
            <option value="Diana, Town Of">Diana, Town Of</option>,
            <option value="Greig, Town Of">Greig, Town Of</option>,
            <option value="Harrisburg, Town Of">Harrisburg, Town Of</option>,
            <option value="Harrisville, Village of T Diana">Harrisville, Village of T Diana</option>,
            <option value="High Market, Town Of">High Market, Town Of</option>,
            <option value="Lewis, Town Of">Lewis, Town Of</option>,
            <option value="Leyden, Town Of">Leyden, Town Of</option>,
            <option value="Lowville, Town Of">Lowville, Town Of</option>,
            <option value="Lowville, Village of T Lowville">Lowville, Village of T Lowville</option>,
            <option value="Lyons Falls, Village of T Lyonsdale">Lyons Falls, Village of T Lyonsdale</option>,
            <option value="Lyons Falls, Village of T West Turin">Lyons Falls, Village of T West Turin</option>,
            <option value="Lyonsdale, Town Of">Lyonsdale, Town Of</option>,
            <option value="Martinsburg, Town Of">Martinsburg, Town Of</option>,
            <option value="Montague, Town Of">Montague, Town Of</option>,
            <option value="New Bremen, Town Of">New Bremen, Town Of</option>,
            <option value="Osceola, Town Of">Osceola, Town Of</option>,
            <option value="Pinckney, Town Of">Pinckney, Town Of</option>,
            <option value="Port Leyden, Village of T Leyden">Port Leyden, Village of T Leyden</option>,
            <option value="Port Leyden, Village of T Lyonsdale">Port Leyden, Village of T Lyonsdale</option>,
            <option value="Turin, Town Of">Turin, Town Of</option>,
            <option value="Turin, Village of T Turin">Turin, Village of T Turin</option>,
            <option value="Watson, Town Of">Watson, Town Of</option>,
            <option value="West Turin, Town Of">West Turin, Town Of</option>
        ],
    Livingston:
        [
            <option value="Avon, Town Of">Avon, Town Of</option>,
            <option value="Avon, Village of T Avon">Avon, Village of T Avon</option>,
            <option value="Caledonia, Town Of">Caledonia, Town Of</option>,
            <option value="Caledonia, Village of T Caledonia">Caledonia, Village of T Caledonia</option>,
            <option value="Conesus, Town Of">Conesus, Town Of</option>,
            <option value="Geneseo, Town Of">Geneseo, Town Of</option>,
            <option value="Geneseo, Village of T Geneseo">Geneseo, Village of T Geneseo</option>,
            <option value="Groveland, Town Of">Groveland, Town Of</option>,
            <option value="Leicester, Town Of">Leicester, Town Of</option>,
            <option value="Lima, Town Of">Lima, Town Of</option>,
            <option value="Lima, Village of T Lima">Lima, Village of T Lima</option>,
            <option value="Livonia, Town Of">Livonia, Town Of</option>,
            <option value="Livonia, Village of T Livonia">Livonia, Village of T Livonia</option>,
            <option value="Mount Morris, Town Of">Mount Morris, Town Of</option>,
            <option value="Sparta, Town Of">Sparta, Town Of</option>,
            <option value="York, Town Of">York, Town Of</option>
        ],
    Madison:
        [
            <option value="Canastota, Village of T Lennox">Canastota, Village of T Lennox</option>,
            <option value="Cazenovia, Town Of">Cazenovia, Town Of</option>,
            <option value="Cazenovia, Village of T Cazenovia">Cazenovia, Village of T Cazenovia</option>,
            <option value="Chittenago, Village of T Sullivan">Chittenago, Village of T Sullivan</option>,
            <option value="Deruyter, Town Of">Deruyter, Town Of</option>,
            <option value="Deruyter, Village of T Deruyter">Deruyter, Village of T Deruyter</option>,
            <option value="Fenner, Town Of">Fenner, Town Of</option>,
            <option value="Hamilton, Town Of">Hamilton, Town Of</option>,
            <option value="Lenox, Town Of">Lenox, Town Of</option>,
            <option value="Lincoln, Town Of">Lincoln, Town Of</option>,
            <option value="Madison, Town Of">Madison, Town Of</option>,
            <option value="Munnsville, Village of T Stockbridge">Munnsville, Village of T Stockbridge</option>,
            <option value="Nelson, Town Of">Nelson, Town Of</option>,
            <option value="Oneida, City Of INSIDE">Oneida, City Of INSIDE</option>,
            <option value="Oneida, City Of OUTSIDE">Oneida, City Of OUTSIDE</option>,
            <option value="Smithfield, Town Of">Smithfield, Town Of</option>,
            <option value="Stockbridge, Town Of">Stockbridge, Town Of</option>,
            <option value="Sullivan, Town Of">Sullivan, Town Off</option>,
            <option value="Wampsville, Village of T Lennox">Wampsville, Village of T Lennox</option>
        ],
    Monroe:
        [
            <option value="Brighton, Town Of (Monroe County)">Brighton, Town Of (Monroe County)</option>,
            <option value="Brockport, Village of T Sweden">Brockport, Village of T Sweden</option>,
            <option value="Chili, Town Of">Chili, Town Of</option>,
            <option value="Churchville, Village of T Riga">Churchville, Village of T Riga</option>,
            <option value="Clarkson, Town Of">Clarkson, Town Of</option>,
            <option value="Fairport, Village of T Perinton">Fairport, Village of T Perinton</option>,
            <option value="Gates, Town Of">Gates, Town Of</option>,
            <option value="Hamlin, Town Of">Hamlin, Town Of</option>,
            <option value="Henrietta, Town Of">Henrietta, Town Of</option>,
            <option value="Honeoye Falls, Village of T Mendon">Honeoye Falls, Village of T Mendon</option>,
            <option value="Mendon, Town Of">Mendon, Town Of</option>,
            <option value="Ogden, Town Of">Ogden, Town Of</option>,
            <option value="Parma, Town Of">Parma, Town Of</option>,
            <option value="Perinton, Town Of">Perinton, Town Of</option>,
            <option value="Pittsford, Town Of">Pittsford, Town Of</option>,
            <option value="Pittsford, Village of T Pittsford">Pittsford, Village of T Pittsford</option>,
            <option value="Riga, Town Of">Riga, Town Of</option>,
            <option value="Rush, Town Of">Rush, Town Of</option>,
            <option value="Scottsville, Village of T Wheatland">Scottsville, Village of T Wheatland</option>,
            <option value="Sweden, Town Of">Sweden, Town Of</option>,
            <option value="Wheatland, Town Of">Wheatland, Town Of</option>
        ],
    Montgomery:
        [
            <option value="Ames, Village of T Canajoharie">Ames, Village of T Canajoharie</option>,
            <option value="Amsterdam, City Of">Amsterdam, City Of</option>,
            <option value="Amsterdam, Town Of">Amsterdam, Town Of</option>,
            <option value="Canajoharie, Town Of">Canajoharie, Town Of</option>,
            <option value="Canajoharie, Village of T Canajoharie">Canajoharie, Village of T Canajoharie</option>,
            <option value="Charleston, Town Of">Charleston, Town Of</option>,
            <option value="Florida, Town Of">Florida, Town Of</option>,
            <option value="Fonda, Village of Mohawk">Fonda, Village of Mohawk</option>,
            <option value="Fort Johnson, Village of T Amsterdam">Fort Johnson, Village of T Amsterdam</option>,
            <option value="Fort Plain, Village of T Canajoharie">Fort Plain, Village of T Canajoharie</option>,
            <option value="Fort Plain, Village of T Minden">Fort Plain, Village of T Minden</option>,
            <option value="Fort Plain, Village of T Palatine">Fort Plain, Village of T Palatine</option>,
            <option value="Fultonville, Village of T Glen">Fultonville, Village of T Glen</option>,
            <option value="Glen, Town Of">Glen, Town Of</option>,
            <option value="Hagaman, Village of T Amsterdam">Hagaman, Village of T Amsterdam</option>,
            <option value="Minden, Town Of">Minden, Town Of</option>,
            <option value="Mohawk, Town Of">Mohawk, Town Of</option>,
            <option value="Nelliston, Village of T Palatine">Nelliston, Village of T Palatine</option>,
            <option value="Palatine Bridge, Village of T Palatine">Palatine Bridge, Village of T Palatine</option>,
            <option value="Palatine, Town Of">Palatine, Town Of</option>,
            <option value="Root, Town Of">Root, Town Of</option>,
            <option value="Saint Johnsville, Town Of">Saint Johnsville, Town Of</option>,
            <option value="Saint Johnsville, Village of T Saint Johnsville">Saint Johnsville, Village of T Saint Johnsville</option>,
            <option value="Stark, Town Of (Montgomery County)">Stark, Town Of (Montgomery County)</option>
        ],
    Niagara:
        [
            <option value="Barker, Village of T Somerset">Barker, Village of T Somerset</option>,
            <option value="Cambria, Town Of">Cambria, Town Of</option>,
            <option value="Hartland, Town Of">Hartland, Town Of</option>,
            <option value="Lewiston, Town Of">Lewiston, Town Of</option>,
            <option value="Lewiston, Village of T Lewiston">Lewiston, Village of T Lewiston</option>,
            <option value="Lockport, City Of">Lockport, City Of</option>,
            <option value="Lockport, Town Of">Lockport, Town Of</option>,
            <option value="Middleport, Village of T Hartland">Middleport, Village of T Hartland</option>,
            <option value="Middleport, Village of T Royalton">Middleport, Village of T Royalton</option>,
            <option value="Newfane, Town Of">Newfane, Town Of</option>,
            <option value="Niagara Falls, City Of">Niagara Falls, City Of</option>,
            <option value="Niagara, Town Of">Niagara, Town Of</option>,
            <option value="North Tonawanda, City Of">North Tonawanda, City Of</option>,
            <option value="Pendleton, Town Of">Pendleton, Town Of</option>,
            <option value="Porter, Town Of">Porter, Town Of</option>,
            <option value="Royalton, Town Of">Royalton, Town Of</option>,
            <option value="Somerset, Town Of">Somerset, Town Of</option>,
            <option value="Wheatfield, Town Of">Wheatfield, Town Of</option>,
            <option value="Wilson, Town Of">Wilson, Town Of</option>,
            <option value="Wilson, Village of T Wilson">Wilson, Village of T Wilson</option>,
            <option value="Youngstown, Village of T Porter">Youngstown, Village of T Porter</option>
        ],
    Oneida:
        [
            <option value="Annsville, Town Of">Annsville, Town Of</option>,
            <option value="Ava, Town Of">Ava, Town Of</option>,
            <option value="Barneveld, Village of T Trenton">Barneveld, Village of T Trenton</option>,
            <option value="Boonville, Town Of">Boonville, Town Of</option>,
            <option value="Boonville, Village of T Boonville">Boonville, Village of T Boonville</option>,
            <option value="Camden, Town Of">Camden, Town Of</option>,
            <option value="Camden, Village of T Camden">Camden, Village of T Camden</option>,
            <option value="Clayville, Village of T Paris">Clayville, Village of T Paris</option>,
            <option value="Clinton, Village of T Kirkland">Clinton, Village of T Kirkland</option>,
            <option value="Deerfield, Town Of">Deerfield, Town Of</option>,
            <option value="Florence, Town Of">Florence, Town Of</option>,
            <option value="Floyd, Town Of">Floyd, Town Of</option>,
            <option value="Forestport, Town Of">Forestport, Town Of</option>,
            <option value="Holland Patent, Village of T Trenton">Holland Patent, Village of T Trenton</option>,
            <option value="Kirkland, Town Of">Kirkland, Town Of</option>,
            <option value="Lee, Town Of">Lee, Town Of</option>,
            <option value="Marshall, Town Of">Marshall, Town Of</option>,
            <option value="Marcy, Town Of">Marcy, Town Of</option>,
            <option value="New Hartford, Town Of">New Hartford, Town Of</option>,
            <option value="New Hartford, Village of T New Hartford">New Hartford, Village of T New Hartford</option>,
            <option value="New York Mills, Village of T New Hartford">New York Mills, Village of T New Hartford</option>,
            <option value="New York Mills, Village of T Whitestown">New York Mills, Village of T Whitestown</option>,
            <option value="Oneida Castle, Village of T Vernon">Oneida Castle, Village of T Vernon</option>,
            <option value="Oriskany, Village of T Whitestown">Oriskany, Village of T Whitestown</option>,
            <option value="Paris, Town Of">Paris, Town Of</option>,
            <option value="Prospect Village of T Trenton">Prospect Village of T Trenton</option>,
            <option value="Remsen, Town Of">Remsen, Town Of</option>,
            <option value="Remsen, Village of T Remsen">Remsen, Village of T Remsen</option>,
            <option value="Remsen, Village of T Trenton">Remsen, Village of T Trenton</option>,
            <option value="Rome, City Of (INSIDE)">Rome, City Of (INSIDE)</option>,
            <option value="Rome, City Of (OUTSIDE)">Rome, City Of (OUTSIDE)</option>,
            <option value="Sherrill, City Of T Vernon">Sherrill, City Of T Vernon</option>,
            <option value="Steuben, Town Of">Steuben, Town Of</option>,
            <option value="Sylvan Beach, Village of T Vienna">Sylvan Beach, Village of T Vienna</option>,
            <option value="Trenton, Town Of">Trenton, Town Of</option>,
            <option value="Utica, City Of">Utica, City Of</option>,
            <option value="Vernon, Town Of">Vernon, Town Of</option>,
            <option value="Vernon, Village of T Vernon">Vernon, Village of T Vernon</option>,
            <option value="Verona, Town Of">Verona, Town Of</option>,
            <option value="Vienna, Town Of">Vienna, Town Of</option>,
            <option value="Western, Town Of">Western, Town Of</option>,
            <option value="Westmoreland, Town Of">Westmoreland, Town Of</option>,
            <option value="Whitesboro, Village of T Whitestown">Whitesboro, Village of T Whitestown</option>,
            <option value="Whitestown, Town Of">Whitestown, Town Of</option>,
            <option value="Yorkville, Village of T Whitestown">Yorkville, Village of T Whitestown</option>
        ],
    Onondaga:
        [
            <option value="Baldwinsville, Town Of Lysander">Baldwinsville, Town Of Lysander</option>,
            <option value="Baldwinsville, Town Of Van Buren">Baldwinsville, Town Of Van Buren</option>,
            <option value="Camillus, Town Of">Camillus, Town Of</option>,
            <option value="Cicero, Town Of">Cicero, Town Of</option>,
            <option value="Clay, Town Of">Clay, Town Of</option>,
            <option value="DeWitt, Town Of">DeWitt, Town Of</option>,
            <option value="East Syracuse, Village of">East Syracuse, Village of</option>,
            <option value="Elbridge, Town Of">Elbridge, Town Of</option>,
            <option value="Elbridge, Village Of">Elbridge, Village Of</option>,
            <option value="Fabius, Town Of">Fabius, Town Of</option>,
            <option value="Fabius, Village of">Fabius, Village of</option>,
            <option value="Fayetteville, Village of">Fayetteville, Village of</option>,
            <option value="Geddes, Town Of">Geddes, Town Of</option>,
            <option value="Lafayette, Town Of">Lafayette, Town Of</option>,
            <option value="Liverpool, Village of T Salina">Liverpool, Village of T Salina</option>,
            <option value="Lysander, Town Of">Lysander, Town Of</option>,
            <option value="Manlius, Town Of">Manlius, Town Of</option>,
            <option value="Manlius, Village of T Manlius">Manlius, Village of T Manlius</option>,
            <option value="Marcellus, Town Of">Marcellus, Town Of</option>,
            <option value="Minoa, Village of T Manlius">Minoa, Village of T Manlius</option>,
            <option value="North Syracuse, Village of T Cicero">North Syracuse, Village of T Cicero</option>,
            <option value="North Syracuse, Village of T Clay">North Syracuse, Village of T Clay</option>,
            <option value="Onondaga, Town Of">Onondaga, Town Of</option>,
            <option value="Otisco, Town Of">Otisco, Town Of</option>,
            <option value="Pompey, Town Of">Pompey, Town Of</option>,
            <option value="Salina, Town Of">Salina, Town Of</option>,
            <option value="Skaneateles, Town Of">Skaneateles, Town Of</option>,
            <option value="Skaneateles, Village of T Skaneateles">Skaneateles, Village of T Skaneateles</option>,
            <option value="Solvay, Village of T Geddes">Solvay, Village of T Geddes</option>,
            <option value="Spafford, Town Of">Spafford, Town Of</option>,
            <option value="Syracuse, City Of">Syracuse, City Of</option>,
            <option value="Tully, Town Of">Tully, Town Of</option>,
            <option value="Tully, Village of T Tully">Tully, Village of T Tully</option>,
            <option value="Van Buren, Town Of">Van Buren, Town Of</option>
        ],
    Ontario:
        [
            <option value="Canadice, Town Of">Canadice, Town Of</option>,
            <option value="Clifton Springs, Village of T Manchester">Clifton Springs, Village of T Manchester</option>,
            <option value="Clifton Springs, Village of T Phelps">Clifton Springs, Village of T Phelps</option>,
            <option value="East Bloomfield, Town Of">East Bloomfield, Town Of</option>,
            <option value="Farmington, Town Of">Farmington, Town Of</option>,
            <option value="Manchester, Town Of">Manchester, Town Of</option>,
            <option value="Manchester, Village of T Manchester">Manchester, Village of T Manchester</option>,
            <option value="Phelps, Town Of">Phelps, Town Of</option>,
            <option value="Richmond, Town Of">Richmond, Town Of</option>,
            <option value="West Bloomfield, Town Of">West Bloomfield, Town Of</option>
        ],
    Orleans:
        [
            <option value="Albion, Town Of (Orleans County)">Albion, Town Of (Orleans County)</option>,
            <option value="Albion, Village of T Albion">Albion, Village of T Albion</option>,
            <option value="Albion, Village of T Gaines">Albion, Village of T Gaines</option>,
            <option value="Barre, Town Of">Barre, Town Of</option>,
            <option value="Carlton, Town Of">Carlton, Town Of</option>,
            <option value="Claredon, Town Of">Claredon, Town Of</option>,
            <option value="Gaines, Town Of">Gaines, Town Of</option>,
            <option value="Holley, Village of T Murray">Holley, Village of T Murray</option>,
            <option value="Kendall, Town Of">Kendall, Town Of</option>,
            <option value="Lyndonville, Village of T Yates">Lyndonville, Village of T Yates</option>,
            <option value="Medina Village of T Ridgeway">Medina Village of T Ridgeway</option>,
            <option value="Medina Village of T Shelby">Medina Village of T Shelby</option>,
            <option value="Murray, Town Of">Murray, Town Of</option>,
            <option value="Ridgeway, Town Of">Ridgeway, Town Of</option>,
            <option value="Shelby, Town Of">Shelby, Town Of</option>,
            <option value="Yates, Town Of">Yates, Town Of</option>
        ],
    Oswego:
        [
            <option value="Albion, Town Of  (Oswego County)">Albion, Town Of (Oswego County)</option>,
            <option value="Altmar, Village of T Albion">Altmar, Village of T Albion</option>,
            <option value="Amboy, Town Of">Amboy, Town Of</option>,
            <option value="Boylston, Town Of">Boylston, Town Of</option>,
            <option value="Central Square, Village of T Hastings">Central Square, Village of T Hastings</option>,
            <option value="Cleveland, Village of T Constantia">Cleveland, Village of T Constantia</option>,
            <option value="Constantia, Town Of">Constantia, Town Of</option>,
            <option value="Fulton, City Of">Fulton, City Of</option>,
            <option value="Fulton, Town Of">Fulton, Town Of</option>,
            <option value="Granby, Town Of">Granby, Town Of</option>,
            <option value="Hannibal, Town Of">Hannibal, Town Of</option>,
            <option value="Hannibal, Village of T Hannibal">Hannibal, Village of T Hannibal</option>,
            <option value="Hastings, Town Of">Hastings, Town Of</option>,
            <option value="Lacona, Village of T Sandy Creek">Lacona, Village of T Sandy Creek</option>,
            <option value="Mexico,  Village of T Mexico">Mexico,  Village of T Mexico</option>,
            <option value="Mexico, Town Of">Mexico, Town Of</option>,
            <option value="Minetto, Town Of">Minetto, Town Of</option>,
            <option value="New Haven, Town Of">New Haven, Town Of</option>,
            <option value="Orwell, Town Of">Orwell, Town Of</option>,
            <option value="Oswego, City Of">Oswego, City Of</option>,
            <option value="Oswego, Town Of">Oswego, Town Of</option>,
            <option value="Palermo, Town Of">Palermo, Town Of</option>,
            <option value="Parish, Town Of">Parish, Town Of</option>,
            <option value="Parish, Village of T Parish">Parish, Village of T Parish</option>,
            <option value="Phoenix, Village of T Schroeppel">Phoenix, Village of T Schroeppel</option>,
            <option value="Pulaski, Village of T Richland">Pulaski, Village of T Richland</option>,
            <option value="Redfield, Town Of">Redfield, Town Of</option>,
            <option value="Richland, Town Of">Richland, Town Of</option>,
            <option value="Sandy Creek, Town Of">Sandy Creek, Town Of</option>,
            <option value="Sandy Creek, Village of T Sandy Creek">Sandy Creek, Village of T Sandy Creek</option>,
            <option value="Scriba, Town Of">Scriba, Town Of</option>,
            <option value="Schroeppel, Town Of">Schroeppel, Town Of</option>,
            <option value="Volney, Town Of">Volney, Town Of</option>,
            <option value="West Monroe, Town Of">West Monroe, Town Of</option>,
            <option value="Williamstown, Town Of">Williamstown, Town Of</option>
        ],
    Otsego:
        [
            <option value="Cherry Valley, Town Of">Cherry Valley, Town Of</option>,
            <option value="Cherry Valley, Village of T Cherry Valley">Cherry Valley, Village of T Cherry Valley</option>,
            <option value="Decatur, Town Of">Decatur, Town Of</option>,
            <option value="Maryland, Town Of">Maryland, Town Of</option>,
            <option value="Middlefield, Town Of">Middlefield, Town Of</option>,
            <option value="Roseboom, Town Of">Roseboom, Town Of</option>,
            <option value="Schenevus, Village of T Maryland">Schenevus, Village of T Maryland</option>,
            <option value="Springfield, Town Of">Springfield, Town Of</option>,
            <option value="Worcester, Town Of">Worcester, Town Of</option>
        ],
    Rensselaer:
        [
            <option value="Brunswick, Town Of">Brunswick, Town Of</option>,
            <option value="Castleton On Hudson, Village of T Schodack">Castleton On Hudson, Village of T Schodack</option>,
            <option value="East Greenbush, Town Of">East Greenbush, Town Of</option>,
            <option value="East Nassau, Village of T Nassau">East Nassau, Village of T Nassau</option>,
            <option value="Grafton, Town Of">Grafton, Town Of</option>,
            <option value="Hoosick Falls, Village of T Hoosick">Hoosick Falls, Village of T Hoosick</option>,
            <option value="Hoosick, Town Of">Hoosick, Town Of</option>,
            <option value="Nassau, Town Of">Nassau, Town Of</option>,
            <option value="Nassau, Village of T Nassau">Nassau, Village of T Nassau</option>,
            <option value="Nassau, Village of T Schodack">Nassau, Village of T Schodack</option>,
            <option value="North Greenbush, Town Of">North Greenbush, Town Of</option>,
            <option value="Petersburg, Town Of">Petersburg, Town Of</option>,
            <option value="Pittstown, Town Of">Pittstown, Town Of</option>,
            <option value="Poestenkill, Town Of">Poestenkill, Town Of</option>,
            <option value="Rensselaer, City Of">Rensselaer, City Of</option>,
            <option value="Sand Lake, Town Of (Rensselaer County)">Sand Lake, Town Of (Rensselaer County)</option>,
            <option value="Schaghticoke, Town Of">Schaghticoke, Town Of</option>,
            <option value="Schaghticoke, Village of T Schaghticoke">Schaghticoke, Village of T Schaghticoke</option>,
            <option value="Schodack, Town Of">Schodack, Town Of</option>,
            <option value="Stephentown, Town Of">Stephentown, Town Of</option>,
            <option value="Troy, City Of">Troy, City Of</option>,
            <option value="Valley Falls, Village of T Pittsford">Valley Falls, Village of T Pittsford</option>,
            <option value="Valley Falls, Village of T Schaghticoke">Valley Falls, Village of T Schaghticoke</option>
        ],
    Saratoga:
        [
            <option value="Ballston, Town Of">Ballston, Town Of</option>,
            <option value="Ballston Spa, Village of T Ballston">Ballston Spa, Village of T Ballston</option>,
            <option value="Ballston Spa, Village of T Milton">Ballston Spa, Village of T Milton</option>,
            <option value="Charlton, Town Of">Charlton, Town Of</option>,
            <option value="Clifton Park, Town Of">Clifton Park, Town Of</option>,
            <option value="Corinth, Town Of">Corinth, Town Of</option>,
            <option value="Corinth, Village of T Corinth">Corinth, Village of T Corinth</option>,
            <option value="Day, Town Of">Day, Town Of</option>,
            <option value="Edinburg, Town Of">Edinburg, Town Of</option>,
            <option value="Galway, Town Of">Galway, Town Of</option>,
            <option value="Galway, Village of T Galway">Galway, Village of T Galway</option>,
            <option value="Greenfield, Town Of">Greenfield, Town Of</option>,
            <option value="Hadley, Town Of">Hadley, Town Of</option>,
            <option value="Halfmoon, Town Of">Halfmoon, Town Of</option>,
            <option value="Malta, Town Of">Malta, Town Of</option>,
            <option value="Milton, Town Of">Milton, Town Of</option>,
            <option value="Moreau, Town Of">Moreau, Town Of</option>,
            <option value="Providence, Town Of">Providence, Town Of</option>,
            <option value="Round Lake, Village of T Malta">Round Lake, Village of T Malta</option>,
            <option value="Saratoga Springs, City Of (INSIDE)">Saratoga Springs, City Of (INSIDE)</option>,
            <option value="Saratoga Springs, City Of (OUTSIDE)">Saratoga Springs, City Of (OUTSIDE)</option>,
            <option value="Saratoga, Town Of">Saratoga, Town Of</option>,
            <option value="Schulerville, Village of T Saratoga">Schulerville, Village of T Saratoga</option>,
            <option value="South Glens Falls, Village of T Moreau">South Glens Falls, Village of T Moreau</option>,
            <option value="Stillwater, Town Of">Stillwater, Town Of</option>,
            <option value="Stillwater, Village of T Stillwater">Stillwater, Village of T Stillwater</option>,
            <option value="Victory Mills, Village of T Saratoga">Victory Mills, Village of T Saratoga</option>,
            <option value="Waterford, Town Of">Waterford, Town Of</option>,
            <option value="Waterford, Village of T Waterford">Waterford, Village of T Waterford</option>,
            <option value="Wilton, Town Of">Wilton, Town Of</option>
        ],
    Schenectdady:
        [
            <option value="Delanson, Village of T Duanesburg">Delanson, Village of T Duanesburg</option>,
            <option value="Duanesburg, Town Of">Duanesburg, Town Of</option>,
            <option value="Glenville, Town Of">Glenville, Town Of</option>,
            <option value="Niskayuna, Town Of">Niskayuna, Town Of</option>,
            <option value="Princetown, Town Of">Princetown, Town Of</option>,
            <option value="Rotterdam, Town Of">Rotterdam, Town Of</option>,
            <option value="Schenectady, City Of">Schenectady, City Of</option>,
            <option value="Scotia, Village of T Glenville">Scotia, Village of T Glenville</option>
        ],
    Schoharie:
        [
            <option value="Blenheim, Town Of">Blenheim, Town Of</option>,
            <option value="Broome, Town Of">Broome, Town Of</option>,
            <option value="Carlisle, Town Of">Carlisle, Town Of</option>,
            <option value="Cobleskill, Town Of">Cobleskill, Town Of</option>,
            <option value="Cobleskill, Village of T Cobleskill">Cobleskill, Village of T Cobleskill</option>,
            <option value="Esperance, Town Of">Esperance, Town Of</option>,
            <option value="Esperance, Village of T Esperance">Esperance, Village of T Esperance</option>,
            <option value="Middleburgh, Town Of">Middleburgh, Town Of</option>,
            <option value="Middleburgh, Village of T Middleburgh">Middleburgh, Village of T Middleburgh</option>,
            <option value="Richmondville, Town Of">Richmondville, Town Of</option>,
            <option value="Richmondville, Village of T Richmond">Richmondville, Village of T Richmond</option>,
            <option value="Schoharie, Town Of">Schoharie, Town Of</option>,
            <option value="Schoharie, Village of T Schoharie">Schoharie, Village of T Schoharie</option>,
            <option value="Seward, Town Of">Seward, Town Of</option>,
            <option value="Sharon Springs, Village of T Sharon">Sharon Springs, Village of T Sharon</option>,
            <option value="Sharon, Town Of">Sharon, Town Of</option>,
            <option value="Summit, Town Of">Summit, Town Of</option>,
            <option value="Wright, Town Of">Wright, Town Of</option>
        ],
    Seneca:
        [
            <option value="Junis, Town Of">Junis, Town Of</option>,
            <option value="Seneca Falls, Town Of">Seneca Falls, Town Of</option>,
            <option value="Tyre, Town Of">Tyre, Town Of</option>,
            <option value="Waterloo, Town Of">Waterloo, Town Of</option>
        ],
    St_Lawrence:
        [
            <option value="Brasher, Town Of">Brasher, Town Of</option>,
            <option value="Canton, Town Of">Canton, Town Of</option>,
            <option value="Canton, Village of T Canton">Canton, Village of T Canton</option>,
            <option value="Clare, Town Of">Clare, Town Of</option>,
            <option value="Clifton, Town Of">Clifton, Town Of</option>,
            <option value="Colton, Town Of">Colton, Town Of</option>,
            <option value="Dekalb, Town Of">Dekalb, Town Of</option>,
            <option value="Depeyster, Town Of">Depeyster, Town Of</option>,
            <option value="Edwards, Town Of">Edwards, Town Of</option>,
            <option value="Edwards, Village of T Edwards">Edwards, Village of T Edwards</option>,
            <option value="Fine, Town Of">Fine, Town Of</option>,
            <option value="Fowler, Town Of">Fowler, Town Of</option>,
            <option value="Gouverneur, Town Of">Gouverneur, Town Of</option>,
            <option value="Gouverneur, Village of T Gouverneur">Gouverneur, Village of T Gouverneur</option>,
            <option value="Hammond, Town Of">Hammond, Town Of</option>,
            <option value="Hammond, Village of T Hammond">Hammond, Village of T Hammond</option>,
            <option value="Hermon, Town Of">Hermon, Town Of</option>,
            <option value="Hermon, Village of T Hermon">Hermon, Village of T Hermon</option>,
            <option value="Heuvelton, Village of T Oswegoatchie">Heuvelton, Village of T Oswegoatchie</option>,
            <option value="Hopkinton, Town Of">Hopkinton, Town Of</option>,
            <option value="Lawrence, Town Of">Lawrence, Town Of</option>,
            <option value="Lisbon, Town Of">Lisbon, Town Of</option>,
            <option value="Louisville, Town Of">Louisville, Town Of</option>,
            <option value="Macomb, Town Of">Macomb, Town Of</option>,
            <option value="Madrid, Town Of">Madrid, Town Of</option>,
            <option value="Massena, Village of T Massena">Massena, Village of T Massena</option>,
            <option value="Morristown, Town Of">Morristown, Town Of</option>,
            <option value="Morristown, Village of T Morristown">Morristown, Village of T Morristown</option>,
            <option value="Norfolk, Town Of">Norfolk, Town Of</option>,
            <option value="Norwood, Village of T Norfolk">Norwood, Village of T Norfolk</option>,
            <option value="Norwood, Village of T Potsdam">Norwood, Village of T Potsdam</option>,
            <option value="Ogdensburg, City Of">Ogdensburg, City Of</option>,
            <option value="Oswegoatchie, Town Of">Oswegoatchie, Town Of</option>,
            <option value="Parishville, Town Of">Parishville, Town Of</option>,
            <option value="Piercefield, Town Of">Piercefield, Town Of</option>,
            <option value="Pierrpoint, Town Of">Pierrpoint, Town Of</option>,
            <option value="Pitcairn, Town Of">Pitcairn, Town Of</option>,
            <option value="Potsdam, Town Of">Potsdam, Town Of</option>,
            <option value="Potsdam, Village of T Potsdam">Potsdam, Village of T Potsdam</option>,
            <option value="Rensselaer Falls, Village of T Canton">Rensselaer Falls, Village of T Canton</option>,
            <option value="Richville, Village of T Dekalb">Richville, Village of T Dekalb</option>,
            <option value="Rossie, Town Of">Rossie, Town Of</option>,
            <option value="Russell, Town Of">Russell, Town Of</option>,
            <option value="Stockholm, Town Of">Stockholm, Town Of</option>,
            <option value="Waddington, Town Of">Waddington, Town Of</option>,
            <option value="Waddington, Village of T Waddington">Waddington, Village of T Waddington</option>
        ],
    Warren:
        [
            <option value="Bolton, Town Of">Bolton, Town Of</option>,
            <option value="Chester, Town Of">Chester, Town Of</option>,
            <option value="Glens Falls, City Of">Glens Falls, City Of</option>,
            <option value="Hauge, Town Of">Hauge, Town Of</option>,
            <option value="Horicon, Town Of">Horicon, Town Of</option>,
            <option value="Johnsburg, Town Of">Johnsburg, Town Of</option>,
            <option value="Lake George, Town Of">Lake George, Town Of</option>,
            <option value="Lake George, Village of T Lake George">Lake George, Village of T Lake George</option>,
            <option value="Lake Luzerne, Town Of">Lake Luzerne, Town Of</option>,
            <option value="Queensbury, Town Of">Queensbury, Town Of</option>,
            <option value="Stony Creek, Town Of">Stony Creek, Town Of</option>,
            <option value="Thurman, Town Of">Thurman, Town Of</option>,
            <option value="Warrensburg, Town Of">Warrensburg, Town Of</option>
        ],
    Washington:
        [
            <option value="Argyle, Town Of">Argyle, Town Of</option>,
            <option value="Argyle, Village of T Argyle">Argyle, Village of T Argyle</option>,
            <option value="Cambridge, Town Of">Cambridge, Town Of</option>,
            <option value="Cambridge, Village of T Cambridge">Cambridge, Village of T Cambridge</option>,
            <option value="Cambridge, Village of T White Creek">Cambridge, Village of T White Creek</option>,
            <option value="Dresden, Town Of">Dresden, Town Of</option>,
            <option value="Easton, Town Of">Easton, Town Of</option>,
            <option value="Fort Ann, Town Of">Fort Ann, Town Of</option>,
            <option value="Fort Ann, Village of T Fort Ann">Fort Ann, Village of T Fort Ann</option>,
            <option value="Fort Edward, Town Of">Fort Edward, Town Of</option>,
            <option value="Fort Edward, Village of T Fort Edwards">Fort Edward, Village of T Fort Edwards</option>,
            <option value="Grandville, Town Of">Grandville, Town Of</option>,
            <option value="Greenwich, Town Of">Greenwich, Town Of</option>,
            <option value="Greenwich, Village of T Easton">Greenwich, Village of T Easton</option>,
            <option value="Greenwich, Village of T Greenwich">Greenwich, Village of T Greenwich</option>,
            <option value="Hampton, Town Of">Hampton, Town Of</option>,
            <option value="Hartford, Town Of">Hartford, Town Of</option>,
            <option value="Hebron, Town Of">Hebron, Town Of</option>,
            <option value="Hudson Falls, Village of T Kingsbury">Hudson Falls, Village of T Kingsbury</option>,
            <option value="Jackson, Town Of">Jackson, Town Of</option>,
            <option value="Kingsbury, Town Of">Kingsbury, Town Of</option>,
            <option value="Putman, Town Of">Putman, Town Of</option>,
            <option value="White Creek, Town Of">White Creek, Town Of</option>,
            <option value="Whitehall, Town Of">Whitehall, Town Of</option>,
            <option value="Whitehall, Village of T Whitehall">Whitehall, Village of T Whitehall</option>

        ],
    Wayne:
        [
            <option value="Arcadia, Town Of T Arcadia">Arcadia, Town Of T Arcadia</option>,
            <option value="Galen, Town Of">Galen, Town Of</option>,
            <option value="Lyons, Town Of">Lyons, Town Of</option>,
            <option value="Lyons, Village of T Lyons">Lyons, Village of T Lyons</option>,
            <option value="Macedon, Town Of">Macedon, Town Of</option>,
            <option value="Macedon, Village of T Macedon">Macedon, Village of T Macedon</option>,
            <option value="Palmyra, Town Of">Palmyra, Town Of</option>,
            <option value="Palmyra, Village of T Palmyra">Palmyra, Village of T Palmyra</option>,
            <option value="Savannah, Town Of">Savannah, Town Of</option>
        ],
    Wyoming:
        [
            <option value="Attica, Town Of">Attica, Town Of</option>,
            <option value="Attica, Village of T Attica">Attica, Village of T Attica</option>,
            <option value="Castile, Town Of">Castile, Town Of</option>,
            <option value="Covington, Town Of">Covington, Town Of</option>,
            <option value="Orangeville, Town Of">Orangeville, Town Of</option>,
            <option value="Perry, Town Of">Perry, Town Of</option>,
            <option value="Wethersfield, Town Of">Wethersfield, Town Of</option>,
        ]

}

export default townList;
