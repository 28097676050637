// AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConnectsLayout } from './layouts';

import GasLeakMapUNY from './pages/GasLeakMap/GasLeakMapUNY';
import GasLeakMapLI from './pages/GasLeakMap/GasLeakMapLI';
import GasLeakMapNYM from './pages/GasLeakMap/GasLeakMapNYM';
import Municipality from './pages/niagaramohawk/attachments/Municipality';
import SelectCounty from './pages/niagaramohawk/attachments/SelectCounty';
import AttachmentForm from './pages/niagaramohawk/attachments/AttachmentForm';
import EdiNyNy from './pages/partners/marketers/EdiNyNy';
import ConnectsOrder from './pages/ConnectsOrder/ConnectsOrder';
import AllowanceMaKedma from './pages/partners/marketers/AllowanceMaKedma';
import AllowanceMaMa from './pages/partners/marketers/AllowanceMaMa';
import ApprovedRatesMaKedma from './pages/partners/marketers/ApprovedRatesMaKedma';
import ServiveterrMap from './pages/niagaramohawk/about_us/serviceterr_map';
import ServiceterrMapA from './pages/niagaramohawk/about_us/serviceterr_map_a';
//import Giving from './pages/Masselectric/about_us/giving';
//import EnvEmf from './pages/niagaramohawk/about_us/env_emf';
//import EnvPolicy from './pages/niagaramohawk/about_us/env_policy';
//import EnvPrograms from './pages/niagaramohawk/about_us/env_programs';
//import EnvISO from './pages/niagaramohawk/about_us/env_iso';
//import Environment from './pages/niagaramohawk/about_us/environment';
//import Community from './pages/niagaramohawk/about_us/community';
import C21Strategy from './pages/transmission/c2_1_strategy';
import C33Documents from './pages/transmission/c3_3_documents'; 
import C22NorthEast from './pages/transmission/c2_2_northeast';
import AquidneckReliability from './pages/transmission/aquidneck_reliability';
import SodeSubstation from './pages/transmission/sode_substation';
import IndexTransmission from './pages/transmission/index';
import IndexEnergySupply from './pages/energysupply/index';
import GoldenRockProject from './pages/transmission/Golden_Rock';
import Standocs8 from './pages/transmission/c3-8_standocs';
import Stategy from './pages/transmission/c5-2_strategy'
import MohicanRebuild from './pages/transmission/mohican_rebuild';
import LasherSubstation from './pages/transmission/lasher_substation';
import SpierRotterdam from './pages/transmission/spier_rotterdam';
import Newgenerator from './pages/transmission/c4-1_newgenerator';
import NewgeneratorProcess4 from './pages/transmission/c4-1_newgenerator_process';
import Conduit from './pages/niagaramohawk/attachments/catv/conduit';
import CustomerInfo from './pages/niagaramohawk/attachments/catv/customer_info';
import ConduitClec from './pages/niagaramohawk/attachments/clec/conduit_clec'
import ClecTowerApp from './pages/niagaramohawk/attachments/clec/clec_tower_app';
import Custodial from './pages/niagaramohawk/attachments/catv/custodial';
import TowerTeleInfo from './pages/niagaramohawk/attachments/catv/tower_tele_info';
import CatV2 from './pages/niagaramohawk/attachments/catv/catv_app2';
import CatvTower from './pages/niagaramohawk/attachments/catv/tower';
import CustomerInfoClec from './pages/niagaramohawk/attachments/clec/customer_info';
import Catv from './pages/niagaramohawk/attachments/catv/catv_index';
import Clec from './pages/niagaramohawk/attachments/clec/clec_index';
import CustoDial from './pages/niagaramohawk/attachments/clec/custodial';
import PoleClec from './pages/niagaramohawk/attachments/clec/pole';
import PoleFlow from './pages/niagaramohawk/attachments/clec/pole_flow';
import TeleInfo from './pages/niagaramohawk/attachments/clec/tele_info';
import ClecTower from './pages/niagaramohawk/attachments/clec/tower';
import ClecTowerTeleInfo from './pages/niagaramohawk/attachments/clec/tower_tele_info';
import Confidentiality from './pages/niagaramohawk/attachments/confidentiality';
import Dispute from './pages/niagaramohawk/attachments/dispute';
import HighVoltageAct from './pages/niagaramohawk/attachments/high_voltage_act';
import ContractorList from './pages/niagaramohawk/attachments/contractor_list';
import SafetyBeforeEntering from './pages/niagaramohawk/attachments/safety_before_entering';
import SafetyComponent from './pages/niagaramohawk/attachments/safety';
import YourBusiness from './pages/niagaramohawk/attachments/your_business';
import IndexWireless from './pages/niagaramohawk/attachments/wireless/index';
import IndexPrivate from './pages/niagaramohawk/attachments/private/index';
import ContractorQualification from './pages/niagaramohawk/attachments/contractor_qualification';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/GasLeakMapLI" element={<GasLeakMapLI />} />
                <Route path="/GasLeakMapNYM" element={<GasLeakMapNYM />} />
                <Route path="/GasLeakMapUNY" element={<GasLeakMapUNY />} />
                <Route path="/niagaramohawk/attachments/municipality2" element={<Municipality />} /> 
                <Route path="/niagaramohawk/attachments/select_county2" element={<SelectCounty />} />
                <Route path="/niagaramohawk/attachments/attachment_form2" element={<AttachmentForm />} />
                <Route path="/niagaramohawk/attachments/your_business" element={<YourBusiness />} />
                <Route path="/partners/marketers/edi_ny_ny" element={<EdiNyNy />} />
                <Route path="/partners/marketers/allowance_ma_kedma" element={<AllowanceMaKedma />} />
                <Route path="/partners/marketers/allowance_ma_ma" element={<AllowanceMaMa />} />
                <Route path="/partners/marketers/approved_rates_ma_kedma" element={<ApprovedRatesMaKedma />} />                
                <Route path="/masselectric/connects/forms/order" element={<ConnectsLayout component={<ConnectsOrder />} showFooter={true} />}/> 
                
                {/* These pages are archived as communicated by Linda */}
                
                {/* <Route path="/masselectric/about_us/giving" element={<Giving />} />
                <Route path="/niagaramohawk/about_us/community" element={<Community/>} />
                <Route path="/niagaramohawk/about_us/env_emf" element={<EnvEmf />} />
                <Route path="/niagaramohawk/about_us/env_iso" element={<EnvISO />} />
                <Route path="/niagaramohawk/about_us/env_policy" element={<EnvPolicy />} />
                <Route path="/niagaramohawk/about_us/env_programs" element={<EnvPrograms />} /> 
                <Route path="/niagaramohawk/about_us/environment" element={<Environment />} /> */}

                <Route path="/niagaramohawk/about_us/serviceterr_map_a" element={<ServiceterrMapA/>} />
                <Route path="/niagaramohawk/about_us/serviceterr_map" element={<ServiveterrMap/>} />                
                <Route path="/transmission/c2-1_strategy" element={<C21Strategy />} />
                <Route path="/transmission/c3-3_documents" element={<C33Documents />} />
                <Route path="/transmission/c2-2_northeast" element={<C22NorthEast />} />
                <Route path="/transmission/aquidneck_reliability" element={<AquidneckReliability />} />
                <Route path="/transmission/sode_substation" element={<SodeSubstation />} />
                <Route path="/transmission/index" element={<IndexTransmission />} />
                <Route path="/energysupply/index" element={<IndexEnergySupply />} />
                <Route path="/transmission/golden_rock" element={<GoldenRockProject />} />
                <Route path="/transmission/c3-8_standocs" element={<Standocs8 />} />
                <Route path="/transmission/c5-2_strategy" element={<Stategy />} />
                <Route path="/transmission/lasher_substation" element={<LasherSubstation />} />
                <Route path="/transmission/mohican_rebuild" element={<MohicanRebuild />} />
                <Route path="/transmission/spier_rotterdam" element={<SpierRotterdam />} />
                <Route path="/transmission/c4-1_newgenerator" element={<Newgenerator />} />
                <Route path="/niagaramohawk/attachments/catv/conduit" element={<Conduit />} />
                <Route path="/niagaramohawk/attachments/catv/customer_info" element={<CustomerInfo />} />
                <Route path="/transmission/c4-1_newgenerator_process" element={<NewgeneratorProcess4 />} />
                <Route path="/niagaramohawk/attachments/clec/clec_tower_app" element={<ClecTowerApp />} />
                <Route path="/niagaramohawk/attachments/clec/conduit" element={<ConduitClec />} />
                <Route path="/niagaramohawk/attachments/catv/Custodial" element={<Custodial />} />
                <Route path="/niagaramohawk/attachments/catv/tower_tele_info" element={<TowerTeleInfo />} />
                <Route path="/niagaramohawk/attachments/catv/catv_app2" element={<CatV2 />} />
                <Route path="/niagaramohawk/attachments/catv/tower" element={<CatvTower />} />
                <Route path="/niagaramohawk/attachments/clec/customer_info" element={<CustomerInfoClec />} />
                <Route path="/attachments/catv/catv_index" element={<Catv />} />
                <Route path="/attachments/clec/clec_index" element={<Clec />} />
                <Route path="/niagaramohawk/attachments/clec/custodial" element={<CustoDial />} />
                <Route path="/niagaramohawk/attachments/clec/pole" element={<PoleClec />} />
                <Route path="/niagaramohawk/attachments/clec/pole_flow" element={<PoleFlow />} />
                <Route path="/niagaramohawk/attachments/clec/tele_info" element={<TeleInfo />} />
                <Route path="/niagaramohawk/attachments/clec/tower" element={<ClecTower />} />
                <Route path="/niagaramohawk/attachments/clec/tower_tele_info" element={<ClecTowerTeleInfo />} />
                <Route path="/niagaramohawk/attachments/confidentiality" element={<Confidentiality />} />
                <Route path="/niagaramohawk/attachments/dispute" element={<Dispute />} /> 
                <Route path="/niagaramohawk/attachments/high_voltage_act" element={<HighVoltageAct />} /> 
                <Route path="/niagaramohawk/attachments/contractor_list" element={<ContractorList />} />
                <Route path="/niagaramohawk/attachments/safety_before_entering" element={<SafetyBeforeEntering />} />
                <Route path="/niagaramohawk/attachments/safety" element={<SafetyComponent />} />  
                <Route path="/niagaramohawk/attachments/wireless/index" element={<IndexWireless />} />
                <Route path="/niagaramohawk/attachments/private/index" element={<IndexPrivate />} /> 
                <Route path="/niagaramohawk/attachments/contractor_qualification" element={<ContractorQualification />} />
            </Routes>
        </Router>
    );
}

export default App;