
import React from 'react';
import Helmet from 'react-helmet';
import Img_pole_flowchart from '../../../../assets/images/pole_flowchart.gif';
import Img_button_back from '../../../../assets/images/button_back.gif';

function Pole_Flow() {
    return (
        <div >
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div className='row' style={{ paddingLeft: '10px', fontSize: '11px', fontFamily: 'Verdana, sans-serif' }}>
                <div style={{ minWidth: '78%', width: '550px' }}>
                    <div className='row'>
                        <div className='col-10'>

                            <p style={{ margin: '1em' }}>
                                <span className="Catv_PageHead"><b>CLEC-Distribution Pole Application Process Flow Diagram</b></span>
                                <br />
                                The following is a step by step illustration of the application process for pole attachments.
                            </p>

                            <div className='row' style={{ paddingLeft: '10px' }} >
                                <img src={Img_pole_flowchart} width="783" height="963" border="0" alt="flowchart" />
                            </div>
                        </div>

                    </div>
                </div>

                <div style={{ minWidth: '22%', width: '130px', paddingRight: '10px' }}>
                        <div style={{paddingTop: '20px'}}>
                            <a href="pole">
                                <img src={Img_button_back} width="51" height="15" border="0" alt="backbutton" />
                            </a>
                         </div>
                </div>
            </div>

            <p>&nbsp;</p>
        </div>
    );
}
export default Pole_Flow;