import React, { useState} from 'react';
import  '../../styles/transmission.css';
import shared_dot from '../../assets/images/shared_requiredinfo.gif';
import shared_line from '../../assets/images/shared_grn_line.gif';
import { useNavigate  } from "react-router-dom";

function Newgenerator()
{
    const navigate = useNavigate();
    const [DD1 , setDD1] = useState("None");
    const [DD2 , setDD2] = useState("None");
    const handleICPDD = (e) =>
    {
        setDD1( e.target.value );
    }

    const handleSOGDD = (e) =>
    {
        setDD2(e.target.value );
    }

    function process_page()
    {
        if(DD1 !== "None" && DD2 !== "None")
        {
            navigate('/transmission/c4-1_newgenerator_process',  { state: { DD1 , DD2 }  } );
        }
        else if(DD1 === "None" && DD2 !== "None")
        {
            alert("Please check your entries in the field noted below \n \n Interconnection Project Location is required")
        }
        else if(DD2 === "None" && DD1 !== "None")
        {
            alert("Please check your entries in the field noted below \n \n Size of Generator is required")
        }
        else
        {
            alert("Please check your entries in the field noted below \n \n Interconnection Project Location is required \n Size of Generator is required")
        }
    }
    

    return(
        <>
        <div className = "container mt-5">
            <p className = "pagehead">New Generator Documentation</p>
            <small>Define your profile below to view the applicable information:</small>
            <div className = "row mt-3">
                <div className = "col-lg-5">
                    <p className = "Section">
                        Section 1 of 2
                    </p>
                </div>
                <div className = "col-lg-3">
                    <small>
                    (<img alt="" className = "dot" src = {shared_dot}></img> = required information)
                    </small>
                </div>
            </div>
            <div className = "row mt-1">
                    <img alt="" style = {{width : "65%" , height : "2px"}} src = {shared_line}></img>
            </div>
            <div className = "row mt-2">
                <h6 style = {{fontWeight : "bold"}}>Generator Profile</h6>
            </div>
            <div className = "row">
                <div className = "col-lg-3">
                <img alt="" className = "dot" src = {shared_dot}></img>  Interconnection Project Location:
                </div>
                <div className = "col-lg-3">
                <select value = {DD1} onChange = {handleICPDD}>                
                    <option value="None">Select One</option>
                    <option value="MA, NH or RI">MA, NH or RI</option>
                    <option value="NY">NY</option>
                </select>
                </div>
            </div>
            <div className = "row mt-1">   
                <div className = "col-lg-3">
                <img alt="" className = "dot" src = {shared_dot}></img>  Size of Generator:
                </div>
                <div className = "col-lg-3">
                <select  value={DD2}  onChange = {handleSOGDD}>
                    <option value="None">Select One</option>
                    <option value="Greater than 20MW">Greater than 20MW</option>
                    <option value="20MW or Less">20MW or Less</option>
                </select>
                </div>
            </div>
            <div className = "row mt-5">
                    <img alt="" style = {{width : "50%" , height : "2px" , marginTop : "19px"}} src = {shared_line}></img>
                    <button onClick = {process_page} style = {{maxWidth : "9%" , minWidth : "15%"   , background: "#FFFFFF",  border: "1px solid #A9C7EC", color: "#006CB5",  fontweight: "bold"}}><small>Next</small></button>
            </div>
        </div>
        </>
    )
}

export default Newgenerator;
