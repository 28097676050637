import React from 'react';

const ConnectsFooterLayout =()=>{
    return (
        <>
        <footer className="NG-footer">
            <div className='row NG-Connect_footer'>
                
                <div className="col-12">
                <span className='NG-footer-copyright float-start'>Copyright © 2023  National Grid</span>
                <span className='float-end px-4'>
                    <a className='Ng-Connect-Privacy' href='https://www1.nationalgridus.com/CorporatePrivacyPolicy' alt='Privacy Policy'>Privacy Policy</a> &nbsp;&nbsp;
                    <a className='Ng-Connect-Privacy' href='https://www1.nationalgridus.com/CorporatePrivacyPolicy' alt='Unsolicited Emails'>Unsolicited Emails</a>
                </span>
                </div>
                
            </div>
        </footer>
        
        </>
        
    )
}
export default ConnectsFooterLayout;