import React from 'react';
import styles from  '../../../styles/transmission.module.css';

function Confidentiality() {
    return (
        <div>
            <div id="outer_shell" style={{position:"absolute", width:"100%", top: "16px", left: "0px"}}> 
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                <td align="right" valign="top">
                    <img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet"/>&nbsp;
                </td>
                <td align="left" valign="top" className = {styles.divpageheadblue}><b>Confidentiality Agreement</b></td>
                <td align="left" valign="top"><a href="policies.asp">
                    <img src="/images/catv/button_back.gif" width="51" height="15" border="0" alt=""/></a>
                    </td>
                </tr>
                <tr> 
                <td width="20" align="left" valign="top">&nbsp;</td>
                <td align="left" valign="top"> <p><b><br/>
                    </b>Both National Grid and the Licensee have a mutual obligation to
                    protect confidential information (the obligations are more fully described
                    in the Agreement). Essentially, the parties have essentially agreed
                    to:</p>
                    <p>&#8220;Confidential information&#8221; includes books, records, documents and other information disclosed in an application, the licensing process, audit or otherwise submitted as part of the Agreement, including forecasting information, customer information, information related to National Grid and/or Licensee facilities. Confidential information 
                    shall be marked or declared at the time of disclosure as &#8220;confidential&#8221;.</p>
                    <p>Each party agrees to use the same degree of care that it uses with similar confidential information of its own.</p>
                    <p>Each party shall restrict disclosure of the confidential information solely to those employees, agents and contractors that have a need to receive and know such confidential information to perform their work responsibilities.</p>
                    <p>Neither party shall disclose any confidential information to a third-party without the prior, written consent of the disclosing party except to the extent such information is required by applicable laws, rules and regulations, by a request, requirement, recommendation, or an order of any Governmental Authority, or by any subpoena or similar 
                    legal process.</p>
                    <p>The receiving party shall return or destroy confidential information received from the disclosing party, including any copies made by the receiving party, within thirty (30) days after a written request by the disclosing party, except for copies retained for archival purposes.</p>
                    <p>Questions relating to confidential information and each parties obligations and the ability to be disclose or shared confidential information shall be directed to legal counsel.</p></td>
                <td width="150" align="left" valign="top">&nbsp;</td>
                </tr>
            </table>
            </div>
        </div>
    );
}
export default Confidentiality;