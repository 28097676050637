
import React from 'react';
import Helmet from 'react-helmet';
import Img_button_back from '../../../assets/images/button_back.gif';
import Img_shared_bullet_diamond from '../../../assets/images/shared_bullet_diamond.gif';

function Dispute() {
    return (
        <div >
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div className='row' style={{ paddingLeft: '10px', paddingTop:'5px', fontSize: '11px', fontFamily: 'Verdana, sans-serif' }}>
                <div style={{ minWidth: '87%', width: '250px' }}>
                    <div className='row'>
                        <div className='col-12'>
                            <img src={Img_shared_bullet_diamond} width="9" height="12" border="0" alt="flowchart" style={{ verticalAlign: 'top', marginTop:'4px' }} /> <span className="Catv_PageHead"><b>Dispute Resolution</b></span>
                            <p style={{ margin: '1em' }}>
                                Disputes should be resolved among the local project representatives. In the event a dispute cannot be resolved locally or is not of a nature to be resolved locally, the Licensee is to submit the dispute in writing, setting forth the Licensee's position, actions taken to resolve, submittal of supporting documentation, and a proposed resolution. Licensee shall submit its dispute to:
                            </p>

                            <div className='row' style={{ paddingLeft: '10px' }} >
                                <p>
                                    <b>
                                        National Grid<br />
                                        300 Erie Boulevard West<br />
                                        Syracuse, NY 13202<br />
                                        Attn.: Mr. Joseph Snyder<br />
                                        Telecommunications Attachments Dept, C-1
                                    </b>
                                </p>
                                <p>
                                    The dispute shall be discussed at the intermediate level within National Grid and the Licensee's organizations for ten (10) days. In the event no resolution is reached, the dispute shall be referred to each parties respective Ombudsman.
                                </p>
                                <p>
                                    <b>
                                        National Grid Ombudsman:<br />
                                        Scott Leuthauser<br />
                                        V.P. Distribution Planning and Engineering
                                    </b>
                                </p>
                                <p>
                                    The dispute shall be discussed between the Ombudsmen for twelve (12) days. In the event no resolution is reached, either party may proceed to submit the dispute to the Commission for alternative dispute resolution.
                                </p>

                            </div>
                        </div>

                    </div>
                </div>

                <div style={{ minWidth: '13%', width: '80px' }}>
                    <div style={{ paddingTop: '5px', paddingLeft: '10px' }}>
                        <a href="policies">
                            <img src={Img_button_back} width="51" height="15" border="0" alt="backbutton" />
                        </a>
                    </div>
                </div>
            </div>

            <p>&nbsp;</p>
        </div>
    );
}
export default Dispute;