import {React } from 'react'

function Generator5()
{  
      return(
        <>
        <p style ={{fontWeight : "bold"}}>Information for:</p>
        <small>
            <p>NY Interconnection Projects <br></br> 20MW or Less</p>
            <p>Your project may follow either New York State's process, or another form of agreement. Pleas <a href="https://www9.nationalgridus.com/transmission/c3-7_accountmgmt.asp">contact your Account Manager</a>.</p>
            </small>
       </>
    );
}

export default Generator5;