
import bottom_banner from '../../../assets/images/bottom_banner.gif';





const FooterForm = () => {
    return (
        <div style={{
            backgroundImage: `url(${bottom_banner})`, backgroundRepeat: 'no-repeat',
            height: '140px', width: '100%', fontFamily: 'Verdana, sans-serif',
            fontSize: '11px', textAlign: 'right', bottom: 0, right: 0, left: 0
        }}>
            <br /><br /> &copy;2011 {/*{(new Date().getFullYear())}*/} National Grid &nbsp;
        </div>
    )
}
export default FooterForm;