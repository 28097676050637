import React from 'react';
import { Link } from "react-router-dom";
import styles from  '../../styles/transmission.module.css';

function C3_3_Documents() {
    let imgNyes = require('../../assets/images/nyes.jpg');
    return (<>            
            <div style={{paddingTop:"10px", fontFamily: "Arial,Helvetica,sans-serif", paddingLeft:"5px"}}>
            <div id="transmissionleftnav" className={styles.transmissionleftnav}>
                <div className = {styles.LeftSideBox}>
                
                </div>
            </div>
            <div id="actual_content" className = {styles.clearfix}>
	            <div className = {styles.div_innerContent}>
                <span className = {styles.divpagehead}>New York Energy Solution </span>
                <br/>
                <img id="nyes" src={imgNyes} border="0" alt="NYES" className={styles.nyes} />
                <br/><br/>
                <div className = {styles.textindent}>National Grid's New York Energy Solution (NYES) was submitted at the request of the 
                New York State Public Service Commission (PSC), which sought competitive proposals from utility and private 
                development companies under the Alternating Current Transmission Upgrades Proceedings (AC Proceeding) to 
                address the state's power concerns. Specifically, the AC Proceeding seeks to relieve historic electric 
                transmission congestion between upstate New York (where the bulk of electricity is made) and downstate 
                New York (the highest load area in the state), which has led to higher prices for electricity.
                </div>
                <br /><br />
                <div className = {styles.textindent}>In December 2015, the PSC recommended that the NYES serve as the basis 
                for further consideration under the competitive New York Independent System Operator's (NYISO) 
                Public Policy Transmission Needs solicitation. The Project includes the buildout of a new 345kV 
                transmission line from the Town of Marcy in Oneida County to the Town of New Scotland in Albany 
                County (Segment A), and a new 345kV transmission line from the Town of Schodack in Rensselaer 
                County to the Town of Pleasant Valley in Dutchess County (Segment B). In the NYISO's competitive 
                icitation, it was stated that projects should stay within established electric transmission 
                rights-of-way. The transmission improvements proposed by National Grid support public policy 
                objectives and provide broad-based benefits across the state by enabling cleaner energy sources, 
                improving system reliability and providing economic benefits. As the proposed Project crosses utility 
                service areas, they will become regulated facilities under the Federal Energy Regulatory Commission (FERC).
                </div>
                <br /><br />

                <p>
                    <span className = {styles.span_smallfont}>Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. 
                    Download <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">Adobe Reader</a>, free software to 
                    view and print PDF files.
                    </span>
                </p>   
            </div>

            <div className = {styles.divright_side}>
                <span className = {styles.divsubHeadingText}>Links</span>
                <div className = {styles.divrightside_content}>
                    <p className={styles.rightside_content_p}><b>New York State Public Service Commission Case Numbers:</b><br />
                    <a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterCaseNo=12-t-0502" target="_blank" rel="noreferrer">12-T-0502 (Initial Proceeding)</a></p>
                    <p className={styles.rightside_content_p}><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterCaseNo=13-E-0488" target="_blank" rel="noreferrer">13-E-0488 (Comparative Proceeding)</a></p>
                    <p className={styles.rightside_content_p}><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterCaseNo=13-m-0457" target="_blank" rel="noreferrer">13-M-0457 (New York Transmission Owners Plan Filings)</a></p>
                    <p className={styles.rightside_content_p}><b>Website:</b> <a href="http://nysenergysolution.com/index.htm" target="_blank" rel="noreferrer">www.nysenergysolution.com/</a></p>
                    <p className={styles.rightside_content_p}><b>Stakeholder Relations Lead: </b>Kevin Cushing</p>
                    <p className={styles.rightside_content_p}><b>Project Email:</b> 
                    <Link to='#' onClick={(e) => {
                        window.location.href = 'mailto:info@nysenergysolution.com'; e.preventDefault();
                        }}> info@nysenergysolution.com
                    </Link></p>
                    <p className={styles.rightside_content_p}><b>Project Hotline:</b> <br/>855-433-3611</p>
                    <p className={styles.rightside_content_p}><b>Project Facebook Page:</b> @NYSENERGYSOLUTION</p>
                    <p className={styles.rightside_content_p}><b>Project Twitter Feed:</b>&nbsp; @NYESGrid</p>                    
                </div>
            </div>

        </div>
 
    </div>
</>);
}

export default C3_3_Documents;