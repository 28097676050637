import React from "react";
import { useLocation } from "react-router-dom";
import Generator2 from "./Generator2";
import Generator3 from "./Generator3";
import Generator4 from "./Generator4";
import Generator31 from "./Generator3-1";
import Generator41 from "./Generator4-1";
import Generator6 from "./Generator6";
import Generator5 from "./Generator5";
import Generator1 from "./Generator1";


function Newgenerator_process4()
{

    const location = useLocation();
    const region = location.state.DD1 + " Interconnection Projects";
    const size = location.state.DD2;
    var Generator = "";
    
    if(location.state.DD1 === "NY" && location.state.DD2 === "Greater than 20MW")
    {
        Generator = <Generator2 />
    }
    else if(location.state.DD1 === "MA, NH or RI" && location.state.DD2 === "Greater than 20MW")
    {
        Generator = <Generator1 />
    }
    else if(location.state.DD1 === "MA, NH or RI" && location.state.DD2 === "20MW or Less")
    {
        Generator = <Generator3 />
    }
    else if(location.state.DD1 === "NY" && location.state.DD2 === "20MW or Less")
    {
        Generator = <Generator4 />
       
    }
    else if(location.state.Drop === "Yes" && location.state.loc === "MA, NH or RI" && location.state.size === "20MW or Less")
    {
        Generator = <Generator31 />
    }
    else if(location.state.Drop === "Yes" && location.state.loc === "NY" && location.state.size === "20MW or Less")
    {
        Generator = <Generator41 />
    }
    else if((location.state.Drop === "No" || location.state.Drop === "Don't Know") && location.state.loc === "NY" && location.state.size === "20MW or Less")
    {
        Generator = <Generator5 />
    }
    else
    {
        Generator = <Generator6 />
    }
    

    return(
        <div className = "container">
        <div className = "row mt-5 g-3">            
            <div className = "col-md-6 col-lg-9 col-8">
                <p style = {{color: "#333333", fontSize : "20px" , fontWeight: "bold", marginBottom: "-3px"}}>New Generator Documentation</p>
                <p>{region === "undefined Interconnection Projects" ?  " " : region} <br></br> {size}</p>
                     {Generator}      
                <small>
                    <span style = {{color: "#999999", fontsize: "smaller"}} >* Please note that by using this link, you will be leaving nationalgrid.com's website. Please review our <a href="https://www9.nationalgridus.com/0-1_privacypolicy.asp">Privacy Policy</a> for information on other websites.</span><br></br>
                    <span style = {{color: "#999999", fontsize: "smaller"}}  >Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. Download <a href="http://get.adobe.com/reader/">Adobe Reader</a>, free software to view and print PDF files.</span>
                </small>
            </div>
            <div className = "col-lg-3 col-md-3 col-3">
                <hr style ={{backgroundColor : "black" , height: "2px", width : "75%"}}></hr>
                <p style = {{marginLeft : "50px" , marginBottom : "2" , marginTop : "-2px" ,  fontsize: "1.4em" ,
                            fontWeight: "bold"}}><small>Related Information</small></p>
                <hr style = {{ width : "75%" }}></hr>
                <p style = {{marginLeft : "50px" , marginBottom : "-5px" , marginTop : "0px" }}><small>Read more about:</small></p>
                <p style = {{marginLeft : "50px" , marginBottom : "2px" , marginTop : "0px"}}>(<a href = "http://www.ferc.gov/industries/electric/indus-act/gi.asp">
                    <small>FERC Generation Interconnection Activities*</small></a>)</p>
                <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "10px" }}><small>System Operators:</small></p>
                <p style = {{marginLeft : "50px" ,  marginTop : "-5px" , marginBottom : "0px"}}><small>ISO New England / NEPOOL</small></p>
                <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "-5px"}}>(<a href = "http://www.iso-ne.com/"><small>www.iso-ne.com*</small></a>)</p>
        
                <p style = {{marginLeft : "50px" , marginBottom : "0px" , marginTop : "10px"}}><small>New York ISO</small></p>
                <p style = {{marginLeft : "50px" , marginBottom : "0px" ,  marginTop : "-5px" }}>(<a href = "http://www.nyiso.org"><small>www.nyiso.org*</small></a>)</p>
            </div>
            
        </div>
        </div>
    );
}

export default Newgenerator_process4;