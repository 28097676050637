import React from 'react';
import { Link } from "react-router-dom";
import styles from  '../../styles/transmission.module.css';
import nationalgridNetworks from '../transmission/non_html/network_map_usa.pdf';

function C2_2_NorthEast() {
    return (<>            
            <div style={{paddingTop:"10px", fontFamily: "Arial,Helvetica,sans-serif", paddingLeft:"5px"}}>
            <div id="transmissionleftnav" className={styles.transmissionleftnav}>
                <div className = {styles.LeftSideBox}>
                
                </div>
            </div>
            <div id="actual_content" className = {styles.clearfix}>
	            <div className = {styles.div_innerContent}>
                <span className = {styles.divpagehead}>Northeast Operations </span>                
                <div className = {styles.textindent}>Our Northeast Operations stretch from the suburbs of Boston 
                west to Lake Erie and from the Canadian border south to Rhode Island Sound. We own, maintain and operate 
                a 9,000-mile transmission system serving five states and a service area of 29,000 square miles. We provide 
                transmission services for more than 310 generators, five electric distribution companies, 53 municipal 
                electric companies and interconnect with a dozen other transmission providers. And, we do business daily 
                with two Independent System Operators in New York and New England.
                </div> <br/>
                <Link to="/transmission/c3-4_connected" className= {styles.subhead}>Getting Connected</Link>
                <div className = {styles.textindent}>Municipalities, cooperatives and generators—Learn how to connect to our transmission system.
                </div>
                <br />
                <Link to="/transmission/c3-7_accountmgmt" className= {styles.subhead}>Contact Your Account Manager</Link>
                <div className = {styles.textindent}>Your single point of contact for all existing wholesale 
                customers and generators—serving customers who connect at 69kV or higher.
                </div>
                <br />
                <Link to="/transmission/c3-8_standocs" className= {styles.subhead}>Operations Documentation</Link>
                <div className = {styles.textindent}>Access documents to assist you in connecting to the transmission system.
                </div>
                <p>
                    <span className = {styles.span_smallfont}>* Please note that by using this link, you will be leaving nationalgrid.com's website. Please review 
                    our <a href="https://www9.nationalgridus.com/0-1_privacypolicy.asp" target="_blank" rel="noreferrer">Privacy Policy</a> for information on other websites.
                    </span>
                </p>   
                <p>
                    <span className = {styles.span_smallfont}>Links noted by "(pdf)" are in PDF format and support Adobe Acrobat 5.0 and later. 
                    Download <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">Adobe Reader</a>, free software to 
                    view and print PDF files.
                    </span>
                </p>   
            </div>

            <div className = {styles.divright_side}>
                <span className = {styles.div_subHeadingText}>Related Information</span>
                <div className = {styles.divrightside_content}>
                    <p className={styles.rightside_content_p}>
                    <b><a href={nationalgridNetworks} target="_blank" rel="noreferrer">National Grid's Network in the U.S.
                    </a></b> (pdf)</p>
                    
                    <p className={styles.rightside_content_p}>System Operators:<br/> </p>
                    <p className={styles.rightside_content_p}>ISO New England / NEPOOL <br/>
                    (<a href="http://www.iso-ne.com" target="_blank" rel="noreferrer">www.iso-ne.com*</a> )<br/>
                    New York ISO <br/>
                    (<a href="http://www.nyiso.com" target="_blank" rel="noreferrer">www.nyiso.com*</a> )
                    </p>
                                        
                </div>
            </div>

        </div>
 
    </div>
</>);
}

export default C2_2_NorthEast;