import React from 'react';
import imgConduit_results from '../../../assets/images/conduit_results.jpg';
import imgSpacer from '../../../assets/images/spacer.gif';
import your_am from '../../../assets/images/your_am.gif';
import close_window from '../../../assets/images/close_window.gif';
import am_footer from '../../../assets/images/am_footer.gif';
import contactList from '../../../constants/contactList.js';
import FooterForm from './footer.js'
import Helmet from 'react-helmet';

function AttachmentForm() {
    const queryParams = new URLSearchParams(window.location.search)
    // Query srtring value
    var town = queryParams.get("town");
    var contact = "NA";

    if (town === "Albany, City Of" || town === "Cohoes, City Of")
        contact = contactList['Albany, City Of']
    else if (town === "Portville, Town Of" || town === "Portville, Village of T Portville")
        contact = contactList['Portville, Town Of']
    else if (town === "Dunkirk, City Of" || town === "Dunkirk, Town Of")
        contact = contactList['Dunkirk, City Of']
    else if (town === "Cortland, City  Of" || town === "Cortlandville, Town Of")
        contact = contactList['Cortland, City  Of']
    else if (town === "Amherst, Town Of" || town === "Buffalo, City Of" || town === "Cheektowaga, Town Of" || town === "Kenmore, Village of T Tonawanda" || town === "Lackawanna, City Of" || town === "Williamsville, Village of T Amherst")
        contact = contactList['Amherst, Town Of']
    else if (town === "Batavia, City Of" || town === "Batavia, Town Of")
        contact = contactList['Batavia, City Of']
    else if (town === "Herkimer, Village of T Herkimer")
        contact = contactList['Herkimer, Village of T Herkimer']
    else if (town === "Watertown, City Of")
        contact = contactList['Watertown, City Of']
    else if (town === "Cazenovia, Village of T Cazenovia")
        contact = contactList['Cazenovia, Village of T Cazenovia']
    else if (town === "Newfane, Town Of" || town === "Niagara Falls, City Of")
        contact = contactList['Newfane, Town Of']
    else if (town === "Utica, City Of" || town === "Rome, City Of (INSIDE)")
        contact = contactList['Utica, City Of']
    else if (town === "Solvay, Village of T Geddes" || town === "Syracuse, City Of")
        contact = contactList['Solvay, Village of T Geddes']
    else if (town === "Minetto, Town Of" || town === "Oswego, City Of" || town === "Pulaski, Village of T Richland")
        contact = contactList['Minetto, Town Of']
    else if (town === "Rensselaer, City Of" || town === "Troy, City Of")
        contact = contactList['Rensselaer, City Of']
    else if (town === "Clifton Park, Town Of" || town === "Saratoga Springs, City Of (INSIDE)" || town === "Saratoga Springs, City Of (OUTSIDE)" || town === "Saratoga, Town Of")
        contact = contactList['Clifton Park, Town Of']
    else if (town === "Niskayuna, Town Of" || town === "Schenectady, City Of" || town === "Scotia, Village of T Glenville")
        contact = contactList['Niskayuna, Town Of']
    else if (town === "Glens Falls, City Of" || town === "")
        contact = contactList['Glens Falls, City Of']
    else
        contact = contactList['NA']

    const handleClick = event => {
        window.print();
    };

    const closeTab = () => {
        window.close();
    };
    return (
        <div>
            <Helmet bodyAttributes={{ style: 'background-color : #FFFFFF' }} />

            <div className="row">
                <div style={{ maxWidth: '300px' }} >
                    <img src={imgConduit_results} alt="logo" border="0" height="75" />
                </div>

                <div style={{ maxWidth: '6%' }} >
                </div>
                <div style={{ maxWidth: '150px', paddingTop: "34px" }} >
                    <img src={close_window} alt="logo" height="25" onClick={closeTab} />
                </div>

            </div>

            <div className="row">
                <img src={imgSpacer} alt="Muncipality" width="8" height="40" />
            </div>


            {contact.map(c =>
                <div style={{ paddingLeft: 17, fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                    {c.sContact !== "" ? (
                        <div style={{ maxWidth: '550px' }}>
                            <div className="row">
                                <img src={your_am} alt="logo" className="col-sm-12 col-12" />
                            </div>

                            <div id="divData" className="row">
                                <div style={{ maxWidth: '250px' }}>
                                    <div>{c.sDisti}</div>
                                    <div>Attn:{c.sContact}</div>
                                    <div>{c.sDept}</div>
                                    <div>{c.sMailingAddress}</div>
                                    <div>{c.sCityState}</div>
                                </div>

                                <div style={{ maxWidth: '250px' }}>
                                    <div>Phone: {c.sPhone}</div>
                                    <div>Fax: {c.sFax}</div>
                                    <div>Email: <a href={"mailto:" + c.sEmail}>{c.sEmail}</a></div>
                                </div>
                            </div>

                            <div className="row">
                                <img src={am_footer} className="col-sm-12 col-12" onClick={handleClick} alt="logo" />
                            </div>
                        </div>

                    ) : (
                        <div>
                            No National Grid Conduit Network is available in this Municipality. <br />
                            Please do not submit a Conduit Occupancy Application for this Municipality.
                        </div>
                    )}
                </div>
            )
            }


            <div style={{ width: '100%', top: '800px', position: 'absolute' }}>
                <footer>
                    <FooterForm />
                </footer>
            </div>

        </div>
    );
}
export default AttachmentForm;
