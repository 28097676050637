import React from 'react';
import  '../../styles/transmission.css';
import shared_line from '../../assets/images/shared_grn_line.gif';
import { useNavigate  } from "react-router-dom";

function Generator31()
{  

    const navigate = useNavigate(); 
    function process_page()
    {

        navigate('/transmission/c4-1_newgenerator'); 
    }
    

      return(
        <>
        <p><small>In order for an Account Manager to contact you and complete your connection to our system, please review the following documentation and fill out our New Generator <a href = "https://www9.nationalgridus.com/transmission/c4-1_newgenerator_processb.asp?location=New%20England&thesecond=20MW%20or%20Greater&thethird=">Contact Form.</a></small></p>
                <p style ={{fontWeight : "bold"}}>Information for:</p>
            <p>MA, NH or RI Interconnection Projects  <br></br> 20MW or Less</p>
            <table class="table-responsive table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Interconnection Procedure</th>
                        <th scope="col">Interconnection Agreement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href = "http://www.iso-ne.com/regulatory/tariff/sect_2/sch23/index.html" >Schedule 23*</a></td>
                        <td>Exhibit 1 for Section II Schedule 23</td>
                    </tr>
                </tbody>
                </table>
                <div>
                    <p>Note:</p>
                    <li>To interconnect generators in this size range, developers/owners must contact the <a  href = "http://www.nyiso.org/">NYISO *.</a></li>
                    <li>An interconnection study, including a system impact analysis and a facility study, will be performed at the developer's/owner's cost.</li>
                </div>
                <div className = "row mt-4 mb-3">
                    <img alt="" style = {{width : "55%" , height : "2px"}} src = {shared_line}></img>
                    <button onClick = {process_page} style = {{width : "15%" ,  marginTop : "-25px" , background: "#FFFFFF",  border: "1px solid #A9C7EC", color: "#006CB5",  fontweight: "bold"}}><small>back</small></button>
                </div>
       </>
    );
}

export default Generator31;