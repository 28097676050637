
import React from 'react';
import Helmet from 'react-helmet';
import IMG_step1_a from '../../../../assets/images/step1_a.gif';
import IMG_step1_b from '../../../../assets/images/step1_b.gif';
import IMG_step1_c from '../../../../assets/images/step1_c.gif';
import IMG_step2_a from '../../../../assets/images/step2_a.gif';
import IMG_shared_bullet_diamond from '../../../../assets/images/shared_bullet_diamond.gif';
function CATV2() {
    return (
        <div>
                <Helmet bodyAttributes={{ style: 'background-color : #FFF; font-family: Verdana, sans-serif;FONT-SIZE: 11px' }} >
                    <title>National Grid</title>
                </Helmet>

                <div className='row' style={{paddingLeft:'15px',paddingRight:'10px'}}>
                    <p style={{margin:'1em'}}>
                        <span className="Catv_PageHead"><b>CATV-Distribution Pole Attachment Application</b></span>
                        <br />
                        Please complete the follow the steps.
                    </p>

                    <div className='col-12' >
                        <div className='row'>

                            <div style={{minwidth:'350px', maxWidth:'400px',paddingBottom:'10px'}}>
                                <img src={IMG_step1_a} width="375" height="93" alt="logo" />
                                <br/>
                                <br/>
                                <a href="../../../doc/ATT/ATT 1-NM Distr Pole Wired License Application.doc" target="_blank">
                                    <img src={IMG_step1_b} width="370" height="27" border="0" alt="logo" />
                                </a>
                                <div style={{paddingLeft:'20px'}}>
                                   <a href="../../../xls/1207/Exhibit-5_Dist.xls" style={{fontWeight:'normal',textDecoration: 'underline',color:'#00345B'}} target="_blank">Application Attachment Sheet for Distribution Poles(.xls)
                                   </a>
                                </div>
                                <a href="../../../doc/ATT/ATT 1A-NM Distr Pole Wired Attachment Removal Application.doc" target="_blank">
                                    <img src={IMG_step1_c} width="370" height="27" border="0" alt="logo" />
                                </a>
                            </div>

                            <div style={{minwidth:'350px', maxWidth:'400px',paddingBottom:'10px'}}>
                                <img src={IMG_step2_a} width="375" height="195" alt="logo" />
                            </div>

                        </div>
                        <div className='row'>
                            <p style={{height:'40px',paddingLeft:'20px'}}>
                                <br/>
                                <br/>
                                <b>
                                  <img src={IMG_shared_bullet_diamond} width="9" height="12" alt="bullet" /> 
                                   &nbsp; <a href="pole_flow" style={{color:'#00345B', textDecoration:'underline'}}>Application Process Flow Diagram</a>
                                </b>
                            </p>
                        </div>

                    </div>

                </div>
            </div>
    );
}
export default CATV2;