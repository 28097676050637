import React from 'react';
import styles from  '../../../../styles/catv.module.css';

function ClecTowerApp() {

    return (
            <>
            <div className="container">
            <div className={styles.body}  style={{position:"absolute", width:"100%", top: "10px", left: "0px", zindex: "9", height: "109px", visibility: "visible"}}> 
            <table style={{width:"100%"}} border="0" cellSpacing="0" cellPadding="0">
                <tr> 
                <td align="left" valign="top"> 
                <table style={{width:"100%"}} border="0" cellSpacing="0" cellPadding="2">
                    <tr> 
                        <td style={{width:"10px"}} align="left" valign="top">&nbsp;</td>
                        <td align="left" valign="top">
                        <table style={{width:"100%"}} border="0" cellSpacing="0" cellPadding="0">
                        <tr> 
                        <td><span className={styles.pagehead}><span className={ styles.spanBold }>CLEC-Transmission Pole Attachment Application </span></span></td>
                        </tr>
                        <tr> 
                        <td valign="top"><p>Please complete the follow the steps. </p>
                        <table style={{width:"790px"}} border="0" cellSpacing="0" cellPadding="0">                      
					    <tr>
                        <td style={{width:"400px"}} valign="top">
                            <img src="/images/catv/step1_a.gif" width="375" height="93" alt="" />
                            <a href="/pdf/non_html//ATT 1-NM Trans Pole Attachment Application Form.doc" target="_blank" rel="noreferrer">
                            <br/><br/>
                            <img src="/images/catv/step1_b.gif" width="375" height="27" border="0" alt="" />
                            </a>
                            <div style={{width:"358px", paddingLeft:"17px"}}>
                                <a className={styles.application} href="/pdf/non_html/NGRID_Application_Packet_-_May_20212_1 (2).xlsx" target="_blank" rel="noreferrer">Application - Wired Attachment Sheet for Transmission Poles(.xls)
                                </a>
                            </div>
						    <a href="/pdf/non_html/ATT 1A-NM Trans Pole Wired Attachment Removal Application.doc" target="_blank" rel="noreferrer">
                                <img src="/images/catv/step1_c.gif" width="375" height="27" border="0" alt="" />
                            </a>
                        </td>
                        <td style={{width:"190px"}} valign="top"><img src="/images/catv/step2_transmission.gif" width="375" height="123" alt="" /></td>
                      </tr>
                      <tr>
                        <td valign="top">&nbsp;</td>
                        <td valign="top">&nbsp;</td>
                      </tr>
                    </table>
                        <br/><br/>                  
                        </td>
                        </tr>
                        </table>
                        </td>
                        <td style={{width:"50px"}} align="right" valign="top">&nbsp;</td>
                    </tr>
                </table>
                </td>
            </tr>
        </table>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>  
            <h5>&nbsp;</h5>
            </div>
    </div>
    </>
    );
}

export default ClecTowerApp;