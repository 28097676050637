import React from 'react'
import '../../../styles/Niagramohawk.css' 
import download from "../../../assets/images/download_button.jpg"
import agreement from "../attachments/non_html/Appendix B - Contractor Qualification.doc"

function ContractorQualification()
{

    return(
        <div className = "container ml-1">
            <span className = "sectionhead">Qualification of Contractors</span>
            <div className = "row mt-2">
                <div className = "col-lg-9 col-md-6 col-sm-6 col-5">
                    <span style = {{fontSize : "13px" }}>In the event the Licensee (applicant) proposes to use a contractor and such contractor is not on National Grid’s list of currently used or acceptable contractors, the Licensee may request National Grid review and accept the Licensee’s proposed contractor. Prior to submittal, the Licensee shall have conducted their own evaluation of the proposed contractor and at time of submittal, the Licensee (applicant) shall make written representation that they have performed such an evaluation and that the Licensee finds the contractor acceptable for the proposed work assignment. The Licensees’ submittal requesting National Grid’s review and accept a proposed contractor shall include not only their representation above, but shall include a completed “Contractor Information Form”, Appendix B.</span>
                    <br></br> <br></br> <span style = {{fontSize : "13px" }}>Please submit completed Contractor Information Form to: </span>
                    <p style = {{fontSize : "14px" , fontWeight : "bold" , margin : "0px"}}>National Grid</p>
                    <p style = {{fontSize : "14px" , fontWeight : "bold" , margin : "0px"}}>300 Erie Boulevard West</p>
                    <p style = {{fontSize : "14px" , fontWeight : "bold" , margin : "0px"}}>Syracuse, New York 13202</p>
                    <p style = {{fontSize : "14px" , fontWeight : "bold" , margin : "0px"}}>Attn.: Mr. Joseph Snyder</p>
                    <p style = {{fontSize : "14px" , fontWeight : "bold" , margin : "0px"}}>ATelecommunications Attachments Department, C-1</p>
                </div>
                <div className = "col-lg-3 col-md-5 col-sm-3 col-7">
                     <span style = {{fontSize : "14px" , fontWeight : "bold"}}>Contractor Information</span> <br></br>
                    <span style = {{fontSize : "14px" , fontWeight : "bold"}}> &nbsp; &nbsp; Form ,Appendix B </span> <br></br>
                    &nbsp; &nbsp;&nbsp; &nbsp; <a href = {agreement} ><img alt = ""  src={download}></img> </a>
                </div>
            </div>
        </div>
    )
}

export default ContractorQualification 