import React from 'react';
import styles from  '../../../../styles/catv.module.css';
import { Link } from "react-router-dom";

function Conduit()
{
    return (
        <>
        <div className="container" >
        <div className={styles.body}  style={{position:"absolute", width:"100%", top: "10px", left: "0px", zindex: "9", height: "109px", visibility: "visible"}}> 
        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
        <tr> 
          <td align="left" valign="top" > 
          <table width="100%" border="0" cellSpacing="0" cellPadding="2">
              <tr> 
                <td width="10" align="left" valign="top">&nbsp;</td>
                <td align="left" valign="top">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tr> 
                      <td><span className={styles.pagehead}><span className={ styles.spanBold }>CLEC-Conduit Occupancy</span></span></td>
                    </tr>
                    <tr> 
                      <td valign="top"> If you aren't already signatory to a<span className={ styles.spanBold }> Conduit Occupancy Agreement</span> click on the Agreement below and follow the instructions.<br/>
                        <br/>
                        <table style={{width:"90%"}} border="0" cellPadding="1" cellSpacing="0">
                          <tr> 
                            <td width="20" align="right" valign="top"><img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet"/>&nbsp;</td>
                            <td align="left" valign="top"><span className={ styles.spanBold }><font color="003366">Conduit Occupancy Agreement</font></span></td>
                            <td align="right"> 
                              <a href="/pdf/non_html/Conduit_Occupancy_Agreement.pdf" target="_blank" rel="noreferrer">
                              <img src="/images/catv/download_button.jpg" width="90" height="23" border="0" alt="" /></a></td>
                          </tr>
                          <tr> 
                            <td align="right" valign="top"></td>
                            <td colSpan="2" align="left" valign="top">
                              <img src="/images/catv/blk_dot.gif" width="100%" height="1" alt="" />
                            </td>
                          </tr>
                          <tr>
                            <td align="right" valign="top">
                              <img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet"/>&nbsp;
                            </td>
                            <td align="left" valign="top"><span className={ styles.spanBold }>
                              <font color="003366">Third Party Instructions</font></span>
                            </td>
                            <td><div align="right">
                              <a href="/pdf/non_html/Conduit_Third_Party_Instructions.pdf" target="_blank" rel="noreferrer">
                              <img src="/images/catv/download_button.jpg" width="90" height="23" border="0" alt="" />
                              </a></div>
                            </td>
                          </tr>
                          <tr>
                            <td align="right" valign="top"></td>
                            <td colSpan="2" align="left" valign="top"><div align="right">
                                <img src="/images/catv/blk_dot.gif" width="100%" height="1" alt="" /></div></td>
                          </tr>
                          <tr>
                            <td align="right" valign="top">
                              <img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet" />&nbsp;
                            </td>
                            <td align="left" valign="top">
                              <span className={ styles.spanBold }><font color="003366">Rate Summary Sheet</font></span>
                            </td>
                            <td><div align="right">
                              <a href="/pdf/non_html/UGConduitRateSheet.pdf" target="_blank" rel="noreferrer">
                                <img src="/images/catv/download_button.jpg" width="90" height="23" border="0"  alt="" />
                                </a></div>
                            </td>
                          </tr>
                          <tr>
                            <td align="right" valign="top"></td>
                            <td colSpan="2" align="left" valign="top"><div align="right">
                                <img src="/images/catv/blk_dot.gif" width="100%" height="1"  alt="" /></div></td>
                          </tr>
                          
                          <tr>
                            <td align="right" valign="top"><img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet" />&nbsp;</td>
                            <td align="left" valign="top"><span className={ styles.spanBold }><font color="#003366">Conduit
                            Occupancy Application</font></span></td>
                            <td><div align="right">
                            <Link to="/niagaramohawk/attachments/clec/conduit_app" >                              
                              <img src="/images/catv/grn_arrow_app.gif" className="img-fluid" width="224" height="49" border="0"  alt="" />
                            </Link></div></td>
                          </tr>
                          <tr>
                            <td align="right" valign="top"></td>
                            <td colSpan="2" align="left" valign="top"><div align="right">
                                <img src="/images/catv/blk_dot.gif" width="100%" height="1"  alt="" /></div></td>
                          </tr>
                          <tr> 
                            <td align="right" valign="top"><img src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" alt="bullet" />&nbsp;</td>
                            <td align="left" valign="top"><span className={ styles.spanBold }><font color="003366">Application Process Flow Diagram</font></span><br/>
                              View the steps of the necessary for securing an agreement for Conduit Occupancy.</td>
                            <td><div align="right"><a href="/pdf/non_html/Website_Conduit_flowchart.vsd" target="_blank" rel="noreferrer">
                            <img src="/images/catv/flow_chart_go.gif" className="img-fluid" width="224" height="49" border="0"  alt="" /></a></div></td>
                          </tr>
                          <tr> 
                            <td align="right" valign="top"></td>
                            <td colSpan="2" align="left" valign="top">
                                <img src="/images/catv/blk_dot.gif" width="100%" height="1" alt="" ></img></td>
                          </tr>
                        
                          <tr> 
                            <td align="right" valign="top">&nbsp;</td>
                            <td colSpan="2" align="left" valign="top"><span className={ styles.spanBold }>For further information on Conduit Occupancy please contact:</span><br/>
                              </td>
                          </tr>
                          <tr>
                            <td align="right" valign="top">&nbsp;</td>
                            <td>
                              <img src="/images/catv/spacer.gif" width="10" height="36" alt="" ></img>
                              <img src="/images/catv/Contact1.jpg" name="mike" width="200" height="80" border="0" id="mike"  alt="" /><br/>&nbsp;
                              <a href="mailto:ThirdParty@nationalgrid.com">
                                <img src="/images/catv/Email.jpg" name="mike" width="300" height="20" border="0" id="mike" alt="Mail"></img>
                              </a>
                            </td>
                          </tr>					          
                        </table>
                    </td>
                    </tr>
                  </table>
                </td>
                <td width="50" align="right" valign="top">&nbsp;</td>
              </tr>
            </table>
            </td>
        </tr>
        </table>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h5>&nbsp;</h5>
      </div>
      </div>
        </>
    );
}

export default Conduit;