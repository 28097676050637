
import React from 'react';
import imgConduit_results from '../../../assets/images/conduit_results.jpg';
import imgSpacer from '../../../assets/images/spacer.gif';
import townList from '../../../constants/townList.js';
import Helmet from 'react-helmet';

function Municipality() {

    const queryParams = new URLSearchParams(window.location.search)
    // Query srtring value
    var county = queryParams.get("county");

    //Based on query string get the Town List Items
    var TownListItems = "NA";
    if (county === "Albany")
        TownListItems = townList.Albany
    else if (county === "Alleghany")
        TownListItems = townList.Alleghany
    else if (county === "Cattaraugus")
        TownListItems = townList.Cattaraugus
    else if (county === "Cayuga")
        TownListItems = townList.Cayuga
    else if (county === "Chautauqua")
        TownListItems = townList.Chautauqua
    else if (county === "Chenango")
        TownListItems = townList.Chenango
    else if (county === "Clinton")
        TownListItems = townList.Clinton
    else if (county === "Columbia")
        TownListItems = townList.Columbia
    else if (county === "Cortland")
        TownListItems = townList.Cortland
    else if (county === "Dutchess")
        TownListItems = townList.Dutchess
    else if (county === "Erie")
        TownListItems = townList.Erie
    else if (county === "Essex")
        TownListItems = townList.Essex
    else if (county === "Franklin")
        TownListItems = townList.Franklin
    else if (county === "Fulton")
        TownListItems = townList.Fulton
    else if (county === "Genesee")
        TownListItems = townList.Genesee
    else if (county === "Greene")
        TownListItems = townList.Greene
    else if (county === "Hamilton")
        TownListItems = townList.Hamilton
    else if (county === "Herkimer")
        TownListItems = townList.Herkimer
    else if (county === "Jefferson")
        TownListItems = townList.Jefferson
    else if (county === "Lewis")
        TownListItems = townList.Lewis
    else if (county === "Livingston")
        TownListItems = townList.Livingston
    else if (county === "Madison")
        TownListItems = townList.Madison
    else if (county === "Monroe")
        TownListItems = townList.Monroe
    else if (county === "Montgomery")
        TownListItems = townList.Montgomery
    else if (county === "Niagara")
        TownListItems = townList.Niagara
    else if (county === "Oneida")
        TownListItems = townList.Oneida
    else if (county === "Onondaga")
        TownListItems = townList.Onondaga
    else if (county === "Ontario")
        TownListItems = townList.Ontario
    else if (county === "Orleans")
        TownListItems = townList.Orleans
    else if (county === "Oswego")
        TownListItems = townList.Oswego
    else if (county === "Otsego")
        TownListItems = townList.Otsego
    else if (county === "Rensselaer")
        TownListItems = townList.Rensselaer
    else if (county === "Saratoga")
        TownListItems = townList.Saratoga
    else if (county === "Schenectdady")
        TownListItems = townList.Schenectdady
    else if (county === "Schoharie")
        TownListItems = townList.Schoharie
    else if (county === "Seneca")
        TownListItems = townList.Seneca
    else if (county === "St Lawrence" || county === "St%20Lawrence" || county ==="St.Lawrence")
    {
        TownListItems = townList.St_Lawrence
        county = "stlawrence";
    }
    else if (county === "Warren")
        TownListItems = townList.Warren
    else if (county === "Washington")
        TownListItems = townList.Washington
    else if (county === "Wayne")
        TownListItems = townList.Wayne
    else if (county === "Wyoming")
        TownListItems = townList.Wyoming

    var imgCounty = "";
    if (TownListItems === "NA")
        imgCounty = imgSpacer;
    else
        imgCounty = require('../../../assets/images/county/' + county.toLowerCase() + '.gif');


    // Selected town navigate to attachment_form2.asp 
    const handleChange = event => {
        window.location.href = "attachment_form2?town=" + event.target.value;
    };


    return (
        <div>
            <Helmet bodyAttributes={{ style: 'background-color : #FFFFFF' }} />
            <div>
                <img src={imgSpacer} alt="Muncipality" width="8" height="75" />
                <img src={imgConduit_results} alt="Town" width="331" height="75" />
            </div>
            <div className="row">
                <div style={{ minWidth: 260 }} class="col-3">
                    <select onChange={handleChange}
                        style={{ height: '19px', padding: 1, marginLeft: 50, minWidth: 180, maxWidth: 260, fontSize: '14px' }} >
                        <option value="municipality">Select one:</option>
                        options= {TownListItems}
                    </select>
                </div>
                <div className="col-9" style={{ paddingLeft: 30 }} >
                    <img src={imgCounty} alt="Country" />
                </div>
            </div>
        </div>
    );
}
export default Municipality;