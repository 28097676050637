import React from 'react';
import styles from  '../../styles/transmission.module.css';
import insurance from '../transmission/non_html/insurance.pdf';
import { Link } from 'react-router-dom'

function C2_1_Strategy() {
    let c2_1_strategy = require('../../assets/images/c2-1_strategy.jpg');
    return (<>            
            <div style={{paddingTop:"10px"}}>
            <div id="transmissionleftnav" className={styles.transmissionleftnav}>
                <div className = {styles.LeftSideBox}>
                    <img src={c2_1_strategy} style={{ width:"178px", height:"150px"}} border="0" alt="Our Strategy"></img>
                </div>
            </div>

            <div id="actualcontent" className = {styles.clearfix}>
                <div className = {styles.div_innerContent}>
                <span className = {styles.divpagehead}>Our Strategy</span>
                Our objective is to create sustainable value in transmission for our shareholders by providing excellent continuing service to our customers. <br />
                <br />
                Our business model focuses on ownership and efficient management of transmission assets and increasing transmission throughput on existing rights of way. We believe this can best be accomplished within an independent transmission company structure which can achieve many of the Federal Energy Commission's goals of reducing transmission costs 
                for customers and encouraging efficient transmission operation. <br />
                <br />
                
                We believe that our considerable U.S. expertise in transmission engineering and operations, combined with our public policy leadership and twelve years of successfully managing the U.K.'s competitive electricity market will allow us to capitalize on opportunities to grow our U.S. transmission business and thereby bring benefits 
                to customers and shareholders alike. <br />
                <br />
                <Link to="/transmission/c3-3_documents" className= {styles.subhead}>Articles of  Interest</Link>
                <div className = {styles.textindent}>Access white papers and articles supporting our transmission strategy. </div>
                    <br />
                    <a href="https://www9.nationalgridus.com/transmission/fiveyear/c3-6_fiveyear.asp" target="_blank" rel="noreferrer" className = {styles.subhead}>Five Year Statement</a> <br />
                    <div className = {styles.textindent}>National Grid's Five Year Statement, examining the current capabilities of the transmission system in New York and New England.</div>
                </div>

                <div className = {styles.div_right_side}>
                    <span className = {styles.div_subHeadingText}>Related Information</span>
                    <div className = {styles.div_rightside_content}>
                    <p className={styles.rightside_content_p}>Would you like proof of our insurance?   View:<br />
                    <a href={insurance} className={styles.insurance_link} target="_blank" rel="noreferrer">Memorandum of Insurance</a> (pdf)</p>
                    </div>
                </div>
            </div>    
            </div>
</>);
}

export default C2_1_Strategy;