import React from 'react';
import styles from  '../../../styles/ngww2.module.css';
import bostonratesPdf from '../marketers/docs/Boston-approvedRates-01-01-2005.pdf';
import energyNorthRates from '../marketers/docs/EnergyNorth-approvedRates-01-01-2005.pdf';

function ApprovedRatesMaKedma() {
    let imgArrowOrange = require('../../../assets/images/arrow_orange.gif'); 
    let imgArrowBrown = require('../../../assets/images/arrow_brown.gif'); 
    let imgSpace = require('../../../assets/images/partners/c.gif');
    let imgSpace_cc = require('../../../assets/images/cc.gif');
    let imgDownloadicon = require('../../../assets/images/icon_download.gif'); 
    let imgHrModifier = require('../../../assets/images/hr_modifier.gif'); 

    return (<>
            <div className={styles.threecoloumlayout} style={{height:"450px"}} > 
            <div className={styles.column2}>
                <table style={{width:"484px"}} border="0" cellPadding="0" cellSpacing="0">
                    <tr>
                        <td valign="top">
                            <div className={styles.pagetitle}>Approved Gas Rates</div>
                            <div></div>
                        </td>
                    </tr>
                </table>
                <div>
                    <img alt="" src={ imgSpace } width="1" height="10" border="0" />
                </div>
                
                <table style={{width:"484px"}} cellPadding="0" cellSpacing="0" border="0">
                    <tr>
                        <td>
                            <div className={styles.grouptitle}>Massachusetts</div>                                
                            <div>
                                <img alt="" src={ imgSpace_cc } style={{width:"484px"}} height="3" vspace="10" />
                            </div>                               
                            <div>
                                <img alt="" src={ imgDownloadicon } width="7" height="9" />                                        
                                <a href={bostonratesPdf} target="_blank" rel="noreferrer" className={`${styles.elementtitle} ${styles.mainlink_a}`}>January 1, 2005</a>                                            
                            </div>                                   
                                                           
                            <div style={{paddingTop:"15px"}}></div>
                            <div>
                                <img alt="" src={ imgArrowBrown } style={{width:"3px" , height:"5px"}} /> 
                                <a href={bostonratesPdf} target="_blank" rel="noreferrer" className={styles.mainlink_a}>Click to view</a>                                        
                            </div>
                            <div style={{paddingTop:"28px"}}></div>
                            <div className={styles.grouptitle}>Energy North</div>
                            <div>
                                <img alt="" src={ imgSpace_cc } style={{width:"484px"}} height="3" vspace="10" />
                            </div>
                            
                            <div>
                                <img alt="" src={ imgDownloadicon } width="7" height="9" />                                    
                                <a href={energyNorthRates} className = {`${styles.elementtitle} ${styles.mainlink_a}`} target="_blank" rel="noreferrer">January 1 , 2005</a>                                        
                            </div>
                                
                            <div style={{paddingTop:"15px"}}></div>
                            <div>
                                <img alt="" src={ imgArrowBrown } width="3" height="5" />
                                <a href={energyNorthRates} target="_blank" rel="noreferrer" className={styles.mainlink_a}>Click to view</a>                                        
                            </div>
                            <div style={{paddingTop:"15px"}}></div>                            
                        </td>
                    </tr>
                </table>
            </div> 
            <div className={styles.column3}>
                
                <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                <tr>
                    <td><img alt="" src={ imgSpace } width="1" height="10" /></td>
                </tr>
                </table>
                <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                <tr>
                    <td style={{width:"8px"}}><img alt="" src={ imgSpace } style={{width:"8px"}} height="1" /></td>
                    <td style={{width:"176px"}}>
                        <span className={styles.modheader}>Contact KeySpan</span>
                    </td>
                    <td width="10"><img alt="" src={ imgSpace } width="10" height="1" /></td>
                </tr>
                </table>
                <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">    
                  
                        <tr>
                            <td style={{width:"18px", paddingTop:"10px"}} align="right" valign="top"><img alt="" src={imgArrowOrange} width="6" height="5" />&nbsp;</td>
                            <td style={{width:"166px", paddingTop:"10px"}} valign="top">
                                <a href="https://www.nationalgridus.com/NY-Home/Default.aspx" target="_blank" rel="noreferrer" className={`${styles.contextuallinkcontact} ${styles.contextuallinkcontact_a}`}>General Inquiry
                                </a>
                            </td>
                            <td width="10"><img alt="" src={ imgSpace } width="10" height="1" /></td>
                        </tr>
                    </table>
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                        <tr>
                            <td><img alt="" src={ imgSpace } width="1" height="10" /></td>
                        </tr>
                    </table>
                    <div><img alt="" src={ imgHrModifier } style={{width:"194px"}} height="1"/></div>
                    
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="FAF5E9">
                        <tr>
                            <td><img alt="" src={ imgSpace } width="1" height="10" /></td>
                        </tr>
                    </table>
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                        <tr>
                            <td style={{width:"8px"}}>
                                <img alt="" src={ imgSpace } style={{width:"8px"}} height="1" /></td>
                            <td style={{width:"176px"}}>
                                <span className={styles.modheader}>Helpful Resources</span>
                            </td>
                            <td width="10"><img alt="" src={ imgSpace } width="10" height="1" /></td>
                        </tr>
                    </table>                            
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                        <tr>
                            <td style={{width:"18px", paddingTop:"10px"}} align="right" valign="top">
                                <img alt="" src={ imgArrowOrange} width="6" height="5" />&nbsp;</td>
                            <td style={{width:"166px", paddingTop:"10px"}} valign="top">
                                <a href='https://www.nationalgridus.com/NY-Home/Default.aspx' className ={`${styles.contextuallink} ${styles.contextuallinkcontact_a}`} target="_blank" rel="noreferrer">Economic Development Program for Commercial Customers</a></td>
                            <td width="10">
                                <img alt="" src={ imgSpace } width="10" height="1" /></td>
                        </tr>
                        <tr>
                            <td colSpan="3"><span style={{paddingTop:"5px"}}></span></td>
                        </tr>
                    </table>
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="#FAF5E9">
                        <tr>
                            <td style={{width:"18px"}} align="right" valign="top">
                                <img alt="" src={ imgArrowOrange} width="6" height="5" />&nbsp;</td>
                            <td style={{width:"166px"}} valign="top">
                                <a href='https://www.nationalgridus.com/NY-Home/Default.aspx' className = {`${styles.contextuallink} ${styles.contextuallinkcontact_a}`}  target="_blank" rel="noreferrer">Learn More about Energy Products and Services for Your Business</a></td>
                            <td width="10">
                                <img alt="" src={ imgSpace } width="10" height="1" /></td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <img alt="" src={ imgSpace } width="1" height="8" /></td>
                        </tr>
                    </table>
                    <table style={{width:"194px"}} cellPadding="0" cellSpacing="0" border="0" bgcolor="FAF5E9">
                        <tr>
                            <td><img alt="" src={ imgSpace } width="1" height="10" /></td>
                        </tr>
                    </table>
                    <div>
                        <img alt="" src={ imgHrModifier } style={{width:"194px"}} height="1" border="0"/>
                    </div>
                </div>
            </div>
    </>);
}

export default ApprovedRatesMaKedma;