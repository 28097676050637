
import React from 'react';
import Helmet from 'react-helmet';
import img_Contact1 from '../../../../assets/images/Contact1.jpg';
import img_Email from '../../../../assets/images/Email.jpg';
import Img_download_button from '../../../../assets/images/download_button.jpg';
import Img_bullet_triangle from '../../../../assets/images/bullet_triangle.gif';

function CustoDial() {
    return (
        <div>
            <Helmet>
                <title>National Grid</title>
            </Helmet>
            <div style={{ paddingLeft: '20px', fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                <div className='row'>
                    <div className='col-10'>
                        <p style={{ margin: '1em' }}>
                            <span className="sectionhead">Wireless</span>
                            <br />
                            To view the various National Grid Wireless Agreements, select from the list below.
                        </p>

                    </div>
                </div >
                <div className='row' style={{ paddingLeft: '20px', paddingRight: '10px' }}>
                    <div className='col-11' style={{ paddingLeft: '22px' }} >

                        <div className='row'>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#003366'}}>Distribution Structures Wireless Agreement - Exhibit 1</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%', paddingRight:'50px' }}>
                                <a href="../../../pdf/distr_pole_wireless_agreement.pdf"> <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                            <div>
                                <hr style={{ opacity: '0.9', marginLeft: '10px', marginTop: '5px', marginBottom: '5px' }} />
                            </div>
                        </div>

                        <div className='row '>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#003366' }}>Distribution Structures Wireless Agreement - Exhibits 2-5 </b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%', paddingRight: '50px' }}>
                                <a href="../../../pdf/Distr_Pole_Wireless_Exh_2-5.pdf"> <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                            <div>
                                <hr style={{ opacity: '0.9', marginLeft: '10px', marginTop: '5px', marginBottom: '5px', marginRight:'0', height: '1px', background: '#333333' }} />
                            </div>
                        </div>

                        <div className='row '>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#003366' }}>Transmission Structures Wireless Agreement</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%', paddingRight: '50px' }}>
                                <a href="../../../pdf/wireless_right_of_occupancy_agreement.pdf"> <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                            <div>
                                <hr style={{ opacity: '0.9', marginLeft: '10px', marginTop: '5px', marginBottom: '5px', marginRight: '0'}} />
                            </div>
                        </div>

                        <div className='row '>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#003366' }}>Transmission Structure Wireless Occupancy Process </b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%', paddingRight: '50px' }}>
                                <a href="../../../pdf/Transm_WirelessProcess.pdf"> <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                            <div>
                                <hr style={{ opacity: '0.9', marginLeft: '10px', marginTop: '5px', marginBottom: '5px', marginRight: '0', height: '1.5px', background: '#333333' }} />
                            </div>
                        </div>

                        <div className='row '>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#003366' }}>Wireless Services Provider Owned Communications Towers Constructed on National Grid Property Agreement</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%', paddingRight: '50px' }}>
                                <a href="../../../pdf/tower_wireless_agreement.pdf"> <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                            <div>
                                <hr style={{ opacity: '0.9', marginLeft: '10px', marginTop: '5px', marginBottom: '5px', marginRight: '0'}} />
                            </div>
                        </div>

                        <div className='row '>
                            <strong>For further information on wireless please contact:</strong>
                            <div style={{ paddingLeft: '10px' }} >
                                <div>
                                    <img src={img_Contact1} name="mike" width="200" height="80" border="0" alt="contact" />
                                </div>
                                <a href="mailto:ThirdParty@nationalgrid.com">
                                    <img src={img_Email} width="300" height="20" border="0" alt="Mail" />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustoDial;