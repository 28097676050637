import React from 'react';
import styles from  '../../../styles/ngww2.module.css';

function AllowanceMaKedma() {
    return (<>
              <div className={`${styles.column2} ${styles.stepsbg}`} style={{marginTop: "35px"}}>
				<div className={styles.sectionwidth_min}>				
				<table border="0" cellpadding="0" cellspacing="0" style={{width:"480px"}}>
					<tr>
						<td style={{width:"480px"}}>
							<div className={styles.pagetitle}>Company Gas Allowance</div>
						</td>
					</tr>
				</table>							
				
                <table style={{width:"520px"}} border="0" cellspacing="0" cellpadding="0">
                    <tr> 
                        <td style={{width:"520px"}} valign="top"> 	                
                            <img alt="" src="../assets/images/c.gif" width="1" height="25" border="0" />
                        </td>
                    </tr>
                </table>					
                <table style={{width:"520px"}} border="0" cellspacing="0" cellpadding="0">
                    <tr> 
                        <td style={{width:"520px"}} valign="top"> 	
                        <p className={styles.typetitle}>Effective November 1, 2023, the Company Gas Allowances is: <br /></p>
                        <br />
                        <p className={styles.typetitle}>
                            <table border="0" align="CENTER" valign="TOP" style={{fontSize:"12px", width:"300px"}}>
                                <tr><td style={{width:"200px"}}> </td><td>3.7%</td></tr>	
                            </table>
                        </p>
                        </td>
                    </tr>
                </table>
                <table style={{width:"520px"}} border="0" cellspacing="0" cellpadding="0">
                    <tr> 
                        <td style={{width:"520px"}} valign="top"> 			
                            <img alt="" src="../assets/images/c.gif" width="1" height="25" border="0" />
                        </td>
                    </tr>
                </table>	
			
            </div>
        </div>
       
    </>);
}

export default AllowanceMaKedma;