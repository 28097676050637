import React from "react";
import "../../styles/transmission.css"
import onislandImg from '../../assets/images/transmission/NGRID_OnIsland_Logo.jpg';
function AquidneckReliability() {
    return (
    <div className="container">
    <div className="row actualContent_Transmission">
         {/* <!--- right side ---> */}
    <div class="col-md-1 leftSide"></div>
    <div className="col-md-6 innerContent">
        <span class="pagehead">Aquidneck Island Reliability Project – “OnIsland”</span>
        <br />
        <img id="on_island" src={onislandImg} height="86px" width="190px" border="0" alt="OnIsland"></img>
        <br /><br />
        <p>Through its Aquidneck Island Reliability Project, better known as “OnIsland,” 
            National Grid is investing an estimated $93 million to improve the electrical infrastructure on Aquidneck Island,
            Rhode Island. This project will minimize disruptions in service delivery and better serve the Island in the future. 
            The work, which spans the Island’s three communities of Newport, Middletown and Portsmouth, 
            began in the summer of 2015 and will conclude in the spring of 2020, 
            pending required project approvals and permitting. The need for the project is driven by 
            the combination of an aging electrical system infrastructure and the increasingly wired lives 
            we all lead. Electricity usage per person has more than doubled since the 1970s. 
            The project calls for the construction of two new substations,
            a rebuild of an existing substation and the retirement of five substations. 
            National Grid will also rebuild and convert two transmission lines from 69 kilovolts 
            to 115 kilovolts and make improvements to the distribution system.  
            Upgrading the grid to handle the evolving demand for power is a necessity, 
            especially in a densely populated area such as Aquidneck Island. Simply put,
            the Island’s power needs are increasing on an old system that is at its maximum capacity.</p>
            <br /><br />
    </div>
    
    {/* <!--- right side ---> */}
    <div class="col-md-3 rightSide">
        <span class="subHeadingText">Related Information</span>
        <div class="rightside_content_tran">
        <p>
        <b>Rhode Island Public Utilities Commission Docket Number: </b><a href="http://www.ripuc.ri.gov/efsb/2016_SB_1.html" target="_blank" rel="noreferrer">SB-2016-01</a></p><br />
        <p><b>Website:</b> <a href="http://www.onislandngrid.com/" target="_blank" rel="noreferrer" >www.onislandngrid.com/</a></p><br />
        <p><b>Stakeholder Relations Lead: </b> Erin Gryniak</p><br />
        <p><b>Project Email:</b> <a href="mailto: onisland@nationalgrid.com">onisland@nationalgrid.com</a></p><br /> 
        <p><b>Project Hotline:</b><br />	401-400-5862 or<br /> 800-568-4558</p><br />
        <p><b>Project Twitter Feed:</b> @OnIslandNG</p><br />
        </div>
    </div>
    <br />
    </div>
    </div>
   );
   };

export default AquidneckReliability
