import React from 'react';
import catv_line from '../../../../assets/images/catv_line.jpg';
import styles from "../../../../styles/catv.module.css"


function Clec(){
    return(
        <diV>
            <tr>
          <td width="20" align="left" valign="top"></td>
         <td className={styles.sectionhead2} align="left" valign="top">
         <span className={styles.line}><b>CLEC</b></span>
       <br></br>
         <p> Select asset to be used from the list below.</p>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
        
          <tbody><tr>
            <td width="20">&nbsp;</td>
            <td width="13" valign="top">
            <img alt="" id="catv_img" src="/images/catv/shared_bullet_diamond.gif" width="9px" height="12px" style={{verticalAlign:"top"}} />
            </td>
            <td width="130" valign="top">
            <a href="https://www9.nationalgridus.com/niagaramohawk/attachments/clec/pole.asp" target="window">
               <b>Distribution Pole</b></a> </td>
            <td width="13" valign="top">—</td>
            
            <td className={styles.content}> National Grid owned electric distribution poles, the record and financial accounting being booked to FERC account 364.</td>
            
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td>
            <img alt="" id="catv_line" src={catv_line} width="100%" height="0.5px" style={{verticalAlign:"top"}} />
            </td>
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">
            <img alt="" id="catv_img" src="/images/catv/shared_bullet_diamond.gif"  width="9px" height="12px" style={{verticalAlign:"top"}}/>
            </td>
            <td valign="top">
              <a id="link" className={styles.hyper} href="pole.asp"> </a><a href="https://www9.nationalgridus.com/niagaramohawk/attachments/clec/tower.asp" target="window"><b>Transmission Pole</b></a> </td>
            <td valign="top">—</td>
            <td className={styles.content}>National Grid designated wood poles supporting electric transmission circuits and recorded in National Grid transmission system plant accounting records (FERC Account No. 355).</td>
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td>
            <img alt="" id="catv_line" src={catv_line} width="100%" height="0.5px" style={{verticalAlign:"top"}} />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td valign="top">
            <img alt="" id="catv_img" src="/images/catv/shared_bullet_diamond.gif"  width="9px" height="12px" style={{verticalAlign:"top"}} /></td>
            <td valign="top"><a href="conduit.asp"> </a><a href="https://www9.nationalgridus.com/niagaramohawk/attachments/clec/conduit.asp" target="window"><b>Conduit Occupancy</b> </a></td>
            <td valign="top"> —</td>
            <td className={styles.content}>National Grid's underground electrical network is primarily located in Albany, Syracuse and Buffalo (and a few smaller municipalities). To the extent conduit is available, National Grid's underground network may provide a pathway to reach urban customers. </td>
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td>
            <img alt="" id="catv_line" src={catv_line} width="100%" height="0.5px" style={{verticalAlign:"top"}} />
            </td>
          </tr>
          <tr> 
            <td>&nbsp;</td>
            
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td></td>
          </tr>
          <tr> 
            <td>&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td valign="top">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
        <p></p>
        <p>&nbsp; </p>
        <p>&nbsp;</p></td>
      <td width="100" align="left" valign="top">&nbsp;</td>
    </tr>
            
            
            </diV>


            
            

        

    );
}

export default Clec;

