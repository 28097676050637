
import React from 'react';
import Helmet from 'react-helmet';
import Img_Email from '../../../../assets/images/Email.jpg';
import Img_Contact from '../../../../assets/images/Contact.jpg';
import Img_bullet_triangle from '../../../../assets/images/bullet_triangle.gif';

function CustoDial() {
    return (
        <div>
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div style={{ paddingLeft: '20px', fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                <div className='row'>
                    <div style={{ width: '95%' }}>
                        <div style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                            <span className="Catv_PageHead"><b>Private Individual</b></span>
                            <br />
                            Please contact the individual listed below for your desired type of attachment.
                        </div>
                    </div>
                </div>

                <div style={{ paddingLeft: '20px' }} >
                    <div className='row'>
                        <div>
                            <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top', marginTop: '2px' }} />
                            <b style={{ color: '#003366' }}>Distribution Pole</b>
                        </div>
                        <p style={{ paddingLeft: '20px' }}>
                            <div>
                                <img src={Img_Contact} name="mike" width="250" height="130" border="0" alt="contact" />
                            </div>
                            <a href="mailto:ThirdParty@nationalgrid.com">
                                <img src={Img_Email} name="mike" width="300" height="20" border="0" alt="Mail" />
                            </a>
                            <hr style={{ opacity: '0.9', margin: '0px',width:'90%'}} />
                        </p>
                    </div>
                </div>

                <div style={{ paddingLeft: '20px' }} >
                    <div className='row'>
                        <div>
                            <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top', marginTop: '2px' }} />
                            <b style={{ color: '#003366' }}>Transmission Pole</b>
                        </div>
                        <p style={{ paddingLeft: '20px' }}>
                            <div>
                                <img src={Img_Contact} name="mike" width="250" height="130" border="0" alt="contact" />
                            </div>
                            <a href="mailto:ThirdParty@nationalgrid.com">
                                <img src={Img_Email} name="mike" width="300" height="20" border="0" alt="Mail" />
                            </a>
                            <hr style={{ height: '1px', margin: '0px', opacity: '0.9', background:'#333333',  width: '90%' }} />
                        </p>
                    </div>
                </div>


                <div style={{ paddingLeft: '20px' }} >
                    <div className='row'>
                        <div>
                            <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top', marginTop: '2px' }} />
                            <b style={{ color: '#003366' }}>Conduit Occupancy</b>
                        </div>
                        <p style={{ paddingLeft: '20px' }}>
                            <div>
                                <img src={Img_Contact} name="mike" width="250" height="130" border="0" alt="contact" />
                            </div>
                            <a href="mailto:ThirdParty@nationalgrid.com">
                                <img src={Img_Email} name="mike" width="300" height="20" border="0" alt="Mail" />
                            </a>
                            <hr style={{ opacity: '0.9', margin: '0px', width: '90%' }} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustoDial;