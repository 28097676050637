import React, { useEffect, useState } from "react";

function ServiveTerrMap() {
  const [imageSize] = useState({ width: 707, height: 488 });
  const [countyCoordinates, setCountyCoordinates] = useState({
    Cattaraugus: "79,438,78,358,85,358,88,366,98,373,104,370,109,369,115,370,117,368,123,367,125,365,132,361,147,360,157,360,162,361,161,438",
    Chautauqua: "4,393,5,439,78,438,78,359,75,359,74,356,72,351,68,350,65,351,48,361,47,368,40,374,35,374,29,377,25,381,20,384,13,385,12,388,7,391",
    Niagara: "152,235,134,242,126,239,121,242,114,242,110,244,105,249,100,249,92,250,87,251,84,252,85,258,84,259,83,263,83,270,84,274,87,276,88,281,95,281,102,286,102,280,108,284,109,287,114,283,117,281,123,280,131,280,137,280,138,278,142,281,147,283,149,284",
    Genesee: "148,276,146,313,187,313,189,310,200,310,203,307,204,301,205,298,205,293,205,292,203,291,200,290,205,276",
    Erie:"85,280,86,288,90,291,92,297,94,300,96,306,98,312,100,317,100,322,96,324,93,329,87,332,86,332,83,332,77,341,73,349,77,355,79,357,85,357,88,360,88,367,97,370,102,374,106,369,112,369,115,369,117,367,122,367,127,365,131,364,135,363,145,360,147,359,146,326,143,324,143,312,145,311,147,281,144,282,137,281,133,282,128,281,126,280,117,280,112,283,110,286,106,286,102,282,97,285,96,283,89,282",
    Orleans:"153,275,201,275,197,237,185,237,173,237,167,237,161,232,153,236,151,238,149,251,149,270,148,275",
    Wyoming:"142,325,147,327,146,364,164,365,165,362,190,364,192,356,195,351,200,347,201,329,200,311,188,310,186,315,145,313,143,313,143,325",
    Alleghany:"163,440,223,439,223,369,214,369,210,364,208,362,165,362,164,364,162,366",
    
    Albany: "601,383,649,374,648,365,650,362,650,356,650,352,650,349,655,346,651,344,657,329,657,325,655,319,649,327,642,329,606,337,606,346,601,355,599,364,600,374,601,382",
    Cayuga: "339,242,330,245,334,265,336,286,330,289,330,295,330,303,333,312,335,320,333,326,330,332,333,341,336,349,340,353,354,353,365,354,374,355,383,356,382,345,379,332,373,323,362,322,360,298,357,297,360,269,357,262,346,263,345,249,345,237,343,234,339,239,334,244",
    Chenango: "423,336,471,332,468,334,485,332,484,341,484,350,484,356,479,363,478,370,479,376,475,386,477,392,475,393,476,400,473,409,473,411,451,411,449,403,428,402,426,380",
    Clinton: "625,84,637,84,644,84,647,79,652,78,654,73,662,72,662,69,666,65,672,65,674,64,672,55,672,50,673,47,672,44,672,40,672,35,675,21,672,16,673,10,675,5,678,1,605,1,601,2,605,12,606,19,606,23,605,27,606,34,611,36,615,40,614,45,614,49,614,53,615,59,619,82",
    Columbia: "673,438,677,426,680,426,680,387,689,371,689,364,689,360,647,369,646,375,645,379,647,387,646,397,641,401,640,405,638,412,634,418,633,423,636,428,639,425,647,430,655,435,664,436",
    Cortland:"420,331,423,346,423,362,426,380,423,383,383,383,383,363,383,357,383,343,383,333",
    Essex: "676,67,669,68,665,72,664,74,656,78,655,80,649,82,647,87,624,85,595,92,600,130,583,133,586,149,577,157,593,175,601,173,605,177,612,179,608,181,608,187,676,175",
    Franklin: "603,1,534,1,534,11,539,11,540,12,557,123,554,127,552,128,556,136,599,129,595,92,602,90,617,87,621,85,617,63,616,63,612,38,607,36,606,29,607,19",
    Fulton: "549,258,558,262,563,264,584,262,589,264,590,265,598,265,599,259,606,258,610,296,569,297,543,292,541,284,541,280,545,280,545,275,545,274",
    Hamilton: "531,142,587,132,588,149,578,157,595,178,599,172,603,178,607,178,611,180,609,182,609,188,595,189,604,258,597,259,596,265,589,265,589,263,563,264,532,247,538,231,525,143",
    Herkimer: "531,247,538,230,528,143,504,147,494,141,503,206,508,251,502,254,500,257,500,262,502,262,505,261,509,264,509,266,501,280,500,287,493,290,495,313,494,316,497,317,502,317,507,310,527,320,530,313,540,316,543,295,540,285,540,279,541,277,546,275,543,271,548,268,548,258,532,249",
    Jefferson:"417,95,465,121,452,137,455,140,458,141,457,145,461,146,457,148,453,148,453,157,445,157,433,170,420,169,422,184,433,186,433,198,405,196,404,202,389,198,389,196,387,196,387,190,386,182,382,176,380,170,376,166,373,162,379,165,383,168,386,169,388,165,394,163,397,160,397,157,394,156,388,156,388,152,396,145,395,136,389,138,382,139,379,145,375,141,373,136,376,128,385,118,396,113,404,109,412,105,415,99",
    Lewis: "494,141,466,123,453,138,458,141,456,143,461,148,456,149,452,150,452,157,445,158,434,171,424,171,422,185,432,186,434,230,452,237,502,206",
    Livingston:"215,371,223,371,225,364,232,361,233,356,251,356,249,344,240,343,238,331,240,330,240,316,242,316,244,311,240,304,225,304,224,296,207,297,205,298,205,301,203,301,203,307,201,309,201,344,199,348,194,354,193,358,191,363,210,364",
    Madison: "413,268,426,273,428,271,435,271,437,271,439,271,449,278,449,283,452,286,453,291,459,288,463,303,473,304,472,311,493,310,493,315,490,317,492,323,488,327,488,330,475,333,476,330,425,334,419,282,415,281",
    Monroe:"200,241,201,274,207,274,203,287,207,290,206,295,225,296,225,302,253,302,253,290,265,290,263,251,259,253,254,253,249,257,242,254,236,250,229,244,221,244,205,240,200,238",
    Montgomery:"543,315,544,297,542,290,554,295,566,297,611,296,612,308,593,323,593,325,575,328,561,321,554,321",
    Oneida: "433,227,437,228,457,235,503,205,509,248,501,250,502,253,500,257,506,262,508,259,511,262,502,276,502,282,493,286,499,312,493,312,492,313,474,314,473,308,473,305,464,306,461,293,454,293,451,285,450,280,440,272,434,272,428,275,424,275,424,273,426,249,424,248",
    Onondaga: "386,268,388,265,388,259,402,264,409,273,414,277,413,286,418,287,423,330,383,333,373,322,361,322,364,297,359,297,361,265,379,265",
    Ontario:"253,356,251,342,241,342,239,331,241,329,241,316,244,316,243,308,241,305,252,302,254,291,268,291,277,292,278,290,288,289,289,292,304,292,309,293,309,305,305,317,309,329,270,331,268,336,265,342,265,346,264,357",
    Oswego:"346,235,348,229,353,227,357,225,366,219,373,218,378,218,383,216,387,213,392,199,406,202,407,197,434,199,435,229,431,229,421,252,423,253,423,262,422,271,420,276,415,275,408,271,404,266,398,263,388,259,388,263,387,268,385,268,380,266,380,263,375,263,363,264,362,262,347,263",
    Otsego: "483,398,490,395,494,389,498,389,503,388,505,383,510,381,513,381,518,376,521,377,525,372,533,371,533,369,537,368,543,367,550,368,557,348,557,345,556,345,555,340,555,337,555,330,553,327,555,320,532,314,527,322,511,312,508,310,504,314,504,316,497,318,494,316,494,315,492,317,489,323,492,326,486,334,486,339,486,343,486,346,483,357,485,363,482,366,481,369,479,374,481,379,481,385,477,388,477,392,478,395",
    Rensselaer: "650,369,691,363,704,330,695,309,695,297,686,297,682,297,679,299,666,300,661,300,658,303,657,309,659,314,658,321,657,328,652,338,656,343,652,346,652,354,649,359,649,364",
    Saratoga: "603,242,629,236,638,249,636,256,637,259,638,259,641,258,642,265,645,261,649,260,650,252,654,251,655,251,656,249,658,249,664,250,661,251,662,255,661,257,665,260,662,264,665,265,664,268,664,272,667,278,666,282,665,289,664,298,657,300,657,301,656,307,657,311,658,316,658,323,655,317,649,323,647,324,642,320,641,318,638,316,635,311,633,301,629,307,617,300,610,297",
    Schoharie:"551,368,557,378,565,383,577,388,584,389,587,386,590,388,597,385,601,384,600,381,601,368,601,363,600,357,602,352,605,347,605,340,603,335,600,335,594,335,590,332,595,327,595,322,586,326,574,327,564,322,561,322,556,322,554,326,554,338,553,343,556,347,554,355",
    Schenectdady: "606,335,601,335,594,335,594,331,597,328,597,323,614,310,612,298,627,309,632,305,637,315,637,316,641,319,645,324,646,326",
    StLawrence: "419,95,504,145,556,137,555,127,557,125,544,14,537,12,536,2,528,1,517,7,513,10,510,10,489,25,473,37,458,49,446,65,441,65,439,70,435,77,432,86,426,91",
    Warren: "595,191,601,190,674,177,671,181,669,181,672,183,669,193,671,199,668,200,662,205,658,211,657,212,658,216,664,250,656,250,655,252,654,252,650,251,648,255,643,259,640,258,639,257,635,255,639,251,635,245,632,241,630,236,602,241",
    Washington:"676,173,676,173,676,178,671,177,669,179,671,183,671,188,670,193,670,196,667,201,664,205,660,211,659,216,659,224,661,235,661,244,660,248,660,257,664,265,667,277,665,282,664,296,659,299,678,297,681,296,691,296,692,296,696,281,695,254,697,229,695,214,691,205,690,204,688,206,685,211,680,209,679,206,678,202,676,196,679,184"
  });

  useEffect(() => {
    const updateDimensions = () => {
      try{
      const image = document.getElementById("ny");
      if (!image) {
        throw new Error("Image element not found");
      }
      const scaleFactor = image.width / imageSize.width;
  
      const updatedCoordinates = Object.fromEntries(
        Object.entries(countyCoordinates).map(([county, coords]) => [
          county,
          coords
            .split(",")
            .map((coord) => Math.round(coord * scaleFactor))
            .join(","),
        ])
      );
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setCountyCoordinates(updatedCoordinates);
    } catch (error) {
      console.error("Error updating dimensions:", error);
    }
    };
  
    updateDimensions();
  
    window.addEventListener("resize", updateDimensions);
  
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
     // eslint-disable-next-line
  }, [imageSize.width]);
  

  return (
    <div className="">
      <div className="mt-5"></div>
      <table border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td align="center" valign="top">
              <img
                src="/images/serviceterr_map/ny_map_f.gif"
                className="img-fluid mx-auto d-block"
                alt="ny"
                name="ny"
                width={imageSize.width}
                height={imageSize.height}
                border="0"
                useMap="#mapny"
                id="ny"
              />
              <map name="mapny">
                {Object.entries(countyCoordinates).map(([county, coords], index) => (
                  <area key={index} shape="poly" coords={coords} href={`serviceterr_map_a?county=${county}`} alt="" />
                ))}
              </map>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ServiveTerrMap;
