import React, { useState,useRef  } from "react";
// import '../../styles/ng.css';
import '../../styles/GasLeakMap.css';
import myImage from '../../assets/images/NY_StateNew.jpg';
import GasLeakButton1_Img from '../../assets/images/Gasleakbutton1.jpg';
import GasLeakButton2_Img from '../../assets/images/Gasleakbutton2.jpg';
import xml2js from 'xml2js';
import { Helmet } from 'react-helmet';

function GasLeakMapLI() {
  const [iframeSrc, setIframeSrc] = useState(null);
  const [zipCodeValue, setZipCodeValue] = useState('');
  const [customError, setError] = useState(null);
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    // When the iframe content loads, scroll to it
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validateZipCode = (zip) => {
    return /^\d{5}$/.test(zip);
  };

  const handleInputChange = (event) => {
    setZipCodeValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick(); // Trigger the "Find" button click
    }
  };
  
  const displayError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError('');
  };

  const loadAndParseXML = (zipCode) => {
    const cacheKey = 'cachedXmlData';
    const cacheTimestampKey = 'cachedXmlDataTimestamp';
  
    // Check if cached data exists and if it's not older than 30 minutes
    const cachedXmlData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
  
    if (cachedXmlData && cachedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(cachedTimestamp) < 30 * 60 * 1000) {
        // Use the cached data and return the result of parseXmlData
        return parseXmlData(cachedXmlData, zipCode);
      }
    }
  
    // If no valid cached data, fetch and parse the XML data
    return fetch('./xml/ApplicationMessage.xml')
      .then((response) => response.text())
      .then((xmlData) => {
        // Cache the parsed data and timestamp
        localStorage.setItem(cacheKey, xmlData);
        localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
        return parseXmlData(xmlData, zipCode); // Return the result of parseXmlData
      })
      .catch((error) => {
        console.error('Error fetching XML data:', error);
        return false; // Return false in case of an error
      });
  };
  

  // Parse and store the XML data
  const parseXmlData = (xmlData, zipCode) => {
    return new Promise((resolve, reject) => {
      xml2js.parseString(xmlData, (err, result) => {
        if (err) {
          console.error('Error parsing main XML:', err);
          reject(err); // Reject the Promise if there's an error
        }
  
        const safetyAndOutageTag = 'SafetyandOutage';
        const gasLeakMapTag = 'GasLeakMap';
        const zipCodeTag = 'ZipCodeLI';
  
        if (
          result &&
          result.Errors &&
          result.Errors[safetyAndOutageTag] &&
          result.Errors[safetyAndOutageTag][0] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0] &&
          result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0][zipCodeTag]
        ) {
          const zipCodes = result.Errors[safetyAndOutageTag][0][gasLeakMapTag][0][zipCodeTag][0];
          const zipCodeArray = zipCodes.split(',').map((zipcode) => zipcode.trim());
          resolve(zipCodeArray.includes(zipCode)); // Resolve the Promise with the result
        } else {
          resolve(false); // Resolve with false if the data is not as expected
        }
      });
    });
  };

  // Function to load and parse the ExternalLinks.xml file
  const loadAndParseExternalLinksXML = (zipCode) => {
    const cacheKey = 'cachedExternalLinksXmlData';
    const cacheTimestampKey = 'cachedExternalLinksXmlDataTimestamp';
    // Check if cached data exists and if it's not older than 30 minutes
    const cachedXmlData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
    if (cachedXmlData && cachedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(cachedTimestamp) < 30 * 60 * 1000) {
        // use the cached data
        parseExternalLinksXmlData(cachedXmlData,zipCode);
        return;
      }
    }
    // If no valid cached data, fetch and parse the XML data
    fetch('./xml/LinksToExternalURL.xml')
      .then((response) => response.text())
      .then((xmlData) => {
        // Parse and store the XML data
        parseExternalLinksXmlData(xmlData,zipCode);
        // Cache the parsed data and timestamp
        localStorage.setItem(cacheKey, xmlData);
        localStorage.setItem(cacheTimestampKey, new Date().getTime().toString());
      })
      .catch((error) => {
        console.error('Error fetching ExternalLinks XML data:', error);
      });
  };

  const parseExternalLinksXmlData = (xmlData,zipCode) => {
    xml2js.parseString(xmlData, (err, result) => {
      if (err) {
        console.error('Error parsing ExternalLinks XML:', err);
        return;
      }
      const commonTag = 'Common';
      const gasLeakMapLITag = 'GasLeakMapLI';
      if (result && result.ExternalLinks && result.ExternalLinks[commonTag]) {
        const gasLeakMapLIData = result.ExternalLinks[commonTag][0][gasLeakMapLITag][0];
        setIframeSrc(gasLeakMapLIData + zipCode);
      }
    });
  };

  const handleButtonClick = () => {
    if (validateZipCode(zipCodeValue)) {
      clearError();
      loadAndParseXML(zipCodeValue)
        .then((result) => {
          if (result) {
            loadAndParseExternalLinksXML(zipCodeValue);
          } else {
            displayError("The zip code you entered is not part of National Grid’s gas service territory. Check with your gas service provider for more information.");
          }
        });
    } else {
      displayError("Please enter a valid 5-digit Zip Code.");
    }
  };

  return (
    <div className="row body">
      <Helmet>
        <title>Gas leak map</title>
      </Helmet>
      <div className="col">
      <div className="header" >
          <div className="whitespacer"></div>
          About this map
          <div className="whitespacer"></div>
          This map shows all gas leaks on streets that have been reported to National Grid.
          <img className="img-fluid mx-auto d-block" src={myImage} alt="Gas Service Territory" width="500px" height="300px" />
          Search by zip code to find all the leaks in your neighborhood.
          <div className="whitespacer"></div>
          Any time a leak is reported, National Grid will make the area safe right away.
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          <div className="floatleft"><img src={GasLeakButton1_Img} alt='' /></div>
          <div style={{ marginLeft: '40px', marginTop: '-25px' }}>
            Leak is inspected frequently to assure safety, and will be permanently repaired
            as quickly as possible. Also categorized in the gas industry as a Type 1 or Type 2 leak.
          </div>
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          <div className="floatleft">
            <img src={GasLeakButton2_Img} alt='' />
          </div>
          <div style={{ marginLeft: '40px', marginTop: '-25px' }}>
            Leak poses no public safety issue. Crews inspect these leaks to make sure the area
            stays safe. Also categorized in the gas industry as a Type 3 leak.
          </div>
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          We monitor and respond to gas leaks 24/7. 
          {/* View our <a href="http://www.coned.com/customercentral/gas-leak-infographic.jpg"
            target="_blank" style={{ fontSize: '14px' }}>infographic</a> to learn more. */}
          <div className="whitespacer"></div>
          If you smell gas, leave the area immediately and call 911 or 1-800-490-0045 in Long Island & The Rockaways
          <div className="whitespacer"></div>
        

        <div id="dvErrorSection" className={customError ? 'error' : 'hidden'}>
          <h4>There are errors or missing information in your form.</h4>
          <div className="noramBlacktext"></div>
          {customError && <span>{customError}</span>}
        </div>

        <div className="twoColumnContentArea section">
          <div className="rows row1">
            <div className="col1">
              <div className="whitespacer"></div>
            </div>
            <div className="col2" style={{ width: '360px' }}>
              <div className="description">
                <div className="floatLeft">
                  <input type="text" className="textbox_gasleak" placeholder="Enter Zip Code" id="zipCode" name="zipCode" value={zipCodeValue} onChange={handleInputChange} onKeyPress={handleEnterKeyPress}/>
                </div>
                <div className="floatLeft paddingLeft10px" style={{ verticalAlign: 'middle' }}>
                  <button className='button_gasleak' onClick={handleButtonClick}>Find</button>
                </div>
                <div className="whitespacer"></div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          <div className="whitespacer"></div>
          Questions? See common <a href='./pdf/POA/Trading_Partner_Agreement-NiMO.pdf'
            target="./pdf/POA/Trading_Partner_Agreement-NiMO.pdf" rel="noopener noreferrer" style={{ fontSize: '14px' }}>FAQs</a> .
        </div>
        
        <div className="whitespacer"></div>
        
        <div ref={iframeRef}>
  {!iframeSrc || customError ? (
    <div className="whitespacer"></div>
  ) : (
    <div className="row">
      <iframe
        title="Map"
        src={iframeSrc}
        width="925px"
        height="720px"
        onLoad={handleIframeLoad}
      />
    </div>
  )}
</div>
      </div>
      </div>
      </div>
  );
}

export default GasLeakMapLI;
