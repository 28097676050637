import React from "react";
import "../../styles/transmission.css"
import IMG_Lasher from '../../assets/images/Lasher.jpg';
import Helmet from 'react-helmet';
function LasherSubstation() {
    return (
        <div>
            <div id="EmfBody">
                <div className='row'>
                    <div id="wrapper" style={{ maxWidth: '1010px' }}>
                        <Helmet bodyAttributes={{ style: 'background-color : #e5e5e5; font-family: Arial, Helvetica, sans-serif' }} >
                            <title>National Grid - Our Strategy</title>
                        </Helmet>
                        <div>&nbsp;</div>
                        <div id="leftnav" style={{ minWidth: '17%' }}>
                            <div id="links">
                                <span className="leftnav">
                                    <p>&nbsp;</p>
                                </span>
                            </div>
                        </div>

                        <div id="actual_content_env" className="clearfix">
                            <div className="innerContent" style={{ maxWidth: '570px' }}>
                                <span className="pagehead">Lasher Road Substation Project</span>
                                <p style={{ textAlign: 'center', paddingRight: '10%' }}>
                                    <img src={IMG_Lasher} alt="Laser" height="135px" width="40%" style={{ position: 'relative', minWidth: '230px' }}></img>
                                </p>
                                <p>
                                    The Lasher Road Substation Project will serve the growing Saratoga, New York, area by increasing the reliability of electrical service to National Grids residential customers. The Project calls for the construction of a new combined switching and 115/13.2kV distribution substation, with associated 115kV line connections, near the intersection of Lasher Road and Randall Road in the Town of Ballston, New York. Once the new station is constructed, National Grid will retire the existing Randall Road Station. The new, modern equipment will allow for rapid service restoration in case of an outage.The Project received its Certificate of Environmental Compatibility and Public Need Amendment from the New York State Public Service Commission on July 13, 2017. Construction of the substation is expected to commence in 2018.
                                </p>
                            </div>
                            <div className="right_side" style={{ maxWidth: '180px' }}>
                                <span className="subHeadingText">Related Information</span>
                                <div className="rightside_content">
                                    <p>
                                        <b>New York State Public Service Commission Case Number: </b><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterSeq=33427&MNO=10-T-0080" target="_blank" rel="noreferrer">10-T-0080</a>
                                    </p>
                                    <p>
                                        <b> Website: </b><a href="http://capitalregionreliability.com/lasher-road.htm" target="_blank" rel="noreferrer">www.capitalregionreliability.com/</a>
                                    </p>
                                    <p>
                                        <b>Project Email: </b><a href="mailto:info@capitalregionreliability.com" target="_blank" rel="noreferrer">info@capitalregionreliability.com</a>
                                    </p>
                                    <p>
                                        <b>Project Hotline:</b>
                                        <br /> 844-727-6648
                                    </p>
                                    <p>
                                        <b>Secretary to the Public Service Commission:</b>
                                        <br /> 518-474-653
                                    </p>
                                    <p>
                                        <b>New York State Department of Public Service, Environmental Certification and Compliance Section:</b>
                                        <br /> 518-474-8702
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LasherSubstation
