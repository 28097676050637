import React from 'react';
import  '../../styles/transmission.css';
import shared_line from '../../assets/images/shared_grn_line.gif';
import { useNavigate  } from "react-router-dom";

function Generator41()
{ 
    const navigate = useNavigate(); 
    function process_page()
    {
        navigate('/transmission/c4-1_newgenerator');
    }
      return(
        <>
        <p style ={{fontWeight : "bold"}}>Information for:</p>
            <p>NY Interconnection Projects  <br></br> 20MW or Less</p>
            <table className="table-responsive table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Interconnection Procedure</th>
                        <th scope="col">Interconnection Agreement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href = "http://www.nyiso.com/public/webdocs/documents/tariffs/oatt/att_z.pdf" >NYISO FERC Electric Tariff, Volume 1, Attachment Z</a> *(pdf)</td>
                        <td>Appendix 9</td>
                    </tr>
                </tbody>
                </table>
                <div>
                    <p>Note:</p>
                    <li>To interconnect generators in this size range, developers/owners must contact the <a href = "http://www.nyiso.org/">NYISO *.</a></li>
                    <li>An interconnection study, including a system impact analysis and a facility study, will be performed at the developer's/owner's cost.</li>
                </div>
                <div className = "row mt-4 mb-3">
                    <img alt="" style = {{width : "55%" , height : "2px"}} src = {shared_line}></img>
                    <button onClick = {process_page} style = {{maxWidth : "9%" , minWidth : "23%"  ,  marginTop : "-25px" , background: "#FFFFFF",  border: "1px solid #A9C7EC", color: "#006CB5",  fontweight: "bold"}}><small>back</small></button>
                </div>
       </>
    );
}

export default Generator41;