const Strategy_info = () => {
    return (
        <div  className="info">
            <p>
            <strong>New York State Public Service Commission Case Number: </strong>
        
<a href="https://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterSeq=42891"  target="window"> 13-T-0235 </a><br></br>
<br></br>
<strong>Website:</strong>
<a href="http://www.auburntransmissionproject.com/"  target="window"> www.auburntransmissionproject.com </a><br></br> 
<br></br>
<strong>National Grid Stakeholder Relations Lead:</strong> Susan Sayre<br></br>
<br></br>
<strong>Project Email:</strong><br></br>
<a href="mailto:info@auburntransmissionproject.com">info@auburntransmissionproject.com </a><br></br> 
<br></br>
<strong>NYSEG Project Hotline:</strong><br></br>
866-717-2281<br></br>
<br></br>
<strong>National Grid Project</strong><br></br>
<strong>Hotline:</strong><br></br>
866-706-5098


</p>
        </div >
    )
}
export default Strategy_info;