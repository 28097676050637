import React from "react";
import styles from "../../../styles/catv.module.css";

function YourBusiness() 
{
  return (
    <div className="">
      {/* <div className="mt-5"></div> */}
      <table border="0" cellSpacing="0" cellPadding="0">
      <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tr> 
      <td width="20" align="left" valign="top"><img alt="spacer" src="/images/catv/spacer.gif" width="20" height="20"></img></td>
      <td align="left" valign="top"> <p><span className={styles.sectionhead}><br />
          <b><font color="#00345B">Your Business</font></b></span><b><font color="#00345B"> — </font></b><font color="#00345B">Select from the list below to get started:</font></p>
        <table width="100%" border="0" cellspacing="0" cellpadding="2">
          <tr align="left" valign="top"> 
            <td width="25" align="right"><img alt="bullet_diamond" src="/images/catv/shared_bullet_diamond.gif" width="9" height="12"></img></td>
            <td width="65"><a className={styles.anchor} href="catv/index.asp"><b>CATV</b></a> </td>
            <td>Cable Television Network Providers. Registered with the NYS Public Service Commission (NYSPSC). CATV and CLECs typically do not own poles but lease space from other pole owners. We have a tariff filed rate filed with the NYSPSC for this type of Attacher.</td>
          </tr>
          <tr align="left" valign="top"> 
            <td height="10" align="right"></td>
            <td></td>
            <td><img alt="blk_dot" src="/images/catv/blk_dot.gif" width="100%" height="1"></img></td>
          </tr>
          <tr align="left" valign="top"> 
            <td align="right"><img alt="bullet_diamond" src="/images/catv/shared_bullet_diamond.gif" width="9" height="12" ></img></td>
            <td><a className={styles.anchor} href="clec/index.asp"><b>CLEC</b></a></td>
            <td>Competitive Local Exchange Carriers including providers of dark fiber. CATV and CLECs typically do not own poles but lease space from other pole owners. Registered with the NYSPSC under a Certificate of Public Convenience and Necessity. The current rate for CLEC attachments is the same as the tariff rate filed for CATV .</td>
          </tr>
          <tr align="left" valign="top"> 
            <td height="10" align="right"></td>
            <td></td>
            <td><img  alt="blk_dot" src="/images/catv/blk_dot.gif" width="100%" height="1"></img></td>
          </tr>
          <tr align="left" valign="top"> 
            <td align="right"><img alt="bullet_diamond" src="/images/catv/shared_bullet_diamond.gif" width="9" height="12"></img></td>
            <td><a className={styles.anchor} href="private/index.asp"><b>Private</b></a></td>
            <td>An individual, entrepreneur, partnership, corporation, educational organization, military organization, or governmental agency. National Grid selectively reviews and authorizes private attachments. We do not have a tariff rate filed for private attachments (attachers) as such attachments are not regulated by NYSPSC (e.g., this use is not addressed 
              by Pole Attachment Regulations).<br /></td>
          </tr>
          <tr align="left" valign="top"> 
            <td height="10" align="right"></td>
            <td></td>
            <td><img alt="" src="/images/catv/blk_dot.gif" width="100%" height="1"></img></td>
          </tr>
          <tr align="left" valign="top"> 
            <td align="right"><img alt="" src="/images/catv/shared_bullet_diamond.gif" width="9" height="12"></img></td>
            <td><a className={styles.anchor} href="wireless/index.asp"><b>Wireless</b></a></td>
            <td>Any antenna, hardware, equipment, apparatus, device and cables or wires connecting such hardware, equipment, apparatus and device placed on a structure, equipment cabinet shelter or cabinet for the purpose of wireless communications.<br /></td>
          </tr>
          <tr align="left" valign="top">
            <td height="10" align="right"></td>
            <td></td>
            <td><img alt="" src="/images/catv/blk_dot.gif" width="100%" height="1"></img></td>
          </tr>
          <tr align="left" valign="top"> 
            <td align="right"></td>
            <td colspan="2"></td>
          </tr>
       </table>
</td>
       <td width="150" align="left" valign="top"></td> 
    </tr>
 </table>
      </table>
    </div>
  );
}


export default YourBusiness;
