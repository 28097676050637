import React from 'react'
import styles from '../../../../styles/catv.module.css'
import download from "../../../../assets/images/download.gif"
import bullet from "../../../../assets/images/bullet_square.gif"
import viewtax from "../non_html/Custodial Assignment List - Alpha Sort(rev.11_20_07).xls"
import viewdsttax from "../non_html/Custodial List - Taxdistr Sort(rev.11_20_07).xls"


function Custodial()
{
    return(
        <div className = "container mt-2">
            <div className = "row">
                <span className={styles.sectionhead}>CATV - Custodial Areas with Verizon</span>
            </div> 
            <div className = "row mt-3">
               <small><span> Within the joint use pole agreement between National Grid and Verizon NY, Inc., custodial areas have been established in reference to billing of Third Party pole attachments on jointly owned poles. Custodial Areas are assigned by tax district (municipality) within the common NMPC-Verizon service areas. Custodial assignments do not exist with any other 
                of National Grid’s joint pole owners. Notwithstanding custodial assignment, application for attachment must be submitted to each pole owner (National Grid and Verizon) and each pole owner grants a license for the attachment. </span></small>         
            </div>
            <div className = "row mt-2">
                <span className={styles.sectionhead}>Verizon / National Grid Custodial Assignments</span>
            </div>
            <div className = "row mt-3">
               <small><span>NG = National Grid revised 11/20/07</span></small>         
            </div>
                <div className = "row">
                    <div className = "col-9 col-lg-3 col-md-5">
                        <small> <img src={bullet} alt = "" style = {{marginBottom : "1%" , marginLeft : "2%" , fontWeight : "bold"}}/> <b>View Alphabetized List Sort</b></small>
                    </div>
                    <div className = "col-1 col-lg-1 col-md-1">    
                        <a href={viewtax}><img alt = "" style = {{marginLeft : "9%"}} src={download}/></a>
                    </div>
                </div>
                <div className = "row">
                    <div className = "col-9 col-lg-3 col-md-5">
                        <small> <img src={bullet} alt = "" style = {{marginBottom : "1%" , marginLeft : "2%" , fontWeight : "bold"}}/> <b>View Tax District List Sort</b></small>
                    </div> 
                <div className = "col-1 col-lg-1 col-md-1">      
                        <a href={viewdsttax}><img alt = "" style = {{marginLeft : "10.1%"}} src={download}/></a>
                    </div>
                </div>    
        </div>        
    );
}

export default Custodial;