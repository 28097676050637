import React from "react";
import { useLocation } from 'react-router-dom';
import '../../../styles/Niagramohawk.css';

function ServiceTerritoryMap() {
  try {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const county = queryParams.get('county');
  let imageUrl;


  var areaNames = ['Albany','Alleghany', 'Cattaraugus', 'Cayuga', 'Chautauqua', 'Chenango', 'Clinton', 'Columbia', 'Cortland', 'Dutchess', 'Erie', 'Essex', 'Franklin', 'Fulton', 'Genesee', 'Greene',
  'Hamilton', 'Herkimer', 'Jefferson', 'Lewis', 'Livingston', 'Madison', 'Monroe', 'Montgomery', 'Niagara', 'Onondaga', 'Oneida', 'Ontario', 'Orleans', 'Oswego', 'Otsego', 'Rensselaer', 'Saratoga',
  'Schenectady', 'Schoharie', 'Seneca', 'StLawrence', 'Warren', 'Washington', 'Wayne', 'Wyoming','Schenectdady'];
  
  if (areaNames.includes(county)) {
    imageUrl = county.toLowerCase() + '.gif';}
    else { imageUrl = 'default.gif';}

    const handleRedirect = () => {
      window.location.href = 'serviceterr_map';
    };
  
  
  return (
  <div className="container-fluid">
    <div className="row">
    <row class="mb-4"></row>
    {/* Left Side Content */}
    <div class="col-md-1"></div>

    {/* Main center Content */}
    <div className="col-md-6 st_innerContent">

    <span className="st_pagehead">Service Territory Map</span>
    <table border="0" cellSpacing="0" cellPadding="0">
    <tr><td colSpan="2">&nbsp;</td></tr>
<tr>
<td align="left" valign="top"><span className="Niagram_tablefont"></span>


<table className="Niagram_table" border="0" cellPadding="1" cellSpacing="0">
            
<tr><td>&nbsp;</td><td><img alt="" src="/images/serviceterr_map/gas_icon.gif" width="95" height="30"></img></td><td>
<img alt="" src="/images/serviceterr_map/electric_icon.gif" width="95" height="30"></img></td></tr>
<tr><td colSpan="3"><img alt=""src="/images/serviceterr_map/shared_grn_line.gif" width="100%" height="2" ></img></td></tr>
<tr><td colSpan="3">&nbsp;</td></tr>


{/* Display rows based on county value */}

{county === 'Onondaga' && (
<>
<tr><td>Apulia Station, Hamlet of T Fabius</td><td align="center">&nbsp;</td><td align="center">
<img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Apulia, Hamlet of T Fabius</td><td align="center">&nbsp;</td><td align="center">
<img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1">
</img></td></tr>
<tr><td>Baldwinsville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center">
<img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brewerton, Hamlet of T Cicero *</td><td align="center">
<img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td>
<td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bridgeport, Hamlet of T Cicero *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Camillus, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Camillus, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Cicero, Hamlet of T Cicero</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Cicero, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Clay, Hamlet of T Clay</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>Clay, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12"  height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12"  height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%"  height="1"></img></td></tr>
<tr><td>DeWitt, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Syracuse, Village of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elbridge, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Euclid, Hamlet of T Clay</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fabius, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fabius, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fayetteville, Village of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Geddes, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Jamesville, Hamlet of T Dewitt</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lafayette, Hamlet of T Lafayette</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lafayette, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Liverpool, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lysander, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Manlius, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Manlius, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minoa, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mottville, Hamlet of T Skaneateles</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nedrow, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Syracuse, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onativia, Hamlet of Lafayette</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onondaga Hill, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onondaga, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Otisco, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pompey, Hamlet of T Pompey</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="1"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pompey, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salina, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles Falls, Hamlet of T Skaneateles</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Solvay, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Onondaga, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Spafford, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Syracuse, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tully, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tully, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Van Buren, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Phoenix, Hamlet of T Lysander</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Alleghany' && (
<><tr><td>Allentown, Hamlet of T Alma</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alma, Hamlet of T Alma</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alma, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Andover, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Andover, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Black Creek, Hamlet of T New Hudson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
{/* <!---<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bellmont, Town Of</td><td align="center"></td><td align="center"></td></tr>---> */}
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Centerville, Hamlet of T Centerville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Centerville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cuba, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cuba, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Friendship, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Genesee, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Independence, Hamlet of T Independence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Independence, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Hudson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scio, Hamlet of T Scio</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scio, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wellsville, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wellsville, Village of T Wellsville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitesville, Town of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Willing, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Cattaraugus' && (
  <>
<tr><td>Allegany, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Allegany, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ashford, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carrollton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cattaraugus, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cold Spring, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Delevan, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Otto, Hamlet Of T East Otto</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Otto, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellicottville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellicottville, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elton, Hamlet of T Freedom</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Farmersville Station, Hamlet of T Farmersville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Farmersville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Franklinville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Franklinville, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Freedom, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Great Valley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hinsdale, Hamlet of T Hindsdale</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hinsdale, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Humphrey Center, Hamlet of T Humphrey</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Humphrey, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ischua, Hamlet of T Ischua</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ischua, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Knapp Creek, Hamlet of T Allegany</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lime Lake, Hamlet of T Machias</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Limestone, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Little Valley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
{/* <!---<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Little Valley, Village of T Little Valley</td><td align="center"></td><td align="center"></td></tr> ---> */}
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lyndon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Machias Junction, Hamlet of Machias</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Machias, Hamlet of T Machias</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Machias, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mansfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
{/* <!---<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Napoli, Town Of</td><td align="center"></td><td align="center"></td></tr>---> */}
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Albion, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Olean, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Olean, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onoville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Otto, Hamlet of T Otto</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Otto, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Perrysburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Portville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Portville, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Randolph, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Red House, Hamlet of T Redhouse</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Red House, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salamanca, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salamanca, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Valley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Steamburg, Hamlet of T Cold Spring</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Valley,Hamlet of T Ashford</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Weston Mills, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Yorkshire, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>


	

</>
)}

{county === 'Cayuga' && (
<><tr><td>Niles,Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sennett, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Chautauqua' && (
  <><tr><td>Arkwright, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ashville, Hamlet of T North Harmony</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bemus Point, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brocton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Burnhams, Hamlet of T Stockton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Busti, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carroll, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cassadaga, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Charlotte, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chautauqua, Hamlet of T Chautauqua</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chautauqua, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clymer, Hamlet of T Clymer</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clymer, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dewittville, Hamlet of T Chautauqua</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dunkirk, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dunkirk, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellery, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellicott, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Findley Lake, Hamlet of T Mina</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fredonia, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>French Creek, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Frewsburg, Hamlet of T Carroll</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gerry, Hamlet of T Gerry</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gerry, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greenhurst, Hamlet of T Ellery</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Harmony, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kennedy, Hamlet of T Poland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kiantone, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lakewood, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lily Dale, Hamlet of T Pomfret</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mapleville, Hamlet of T Ellery</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mina, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Niobe, Hamlet of T Harmony</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Clymer, Hamlet of T Clymer</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Harmony, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Panama, Village of T Harmony</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Point Chautauqua, Hamlet of T Chautauqua</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Poland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pomfret, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Portland, Hamlet of T Portland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Portland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ripley, Hamlet of T Ripley</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ripley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sheridan, Hamlet of T Sheridan</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sheridan, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sherman, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sherman, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sinclairville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stockton, Hamlet of T Stockton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stockton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watts Flats, Hamlet of T Harmony</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>

	

</>
)}

{county === 'Chenango' && (
<>
<tr><td>Lincklaen, Hamlet of Lincklaen</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lincklaen, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Clinton' && (
<>
<tr><td>Blackbrook, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saranac, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Columbia' && (
<><tr><td>Chatham, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Claverack, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clermont, Hamlet of T Clermont</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clermont, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gallatin, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Germantown, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ghent, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greenport, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hudson, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kinderhook, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kinderhook, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Livingston, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scottville, Hamlet of T Stockport</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stockport, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stuyvesant Falls, Hamlet of T Stuyvesant</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stuyvesant, Hamlet of T Stuyvesant</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stuyvesant, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Taghkanic, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Valatie, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>)}

{county === 'Cortland' && (<>
<tr><td>Cortland, City  Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cortlandville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cuyler, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Homer, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Homer, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>McGraw, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Preble, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scott, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Solon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Truxton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Virgil, Hamlet of T Virgil</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Virgil, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Erie' && (<>
<tr><td>Akron, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Amherst, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Angola, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Athol Springs, Hamlet of T Hamburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Blasdell, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brant, Hamlet of T Brant</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brant, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Buffalo, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cheektowaga, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Collins Center, Hamlet of T Collins</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Collins, Hamlet of T Collins</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Collins, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Concord, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Depew, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Depew, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Derby, Hamlet of T Evans</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Amherst, Hamlet of T Amherst *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Eden, Hamlet of T Eden</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Eden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Eggertsville, Hamlet of T Amherst</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Evans, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Farnham, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Getzville, Hamlet of T Amherst</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Grand Island, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hamburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Iroquois, Hamlet of T Collins</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kenmore, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lackawanna, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lakeview, Hamlet of T Hamburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lancaster, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lawtons, Hamlet of T North Collins</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newstead, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Collins, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Collins, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Evans, Hamlet of T Evans</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Snyder, Hamlet of T Amherst</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Swormville, Hamlet of T Amherst *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tonawanda, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tonawanda, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Seneca, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Williamsville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Woodlawn, Hamlet of T Hamburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>

</>)}



{county === 'Albany' && (
<><tr><td>Albany, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Altamont, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Berne, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bethlehem, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Coeymans, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cohoes, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Colonie, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Colonie, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Delmar, Hamlet of T Bethlehem</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elsemere, Hamlet of T Bethlehem</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Green Island, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Guilderland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Knox, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Latham, Hamlet of T Colonie</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Loudonville, Hamlet of T Colonie</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Maplewood, Hamlet of T Colonie</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>McKownville, Hamlet of T Guilderland</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Menands, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Scotland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newtonville, Hamlet of T Colonie</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ravena, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Selkirk, Hamlet of T Bethlehem</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Slingerlands, Hamlet of T Bethlehem</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Bethlehem, Hamlet of T Bethlehem</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Voorheesville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watervliet, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Albany, Hamlet of T Colonie</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Essex' && (<>
  <tr><td>Bloomingdale, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Crown Point, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lake Placid, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minerva, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mineville, Hamlet of T Moriah</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moriah, Hamlet of T Moriah</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moriah, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Elba, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Hudson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Port Henry, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saranac Lake, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schroon Lake, Hamlet of T Schroon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schroon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>St Armand, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ticonderoga, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ticonderoga, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westport, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westport, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Witerbee, Hamlet of T Moriah</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Franklin' && (
<><tr><td>Altamont, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bangor, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Belmont, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bombay, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brandon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brighton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brushton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Constable, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dickinson Center, Hamlet of T Dickinson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dickinson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Duane Center, Hamlet of T Duane</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Duane, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Covington, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Covington, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Franklin, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Harrietstown, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Malone, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Malone, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moira, Hamlet of T Moira</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moira, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mountain View, Hamlet of T Belmont</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Bangor, Hamlet of T Bangor</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Owls Head, Hamlet of T Belmont</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moira, Hamlet of T Moira</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Santa Clara, Hamlet of T Santa Clara</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Santa Clara, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saranac Lake, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skerry, Hamlet of T Brandon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Bangor, Hamlet of T Bangor</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>St Regis Falls, Hamlet of T Waverly</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Trout River, Hamlet of T Constable</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tupper Lake, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waverly, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Bangor, Hamlet of T Bangor</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westville Center, Hamlet of T Westville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whippleville, Hamlet of T Malone</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Fulton' && (
<><tr><td>Bleecker, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Broadalbin, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Broadalbin, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Caroga, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dolgeville, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ephratah, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gloversville, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Johnstown, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Johnstown, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mayfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mayfield, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Northhampton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Northville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oppenheim, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Perth, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stratford, Hamlet of T Stratford</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stratford, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Genesee' && (
<><tr><td>Alabama, Hamlet of T Alabama</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alabama, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alexander, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alexander, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Attica, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Basom, Hamlet of T Alabama</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Batavia, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Batavia, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bergen, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bethany, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Byron Center, Hamlet of T Byron</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Byron, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Corfu, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Darien Center, Hamlet of T Darien</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Darien, Hamlet of T Darien</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Darien, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Bethany, Hamlet of T Bethany</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Pembroke, Hamlet of T Pembroke</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elba, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elba, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Leroy, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Leroy, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oakfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oakfield, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pavillon, Hamlet of T Pavillon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pavilion, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pembroke, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Byron, Hamlet of T Byron</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stafford, Hamlet of T Stafford</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stafford, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Greene' && (
<><tr><td colspan="3">&nbsp;</td></tr></>
)}

{county === 'Hamilton' && (
<><tr><td>Arietta, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Benson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hope, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Indian Lake, Hamlet of T Indian Lake</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Indian Lake, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Inlet, Hamlet of T Inlet</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Inlet, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lake Pleasant, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Long Lake, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Morehouse, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Raquet Lake, Hamlet of T Long Lake</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Speculator Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wells, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr></>
)}

{county === 'Herkimer' && (
<>
<tr><td>Big Moose, Hamlet of T Webb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cold Brook, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Columbia, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Danube, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dolgeville, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Eagle Bay, Hamlet of T Webb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fairfield, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Frankfort, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Frankfort, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>German Flatts, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Herkimer, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Herkimer, Village of T Herkimer</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ilion, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Indian Castle, Hamlet of T Danube</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kent, Hamlet of T Frankfort</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Litchfield, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Little Falls, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Little Falls, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Manheim, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>McKeever, Hamlet of T Webb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Middleville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mohawk, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newport, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newport, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newville, Hamlet of T Danube</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Norway, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ohio, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Old Forge, Hamlet of T Webb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Poland, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Russia, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salisbury Center, Hamlet of T Salisbury</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salisbury Corners, Hamlet of T Salisbury</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salisbury, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schuyler, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stark, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Thendara, Hamlet of T Webb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Webb, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Jefferson' && (
<>
<tr><td>Adams Center, Hamlet of T Adams</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Adams, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Adams, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alexandria Bay, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Alexandria, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Antwerp, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Antwerp, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Belleville, Hamlet of T Ellisburg</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Black River, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brownville, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brownville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Burrville, Hamlet of T Watertown</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Calcium Hamlet of T Leray</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cape Vincent, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cape Vincent, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carthage, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Champion, Hamlet of T Champion</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Champion, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chaumont, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clayton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clayton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Courners, Hamlet of T Pamelia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Deferiet, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Depauville, Hamlet of T Clayton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dexter, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Rodman, Hamlet of T Rodman</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellisburg, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ellisburg, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Evans Mills, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Felts Mills, Hamlet of T Rutland</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fishers Landing, Hamlet of T Orleans</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Glen Park, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Great Bend, Hamlet of T Champion</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Henderson Harbor, Hamlet of T Henderson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Henderson, Hamlet of Henderson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Henderson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Herrings, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hounsfield, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lafargeville, Hamlet of T Orleans</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Leray, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Leraysville, Hamlet of T Leray</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Limerick, Hamlet of T Brownville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lorraine, Hamlet of T Lorraine</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lorraine, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lyme, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mannsville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Natural Bridge, Hamlet of T Wilna *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Omar, Hamlet of T Orleans</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Orleans, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pamelia Four, Hamlet of T Pamelia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pamelia, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Philadelphia, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Philadelphia, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pierrepont Manor, Hamlet of T Ellisburg</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Plessis, Hamlet of T Alexandria</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Redwood, Hamlet of T Alexandria</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rodman, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rosiere, Hamlet of T Cape Vincent</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rutland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sackets Harbor, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Smithville, Hamlet of T Adams</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>St Lawrence, Hamlet of T Cape Vincent</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Theresa, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Theresa, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Three Mile Bay, Hamlet of T Lyme</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tylerville, Hamlet of T Rutland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watertown, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watertown, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Carthage, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wilna, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Woods Mills, Hamlet of T Wilna</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Woodville, Hamlet of T Ellisburg</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Worth, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Lewis' && (
<><tr><td>Barnes Corners, Hamlet of T Pinckney</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Beaver Falls, Hamlet of T Croghan *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Beaver Falls, Hamlet of T New Bremen *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Belfort, Hamlet of T Croghan</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Castorland, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Constableville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Constableville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Copenhagen, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Croghan, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Croghan, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Deer River, Hamlet of T Denmark</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Denmark, Hamlet of T Denmark</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Denmark, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Diana, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Glenfield, Hamlet of T Martinsburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greig, Hamlet of T Greig</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greig, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Harrisburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Harrisville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>High Market, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lewis, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Leyden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lowville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lowville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lyons Falls, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lyonsdale, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Martinsburg, Hamlet of T Martinsburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Martinsburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Montague, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Natural Bridge, Hamlet of T Diana *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Naumburg, Hamlet of T Denmark</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Bremen, Hamlet of T New Bremen</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Bremen, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Osceola, Hamlet of T Osceola</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Osceola, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pinckney, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Port Leyden, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Port Leyden, Village of T Lyonsdale</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Turin, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Turin, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watson, Hamlet of T Watson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Watson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Martinsburg, Hamlet of T Martinsburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Turin, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Livingston' && (
<><tr><td>Avon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Avon, Village Of </td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Caledonia, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Caledonia, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Conesus, Hamlet Of T Consesus</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Conesus, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Geneseo, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Geneseo, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Groveland, Hamlet of T Groveland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Groveland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hemlock, Hamlet of T Livonia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lakeville, Hamlet of T Livonia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lima, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lima, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Linwood, Hamlet of T York</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Livonia Center, Hamlet of T Livonia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Livonia, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Livonia, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Piffard, Hamlet of T York</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Retsof, Hamlet of T York</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Lima, Hamlet of T Lima</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wadsworth, Hamlet of T York</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>York, Hamlet of T York</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>York, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Madison' && (
<>
<tr><td>Bridgeport, Hamlet of T Sullivan *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Canastota, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cazenovia, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cazenovia, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chittenago, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clockville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Deruyter, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Deruyter, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fenner, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lenox, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lenox, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lincoln, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Munnsville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nelson, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oneida Lake, Hamlet of T Lenox</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oneida, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Bay, Hamlet of T Lenox</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stockbridge, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sullivan, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Valley Mills, Hamlet of T Stockbridge</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wampsville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitelaw, Hamlet of T Lenox</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Monroe' && (
<><tr><td>Brighton, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brockport, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chili, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clarkson, Hamlet of of T Clarkson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clarkson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Garbutt, Hamlet of of T Wheatland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hamlin, Hamlet of of T Hamlin</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hamlin, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Henrietta, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Honeoye Falls, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Industry, Hamlet of of T Rush</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mendon, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Morton, Hamlet of T Hamlin</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mumford, Hamlet of T Wheatland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ogden, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Parma, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Riga, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rush, Hamlet of T Rush</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rush, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scottsville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sweden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Walker, Hamlet of T Hamlin</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Rush, Hamlet of T Rush</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wheatland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Montgomery' && (
<><tr><td>Ames, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Amsterdam, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Amsterdam, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Canajoharie, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Canajoharie, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Charleston, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Florida, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fonda, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Johnson, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Plain, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fultonville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Glen, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hagaman, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minden, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mohawk, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nelliston, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr> 
<tr><td>Palatine Bridge, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Palatine, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Root, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saint Johnsville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saint Johnsville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tribes Hill, Hamlet of T Amsterdam</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Niagara' && (
<><tr><td>Appleton, Hamlet of T Newfane</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Barker, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Beach Ridge, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Beebe, Hamlet of T Wilson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bergholtz, Hamlet of T Wheatfield</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Burt, Hamlet of T Newfane</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cambria, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gasport, Hamlet of T Royalton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hartland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hoffman Station, Hamlet of T Beach Ridge</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lewiston, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lewiston, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Middleport, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Modelton, Hamlet of T Lewiston</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newfane, Hamlet of T Newfane</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Newfane, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Niagara Falls, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Niagara, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Tonawanda, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Olcott, Hamlet of T Newfane</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pekin, Hamlet of T Cambria</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pendleton Center, Hamlet of T Pendleton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pendleton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pleasant Corners, Hamlet of T Wilson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Porter, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ransomville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Royalton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sanborn, Hamlet of T Lewiston</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sanborn, Hamlet of T Cambria</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Shawnee, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Somerset, Hamlet of T Somerset</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Somerset, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>St Johnsburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sunset Beach, Hamlet of T Wilson</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wheatfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wilson, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wilson, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Youngstown, Village of T Porter</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Onondaga' && (
<><tr><td>Apulia Station, Hamlet of T Fabius</td><td align="center">&nbsp;</td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Apulia, Hamlet of T Fabius</td><td align="center">&nbsp;</td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Baldwinsville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brewerton, Hamlet of T Cicero *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bridgeport, Hamlet of T Cicero *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Camillus, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Camillus, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cicero, Hamlet of T Cicero</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cicero, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clay, Hamlet of T Clay</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clay, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>DeWitt, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Syracuse, Village of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Elbridge, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Euclid, Hamlet of T Clay</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fabius, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fabius, Village of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fayetteville, Village of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Geddes, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Jamesville, Hamlet of T Dewitt</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lafayette, Hamlet of T Lafayette</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lafayette, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Liverpool, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lysander, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Manlius, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Manlius, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minoa, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mottville, Hamlet of T Skaneateles</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nedrow, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Syracuse, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onativia, Hamlet of Lafayette</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onondaga Hill, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Onondaga, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Otisco, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pompey, Hamlet of T Pompey</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pompey, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Salina, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles Falls, Hamlet of T Skaneateles</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Skaneateles, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Solvay, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Onondaga, Hamlet of T Onondaga</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Spafford, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Syracuse, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tully, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Tully, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Van Buren, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Phoenix, Hamlet of T Lysander</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Oneida' && (
<><tr><td>Alder Creek, Hamlet of T Boonville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Annsville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ava, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Blossvale, Hamlet of T Annsville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Boonville, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Boonville, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Camden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Camden, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cassville, Hamlet of T Paris</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chadwicks, Hamlet of T New Hartford</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Churchville, Hamlet of T Verona</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clark Mills, Hamlet of T Kirkland</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clayville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clinton, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Deerfield, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Durhamville, Hamlet of T Verona</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Florence, Hamlet of T Florence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Florence, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Floyd, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Forestport, Hamlet of T Forestport</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Forestport, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Holland Patent, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Jewell, Hamlet of T Vienna</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kirkland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lee Center, Hamlet of T Lee</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lee, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lehigh, Hamlet of T Florence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Marcy, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>McConnellsville, Hamlet of T Vienna</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Hartford, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Hartford, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New London, Hamlet of T Verona</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New York Mills, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Bay, Hamlet of T Vienna</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Western, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oneida Castle, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oriskany, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Otter Lake, Hamlet of T Forestport</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Paris, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Prospect Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Remsen, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Remsen, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rome, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sauquoit, Hamlet of T Paris</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sherrill, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sherrill, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stacy Basin, Hamlet of T Verona</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Steuben, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stittville, Hamlet of T Marcy</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sylvan Beach, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Taberg, Hamlet of T Annsville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Trenton, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Trenton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Utica, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Vernon Center, Hamlet of T Vernon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Vernon, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Vernon, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Verona Mills, Hamlet of T Verona</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Verona, Hamlet of T Verona</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Verona, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Vienna, Hamlet of T Vienna</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Vienna, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Washington Mills, Hamlet of T New Hartford</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westdale, Hamlet of T Camden</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Western, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westernville, Hamlet of T North Western</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westmoreland, Hamlet of T Westmoreland</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Westmoreland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>White Lake, Hamlet of T Forestport</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitesboro, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitestown, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Willowvale, Hamlet of T New Hartford</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Woodhull, Hamlet of T Forestport</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Yorkville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Ontario' && (
<><tr><td>Canadice, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Bloomfield, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Honeoye, Hamlet of T Richond</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ionia, Hamlet of T West Bloomfield</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Bloomfield, Hamlet of T West Bloomfield</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Richmond, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Bloomfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colSpan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Orleans' && (
<><tr><td>Albion, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Albion, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Barre, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carlton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Claredon, Hamlet of T Claredon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Claredon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Eagle Harbor, Hamlet of T Gaines</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fancher, Hamlet of T Murray</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gaines, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hulberton, Hamlet of T Murray</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kendall, Hamlet of T Kendall</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kendall, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kent, Hamlet of T Carleton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Knowlesville, Hamlet of T Rigeway</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lyndonville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Medina Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Murray, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ridgeway, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Shelby, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waterport, Hamlet of T Carlton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Yates, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Oswego' && (
<><tr><td>Albion, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Altmar, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Amboy, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Boylston, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brewerton, Hamlet of T Hastings *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Central Square, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cleveland, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Constantia, Hamlet of T Constantia</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Constantia, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fernwood, Hamlet of T Richland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fulton, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Granby, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hannibal, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hannibal, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hastings, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lacona, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lycoming, Hamlet of T Scriba</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mexico,  Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Mexico, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minetto, Hamlet of T Minetto</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Minetto, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Haven, Hamlet of T New Haven</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>New Haven, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Orwell, Hamlet of T Orwell</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Orwell, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oswego Center, Hamlet of T Oswego</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oswego, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oswego, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Palermo, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Parish, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Parish, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Phoenix, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pulaski, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Redfield, Hamlet of T Redfield</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Redfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Richland, Hamlet of T Richland</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Richland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sandy Creek, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sandy Creek, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schroeppel, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scriba, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Volney, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Monroe, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Williamstown, Hamlet of T Williamstown</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Williamstown, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Otsego' && (
<><tr><td>Cherry Valley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cherry Valley, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Decatur, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Worcester, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Maryland, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Roseboom, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schenevus, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Worcester, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Rensselaer' && (
<>
<tr><td>Brunswick, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Castleton On Hudson, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cropseyville, Hamlet of T Brunswick</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Greenbush, Hamlet of T East Greenbush</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>East Greenbush, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Grafton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hampton Manor, Hamlet of T East Greenbush</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hoosick Falls, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hoosick, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Johnsonville, Hamlet of T Pittstown</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nassau, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nassau, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Greenbush, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pittstown, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Poestenkill, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rensselaer, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sand Lake, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schaghticoke, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schaghticoke, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schodack, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Troy, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Valley Falls, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Saratoga' && (
<><tr><td>Ballston, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ballston Spa, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Charlton, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clifton Park, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Corinth, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Corinth, Village Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Day, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Edinburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Galway, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Galway, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greenfield, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hadley, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Halfmoon, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Malta, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Milton, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Moreau, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>MT McGregor, Hamlet of T Moreau</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Northumberland, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Palmer, Hamlet of T Corinth</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Providence, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rexford, Hamlet of T Clifton Park</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saratoga Springs, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Saratoga, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schuylerville, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Glens Falls, Village of T Moreau</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stillwater, Town Of *</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Victory Mills, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waterford, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waterford, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wilton, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Schenectady' && (
<>
</>
)}

{county === 'Schoharie' && (
<><tr><td>Blenheim, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Broome, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carlisle, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cobleskill, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cobleskill, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Esperance, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Esperance, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fulton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Middleburgh, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Middleburgh, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Richmondville, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schoharie, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schoharie, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Seward, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sharon Springs, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Sharon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Summit, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wright, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Seneca' && (
<>
<tr><td colspan="3">&nbsp;</td></tr>
</>
)}

{county === 'StLawrence' && (
<><tr><td>Balmat, Hamlet of T Fowler</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bigelow, Hamlet of T Dekalb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brasher Falls, Hamlet of T Brasher</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brasher, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brier Hill, Hamlet of T Morristown</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Canton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Canton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chase Mills, Hamlet of T Waddington</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chippewa Bay, Hamlet of T Hammond</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clare, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clifton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Colton, Hamlet of T Colton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Colton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cranberry Lake, Hamlet of T Clifton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Crary Mills, Hamlet of T Potsdam</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dekalb Junction, Hamlet of T Dekalb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dekalb, Hamlet of T Dekalb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dekalb, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Depeyster, Hamlet of T Depeyster</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Depeyster, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Edwards, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Edwards, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Edwardsville, Hamlet of T Morristown</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fine, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Flackville, Hamlet of T Lisbon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Jackson, Hamlet of T Hopkinton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Jackson, Hamlet of T Lawrence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fowler, Hamlet of T Fowler</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fowler, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gouverneur, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Gouverneur, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hammond, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hammond, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hannawa Falls, Hamlet of T Pierrepont</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Helena, Hamlet of T Brasher</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hermon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hermon, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Heuvelton, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hopkinton, Hamlet of T Hopkinton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hopkinton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lawrence, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lawrenceville, Hamlet of T Lawrence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lisbon, Hamlet of T Lisbon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lisbon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Louisville, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Macomb, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Madrid Springs, Hamlet of T Madrid</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Madrid, Hamlet of T Madrid</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Madrid, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Massena, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Massena, Village Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Morely, Hamlet of T Canton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Morristown, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Morristown, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Natural Dam, Hamlet of T Gouverneur</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Nicholville, Hamlet of T Lawrence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Norfolk, Hamlet of T Norfolk</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Norfolk, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Lawrence, Hamlet of T Lawrence</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Norwood, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Ogdensburg, City Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oswegoatchie, Hamlet of T Fine</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Oswegoatchie, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Parishville, Hamlet of T Parishville</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Parishville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Piercefield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pierrpoint, Hamlet of T Pierrpoint</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pierrpoint, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pitcairn, Hamlet of T Pitcairn</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pitcairn, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pope Mills, Hamlet of T Macomb</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Potsdam, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Potsdam, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Pyrites, Hamlet of T Canton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Raymondville, Hamlet of T Norfolk</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rensselaer Falls, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Richville, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rossie, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Russell, Hamlet of T Russell</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Russell, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Colton, Hamlet of T Colton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Edwards, Hamlet of T Edwards</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Star Lake, Hamlet of T Fine</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stockholm, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waddington, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Waddington, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wanakena, Hamlet of T Fine</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Potsdam, Hamlet of T Potsdam</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>West Stockholm, Hamlet of T Stockholm</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Winthrop, Hamlet of T Stockholm</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Warren' && (
<><tr><td>Bolton Landing, Hamlet of T Bolton</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Bolton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Brant Lake, Hamlet of T Horicon</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chester, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Chestertown, Hamlet of T Chester</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Glens Falls, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hague, Hamlet of T Hauge</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hague, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Horicon, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Johnsburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lake George, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lake George, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Lake Luzerne, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Luzerne, Hamlet Of Lake Luzerne</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>North Creek, Hamlet of T Johnsburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Queensbury, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Stony Creek, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Thurman, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Warrensburg, Hamlet Of T Warrensburg</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Warrensburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Washington' && (
<><tr><td>Argyle, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Argyle, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cambridge, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Cambridge, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Clemons, Hamlet Of T Dresden</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Comstock, Hamlet Of T Fort Ann</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Dresden, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Easton, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Ann, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Ann, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Edward, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Fort Edward, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greenwich, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Greenwich, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hampton, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hartford, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Hudson Falls, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Jackson, Town Of *</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Kingsbury, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Putman, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>White Creek, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitehall, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Whitehall, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}

{county === 'Wayne' && (
<>
<tr><td colspan="3">&nbsp;</td></tr>
</>
)}

{county === 'Wyoming' && (
<><tr><td>Attica, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Attica, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Covington, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Orangeville, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Wethersfield, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}



{county === 'Schenectdady' && (
<>
<tr><td>Alplaus, Hamlet of T Glenville</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Carman, Hamlet of T Rotterdam</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Delanson, Village Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Duanesburg, Town Of</td><td align="center"></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Glenville, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Niskayuna, Hamlet of T Niskayuna</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Niskayuna, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Princetown, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rotterdam Junction, Hamlet of T Rotterdam</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Rotterdam, Town Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Schenectady, City Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>Scotia, Village Of</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
<tr><td>South Schenectady, Hamlet of T Rotterdam</td><td align="center"><img alt="" src="/images/serviceterr_map/gas_x.gif" width="12" height="12"></img></td><td align="center"><img alt="" src="/images/serviceterr_map/electric_x.gif" width="12" height="12"></img></td></tr>
<tr><td colspan="3"><img alt="" src="/images/serviceterr_map/blk_dot.gif" width="100%" height="1"></img></td></tr>
</>
)}
</table>

</td>
</tr>
<tr><td colSpan="2"><div className="smallfont">* Indicates limited service area</div></td></tr>
    <tr>
      <td colSpan="2" valign="top">
        {/* <img src="/images/serviceterr_map/shared_grn_line.gif" alt="shared_grn_line" width="87%" height="2"  />
        <input className="Backbutton" type="button" value="Back" name="back" onClick={handleRedirect} />  */}
        </td>
    </tr>
</table>
</div>
    
    



{/* Right Side Content */}
    <div class="col-md-5">
                <img src={"/images/serviceterr_map/spacer.gif"} width="20" height="1" alt="spacer" />
                <img src={`/images/serviceterr_map/county/${imageUrl}`} alt="imageUrl" />
                <div class="mt-3"></div>
                 <span className="bluefont"> 
                  <img alt="" src="/images/serviceterr_map/spacer.gif" height="1" width="8%" border="0"></img>
                  {'<< '}
                  <a href="serviceterr_map">Back to Map</a>
                 </span> 
</div>

    <div className="row">
     <div class="col-md-2"></div>
     <div class="col-md-10">
     <img src="/images/serviceterr_map/shared_grn_line.gif" alt="shared_grn_line" width="87%" height="2px"  />
        <input className="Backbutton" type="button" value="Back" name="back" onClick={handleRedirect} /> 
     </div>
     </div>
</div>
<br />
</div>
);

}
catch (error) {
 console.error('An error occurred in ServiceTerritoryMap:', error);
}

}
export default ServiceTerritoryMap;