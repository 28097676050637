import React from "react";
import "../../styles/transmission.css"
import SodemanImg from '../../assets/images/transmission/Sodeman.bmp';
function SodeSubstation() {
    return (
    <div className="container">
    <div className="row actualContent_Transmission">
         {/* <!--- right side ---> */}
         <div class="col-md-1 leftSide"></div>
    <div className="col-md-6 innerContent">
        <span class="pagehead">Sodeman Road Substation Project</span>
        <br />
        <img id="sodeman" src={SodemanImg} border="0" alt="Sodeman"></img>
        <br /><br />
        <p>The Sodeman Road Substation Project involves the construction of a 115/13.2kV substation in the Town of Milton in Saratoga County, 
            New York. This area is continuously growing and increased demands are being placed on an aging electrical system. 
            The Project would resolve current issues with neighboring substations: Ballston, Brook Road and Rock City Falls, 
            and would allow for the retirement of the Rock City Falls Station. 
            The substation will reinforce the existing upstate New York transmission system and support the region’s long-range energy needs. 
            The New York State Public Service Commission granted the Certificate of Environmental Compatibility 
            and Public Need Amendment application on April 22, 2016. Construction is planned to begin in 2018.</p>
        <br /><br />
    </div>
    
    {/* <!--- right side ---> */}
    <div class="col-md-3 rightSide">
	<span class="subHeadingText">Links</span>
	<div class="rightside_content">
	<p><b>New York State Public Service Commission Case Number: </b><a href="http://documents.dps.ny.gov/public/MatterManagement/CaseMaster.aspx?MatterSeq=33427&amp;MNO=10-T-0080" rel="noreferrer" target="_blank">10-T-0080</a></p><br />
	<p><b>Website:</b> <a href="http://capitalregionreliability.com/sodeman-road.htm" target="_blank" rel="noreferrer">www.capitalregionreliability.com/</a></p><br />
  {/* <!--  <p><b>Stakeholder Relations Lead: </b>Erin Gryniak</p><br />--> */}
	<p><b>Project Email:</b> <a href="mailto:info@capitalregionreliability.com" target="_blank" rel="noreferrer">info@capitalregionreliability.com</a></p><br />
	<p><b>Project Hotline: </b> <br />844-727-6649<br /> <br />  
	    <b>Secretary to the Public Service Commission:</b><br />518-474-6530<br /> <br />
	         <b>New York State Department of Public Service, Environmental Certification and Compliance Section:</b><br />518-474-8702</p><br /> 
	</div>
    </div>

    <br />
    </div>
    </div>
   );
   };

export default SodeSubstation
