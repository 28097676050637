import React from 'react'
import styles from '../../../styles/catv.module.css'
import Img_button_back from '../../../assets/images/button_back.gif';

const SafetyBeforeEntering = () => {
  return (
    <>
    <div>
    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td width="20" align="left" valign="top">&nbsp;</td>
          <td align="left" valign="top">
            <p><b><br />
              <span className={styles.sectionhead}>Before entering an enclosed space you must:</span>
            </b></p>
            <ul>
              <li className={styles.square}>Have obtained authorization to enter</li>
              <li className={styles.square}>A National Grid representative must be present</li>
              <li className={styles.square}>Have successfully completed training</li>
              <li className={styles.square}>Conduct a hazard evaluation, including an air quality assessment and job brief</li>
              <li className={styles.square}>Wear all appropriate personal protective equipment</li>
              <li className={styles.square}>Have the necessary permits</li>
              <li className={styles.square}>Have an attendant immediately available outside the space who is trained in non-entry rescue procedures, emergency notification, first aid, and CPR</li>
              <li className={styles.square}>Establish a communication method to be used while in the enclosed space<br /></li>
            </ul>
            <p>&nbsp; </p>
            <p>&nbsp;</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  </div>
  <div id="Layer1" style={{position:"absolute", width:"52px", height:"16px", zIndex:"9", left:"774px", top:"17px"}} ><a href="safety"><img src={Img_button_back} width="51" height="15" border="0" alt='back' /></a></div>
  </>
  )
}

export default SafetyBeforeEntering

