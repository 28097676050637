import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import axios from "axios";
import imgMainBanner from '../../assets/images/d_banner-business_ma.jpg';


function ConnectsOrder() {   
  const [isSubmit, setIsSubmit] = useState(false); 
    const [quantity, setQuantity] = useState(0);
    
    const [emailInfo] = useState({
      messageBody:'',
      mailFrom:'',
      mailTo:'',
      subject:'',
      isHtmlBody: true
    });
    
// State to store the form data
    const [formData, setFormData] = useState ({
        CompanyName: '',
        PhoneNumber: '',
        ElectricianName: '',
        ShippingNote:'',
        Q_C7D: '',
        Q_C7B:'',
        Q_C60R:'',
        Q_C60S: '',
        Q_S13J:'',
        Q_C60E:'',
        Q_S13L:'',
        Q_C60G:'',
        Q_S13H:'',
        Q_S13LI:''
    });
    
    const [formatFormData] = useState({
      emailInfo: emailInfo,
      contentFormDetails: formData
    });

    const [errors, setErrors] = useState({
        CompanyName: '',
        PhoneNumber: '',
        ElectricianName: '',
        ShippingNote:'',
        Quantity: 0
    });

    // Function to handle form input changes    
    const handleChange = (e) => { 
      setFormData({ ...formData, [e.target.name]: e.target.value });

      if( !formData.Q_C7B || !formData.Q_C60R || !formData.Q_C7D || !formData.Q_C60S ||
        !formData.Q_S13J || !formData.Q_C60E || !formData.Q_S13L || !formData.Q_C60G || 
        !formData.Q_S13H || !formData.Q_S13LI ) 
        { 
            setQuantity(formData.Q_C7B + formData.Q_C60R + formData.Q_C7D + formData.Q_C60S + 
              formData.Q_S13J + formData.Q_C60E + formData.Q_S13L + formData.Q_C60G + formData.Q_S13H + 
              formData.Q_S13LI);
            }
    };
  // Function to submit the form data using Axios
    const onSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()){
          //final set data to post
          formatFormData.contentFormDetails = formData;
            try {
              //const response = await axios.post("https://localhost:44389/api/ConnectsOrderMail", formatFormData);
              const response = await axios.post(`${process.env.REACT_APP_BASE_URL + 'ConnectsOrderMail' }`, formatFormData);
              console.log("Post created:", response.data);
              if(response.data.includes('success'))
              {
                setIsSubmit(true);
                //Reset form
                Array.from(e.target).forEach((e) => (e.value = ""));
              }              
            } 
            catch (error) {
              console.error("Error creating post:", error);
            }
        }        
    }
    const validateForm = () => { 
        let valid = true;
        const errorsCopy = { ...errors };
    
        if (!formData.CompanyName.trim()) {
          errorsCopy.companyName = 'Company Name is required';
          valid = false;
        } 
        else {
          errorsCopy.companyName = '';
        }
    
        if (!formData.ElectricianName.trim()) {
            errorsCopy.electricianName = 'Electrician Name is required';
            valid = false;
          } 
          else {
            errorsCopy.electricianName = '';
          }

        if (!formData.PhoneNumber.trim()) {
            errorsCopy.phoneNumber = 'Phone Number is required';
            valid = false;
          } 
          else {
            errorsCopy.phoneNumber = '';
          }          

        if( !formData.Q_C7B || !formData.Q_C60R || !formData.Q_C7D || !formData.Q_C60S ||
        !formData.Q_S13J || !formData.Q_C60E || !formData.Q_S13L || !formData.Q_C60G || 
        !formData.Q_S13H || !formData.Q_S13LI ) 
        {              
            if(quantity === 0)
            {
                errorsCopy.quantity = 'Quantity is required';
                valid = false;
            }        
            else
              errorsCopy.quantity = 0;
        }
        else {
          errorsCopy.quantity = 0;
      }
        setErrors(errorsCopy);
        return valid;
      };    
      
      return (
        <>
        <div className='img-fluid'>
          <img className='NG-banner-connects-img' src={imgMainBanner} alt='National Grid'></img>
        </div> 
        <div className='row'>
            <div className="col-3"></div>
            <div className="col-8 pt-2"><h3>National Grid Connects - Connector Order</h3></div>
            <div className="col-1"></div>
        </div>
        <div className='row' hidden = {!isSubmit}>
              <div className="col-12 pt-2">
              Your order form has been submitted. The connectors and covers will be shipped to the shipping address entered on the registration form.
              <br/>Thank You for participating in this program.  
              </div>              
          </div>
        <div className="NG-form" hidden = {isSubmit}>  
              
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">            
            <hr ></hr>            
            <p>All (*) fields are required.</p>
            <div className="row">
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-2">
                    <label  className="col-form-label" >Company Name: *</label>
                  </div>
                  <div className="col-10">
                  <input type="text" name="CompanyName" data-testid="companyname"
                        className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                        value = {formData.CompanyName}
                        onChange={handleChange}
                    />
                    <div className="error-message">{errors.companyName ? errors.companyName: ""}</div>                    
                  </div>
                </div>

                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label" >Electrician Name: *</label>
                  </div>
                  <div className="col-10">
                  <input type="text" name="ElectricianName" data-testid="electricianname"
                        className={`form-control ${errors.electricianName ? 'is-invalid' : ''}`}
                        onChange={handleChange}
                        value={formData.ElectricianName}
                    />
                    <div className="error-message">{errors.electricianName ? errors.electricianName: ""}</div>                    
                  </div>
                </div>

                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label" >Phone Number: *</label>
                  </div>
                  <div className="col-4">
                  <InputMask type="text" name="PhoneNumber" mask="999-99-9999" data-testid="phonenumber"
                    value={formData.PhoneNumber} onChange={handleChange}
                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} />                    
                    <div className="error-message">{errors.phoneNumber ? errors.phoneNumber : ""}</div>                    
                  </div>
                  <div className="col-6">
                  should be in xxx-xxx-xxxx format.
                  </div>                  
                </div>

                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">Shipping Note</label>
                  </div>
                  <div className="col-10">
                  <textarea name ='ShippingNote' data-testid="shippingnote"
                        maxLength = "256"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.ShippingNote}>                            
                    </textarea>                   
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <hr ></hr>
                  </div>                  
                </div>

                <div className="row">
                  <div className="col-12">
                  All Connectors will be shipped to the shipping address on file when initially signed up for program.<br/>
                  Call 800-260-0054 if shipping address needs to be updated
                  </div>                  
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-10">
                    <label className="col-form-label">Quantity	Connectors and Covers: *</label>
                    <div className="error-message">{errors.quantity ? errors.quantity : ""}</div>
                  </div>                  
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name='Q_C7B' data-testid="Q_C7B"
                        className='form-control my-1'
                        onChange={handleChange}
                        value={formData.Q_C7B}>
                    </input> 
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C7B) Bolted Connector - #6 STR- #2/0 STR to #6 STR to #2/0 STR</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_C60R" data-testid="Q_C60R"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_C60R}>
                    </input>                     
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C60R) Cover for C7B bolted connector</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_C7D"  data-testid="Q_C7D"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_C7D}>
                    </input>
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C7D) Bolted Connector - #4/0 STR – 400kcmil to #6 STR - #2/0 STR</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_C60S" data-testid="Q_C60S"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_C60S}>
                    </input>                  
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C60S) Cover for C7D bolted connector</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2">
                  <input type="number" name="Q_S13J" data-testid="Q_S13J"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_S13J}>
                    </input> 
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(S13J) Compression Connector - #2/0 STR - #1/0 STR to #2/0 STR - #1/0 STR</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_C60E" data-testid="Q_C60E"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_C60E}>
                    </input>                   
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C60E) Cover for S13J & S13H compression connector</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_S13L" data-testid="Q_S13L"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_S13L}>
                    </input>                   
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(S13L) Compression Connector - #4/0 STR to #2/0 STR - #1/0 STR</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_C60G" data-testid="Q_C60G"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_C60G}>
                    </input>
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(C60G) Cover for S13L & S13LI compression connector</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_S13H" data-testid="Q_S13H"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_S13H}>
                    </input>
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(S13H) Compression Connector - #2/0 STR - #2 STR to #2 STR</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2"> 
                  <input type="number" name="Q_S13LI" data-testid="Q_S13LI"
                        className='form-control  my-1'
                        onChange={handleChange}
                        value={formData.Q_S13LI}>
                    </input> 
                  </div>
                  <div className="col-8">                      
                    <label className="col-form-label" >(S13LI) Compression Connector - #4/0 STR to #1 STR - #6 STR</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2">
                  </div>
                  <div className="col-2">
                  </div>
                  <div className="col-8 py-3">
                  <button type="submit" data-testid="cancel" className="NG-btn-blue float-start">Cancel</button>
                    <button type="submit" id="continue" data-testid="continue" className="NG-btn-blue float-start mx-3">Continue</button>
                    
                  </div>
                </div>
            </form>
            </div>
            </div>
        </div>   
    </>
      
);}
  
export default ConnectsOrder;