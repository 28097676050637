
import React from 'react';
import Helmet from 'react-helmet';
import Img_download_button from '../../../../assets/images/download_button.jpg';
import Img_bullet_triangle from '../../../../assets/images/bullet_triangle.gif';
import Img_shared_bullet_diamond from '../../../../assets/images/shared_bullet_diamond.gif';
import Img_grn_arrow_app from '../../../../assets/images/grn_arrow_app.gif';

const A_Style = {
    color: '#00345B',
    fontSize: '11px',
    fontfamily: 'Verdana, sans - serif',
    textDecoration: 'underline',
    fontWeight: 'normal'

};
function Tower() {
    return (
        <div>
            <Helmet>
                <title>National Grid</title>
            </Helmet>
            <div style={{ paddingLeft: '10px', fontFamily: 'Verdana, sans-serif', fontSize: '11px' }}>
                <div className='row'>
                    <div className='col-10'>
                        <p style={{ margin: '1em' }}>
                            <span className="Catv_PageHead"><b>CLEC-Transmission Pole</b></span>
                            <br />
                            If you aren't already signatory to a<b> Standard Transmission Pole Agreement</b> click on the Agreement below and follow the instructions.
                        </p>

                    </div>
                </div >
                <div className='row' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <div className='col-10' style={{ paddingLeft: '22px' }} >


                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Standard Transmission Pole Agreement</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%' }}>
                                <a href="../../../pdf/trans_poles_agreement.pdf">
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>

                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Third Party Instructions</b>
                            </div>

                            <div style={{ textAlign: 'right', width: '25%' }}>
                                <a href="../../../doc/1207/ATT._2-Third_Party_Instructions_-_Transmission_02_06_23_1 (1).doc">
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ maxWidth: '75%', minWidth: '300px' }} >
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} />  <b>Joint Owner List</b> - Applicants are to verify transmission pole ownership with National Grid.
                            </div>

                            <div style={{ textAlign: 'right', width: '25%' }}>
                                <a href="tower_tele_info">
                                    <img src={Img_download_button} width="90" height="23" border="0" alt="download" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>


                        <div className='row divrowpadding'>
                            <div style={{ width: '60%', minWidth: '280px' }}>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} /> <b>Pole Attachment Application</b>
                            </div>
                            <div style={{ textAlign: 'right', width: '40%' }}>
                                <a href="clec_tower_app">
                                    <img src={Img_grn_arrow_app} width="224" height="49" border="0" alt="click here" />
                                </a>
                            </div>
                        </div>

                        <div style={{ paddingLeft: '12px' }}>
                            <hr style={{ opacity: '0.7', margin: '0', borderTop: 'solid black' }} />
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" alt="bullet" style={{ verticalAlign: 'top' }} /> <b style={{ color: '#00345B' }}>Rates and Fees Information</b>
                            </div>
                        </div>

                        <div className='row divrowpadding'>
                            <p style={{ paddingLeft: '40px' }}>
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />  <a href="../../../pdf/posting/Website_Service Rate Summary Sheet (2007).pdf" style={A_Style}>Service Rate Summary Sheet</a> (.pdf)
                                <br />
                                <img src={Img_bullet_triangle} width="6" height="10" alt="bullet" style={{ verticalAlign: 'top' }} />  <a href="../../../pdf/posting/2016 Make-Ready Summary Sheet.pdf" style={A_Style}>Make-Ready Summary Sheet</a> (.pdf)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Tower;