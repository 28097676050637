import {React } from 'react'

function Generator6()
{  
      return(
        <>
        <p style ={{fontWeight : "bold"}}>Information for:</p>
        <small>
            <p>MA, NH or RI Interconnection Projects <br></br> 20MW or Less</p>
            <p>Your project may follow either your State's process, or another form of agreement. Please <a href="https://www9.nationalgridus.com/transmission/c3-7_accountmgmt.asp">contact your Account Manager</a>.</p>
        </small>
       </>
    );
}

export default Generator6;