import React from 'react'
import styles from '../../../../styles/catv.module.css'
import contact_card from "../../../../assets/images/contact_card.gif"
import button_back from "../../../../assets/images/button_back.gif"

function TowerTeleInfo()
{
    return(
        <>
            <div className = "container mt-2 ">
                <div className = "row">
                    <div className = "col-12">
                        <span className={styles.sectionhead1}>Joint Pole Owners List</span>
                    </div>
                </div> 
                <div className = "row mt-3">
                    <div className = "col-3">
                        <p className = {styles.ContactDetails}><b>Windstream Communications </b> <br></br>
                            201 East Fourth Street<br></br>
                            P.O. Box 850<br></br>
                            Jamestown, NY 14702-0850 <br></br>
                            (716) 661-5415<br></br>
                            <br></br>
                            <b>Armstrong Telephone Co. </b><br></br>
                            136 East Front Street<br></br>
                            P.O. Box K<br></br>
                            Addison, NY 14801 <br></br>
                            (607) 359-2211<br></br>
                            <b><br></br>
                                Berkshire Telephone Co. </b><br></br>
                                19 Broad St.<br></br>
                                PO Box 405        <br></br>
                            Kinderhook, NY 12106 <br></br>
                            Brian Kelley <br></br>
                            (518) 758-9900<br></br>
                            <br></br>
                            <b>Chautauqua &amp; Erie Telephone Corp.</b> <br></br>
                            30 Main Street<br></br>
                            P.O. Box B<br></br>
                            Westfield, NY 14787 <br></br>
                            (716) 326-2121<br></br>
                            <br></br>
                            <b>Chazy &amp; Westport Telephone Co. </b><br></br>
                            2 Champlain Ave.<br></br>
                            Westport, NY 12993 <br></br>
                            Gerald R. Forcier <br></br>
                            (518) 962-8211<br></br>
                            <br></br>
                            <b>Citizens Communications Co.of NY, Inc.</b><br></br>
                            137 Harrison Street<br></br>
                            Johnstown, NY 12095 <br></br>
                            Peter Czechowicz <br></br>
                            (518) 773-6521<br></br>
                            <br></br>
                            <b>Citizens Telephone of Hammond </b><br></br>
                            PO Box 217<br></br>
                            Hammond, NY 13646 <br></br>
                            Donald Ceresoli, Jr. <br></br>
                            (315) 324-5911<br></br>
                            <b><br></br>
                                Crown Point Telephone Co.</b> <br></br>
                            Main Street<br></br>
                            P.O. Box 275<br></br>
                            Crown Point, NY 12928-0275 <br></br>
                            (518) 597-3300<br></br>
                            <br></br>
                            </p>                       
                    </div>
                    <div className = "col-3">
                    <p className = {styles.ContactDetails}><b>Dunkirk Fredonia Telephone Company </b><br></br>
                        40 Temple St.<br></br>
                        PO Box 209<br></br>
                        Fredonia, NY 14063 <br></br>
                        Wade Weatherlow <br></br>
                        (716) 673-3031 <br></br> <br></br>
                            <b>Edwards Telephone Co. </b><br></br>
                            d/b/a TDS Telecom <br></br>
                            P.O. Box 36<br></br>
                            Edwards, NY 13635 <br></br>
                            (315) 562-3211<br></br>
                            <b><br></br>
                                Empire Telephone Corp.</b><br></br>
                            34 Main Street<br></br>
                            P.O. Box 349<br></br>
                            Prattsburg, NY 14873 <br></br>
                            (607) 522-3712<br></br>
                            <br></br>
                            <b>Frontier Communications of Ausable Valley, Inc. </b><br></br>
                            310 Front Street<br></br>
                            Keeseville, NY 12944 <br></br>
                            Gene Gengle <br></br>
                            (716) 834-7211
                        <b>Frontier Communications of Rochester, Inc. </b><br></br>
                            180 South Clinton Avenue<br></br>
                            Rochester, NY 14646-0995 <br></br>
                            Mary Ann Murray <br></br>
                            (716) 777-7944<br></br>
                            <br></br>
                            <b>Frontier Communications of Seneca Gorham, Inc. </b><br></br>
                            71 Main Street<br></br>
                            P.O. Box 278<br></br>
                            Bloomfield, NY 14469-0278 <br></br>
                            (716) 657-7111<br></br>
                            <b><br></br>
                            Germantown Telephone Co. </b><br></br>
                            210 Main St.<br></br>
                            PO Box 352<br></br>
                            Germantown, NY 12526 <br></br>
                            Bruce Bohnsack <br></br>
                            (518) 537-6257<br></br>
                            <b><br></br>
                            Middleburg Telephone Corp. </b><br></br>
                            103 Cliff St. <br></br>
                            P.O. Box 191  <br></br>
                            Middleburgh, NY 12122 <br></br>
                            James Becker <br></br>
                            (518) 827-5211<br></br>
                            <b><br></br>
                            Newport Telephone Co. </b><br></br>
                            3087 Bridge Street<br></br>
                            Newport, NY 13416 <br></br>
                            L. Theodore Foss <br></br>
                            (315) 845-8112<br></br>
                            <br></br>
                            <br></br>
                        </p>
                    </div>
                    <div className = "col-5">
                    <p className = {styles.ContactDetails}><b>Nicholville Telephone Co. </b><br></br>
                    Water Street<br></br>
                    P.O. Box 122<br></br>
                    Nicholville, NY 12965-0122 <br></br>
                    (315) 328-4411 <br></br> <br></br>
                        <b>Ogden Telephone Co. (Citizens) </b><br></br>
                        21 West Avenue<br></br> 
                        Spencerport, NY 14559 <br></br>
                        (716) 352-3451 <br></br> <br></br>
                    <b>Oneida County Rural Telephone Co. </b><br></br>
                        9560 Main St.<br></br>
                        Holland Patent, NY 13354 <br></br>
                        Peter McCarthy <br></br>
                        (315) 865-5201<br></br>
                        <br></br>
                        <b>Pattersonville Telephone Co. </b><br></br>
                        277 Main Street <br></br>
                        Rotterdam Junction, NY 12150 <br></br>
                        (518) 887-2121<br></br>
                        <br></br>
                        <b>State Telephone Co.</b> <br></br>
                        46 Reed Street<br></br>
                        Coxsackie, NY 12051 <br></br>
                        (518) 731-6128<br></br>
                        <br></br>
                        <b>Taconic Telephone Co. </b><br></br>
                        Atten: Gerald Blass<br></br>
                        1 Taconic Place<br></br>
                        Chatham, NY 12037 <br></br>
                        (518) 392-1210<br></br>
                        <br></br>
                        <b>Township Telephone Co. </b><br></br>
                        d/b/a TDS Telecom <br></br>
                        P.O. Box 70<br></br>
                        Chaumont, NY 13622 <br></br>
                        (315) 649-2411<br></br>
                        <br></br>
                        <b>Verizon </b><br></br>
                        1095 Ave. of Americas<br></br>
                        New York, NY 10036 <br></br>
                        James Slavin <br></br>
                        (212) 395-7208<br></br>
                        <br></br>
                        <b>Vernon Telephone Co. </b><br></br>
                        d/b/a TDS Telecom <br></br>
                        Curtis Road<br></br>
                        P.O. Box 900, Vernon, NY 13476 <br></br>
                        Bill Nicholson <br></br>
                        (315) 829-2211</p>
                    </div>
                    <div className = "col-1">
                    <a href="pole.asp"><img alt = "" src={button_back} width="51" height="15" border="0" /></a><br></br>
                    <br></br>
                    <a href="../update_contactinfo.asp?type=Joint Pole Owners"><img alt = "" src={contact_card} width="132" height="99" border="0" /></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TowerTeleInfo;