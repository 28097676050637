import {React} from 'react'
import shared_line from '../../assets/images/shared_grn_line.gif';
import { useNavigate } from 'react-router-dom';
import SharedTransmission from "../transmission/non_html/shared_transmission_lgenintercagrmt.pdf"


function Generator1()
{  
    const navigate = useNavigate(); 
    function process_page()
    {
        navigate('/transmission/c4-1_newgenerator');
    }
      return(
        <>
            <p><small>In order for an Account Manager to contact you and complete your connection to our system, please review the following documentation and fill out our New Generator <a href = "https://www9.nationalgridus.com/transmission/c4-1_newgenerator_processb.asp?location=New%20England&thesecond=20MW%20or%20Greater&thethird=">Contact Form.</a></small></p>
                <p style ={{fontWeight : "bold"}}>Information for:</p>
            <table className="table-responsive table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Interconnection Procedure</th>
                        <th scope="col">Interconnection Agreement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href = "http://www.iso-ne.com/regulatory/tariff/sect_2/sch22/index.html" >ISO-NE Tariff Schedule 22, Section II*</a></td>
                        <td><a href = {SharedTransmission} >Standard Large Generator Interconnection Agreement</a>(pdf)</td>
                    </tr>
                </tbody>
                </table>
                <div className = "row mb-3">
                    <img alt="" style = {{width : "55%" , height : "2px" , marginTop : "4%"}} src = {shared_line}></img>
                    <button onClick = {process_page} style = {{maxWidth : "9%" , minWidth : "25%"  , marginTop : "2%"  ,  background: "#FFFFFF",  border: "1px solid #A9C7EC", color: "#006CB5",  fontweight: "bold"}}><small>back</small></button>
                </div>
       </>
    );
}

export default Generator1;