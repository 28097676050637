import React, { useState } from 'react';
import  '../../styles/transmission.css';
import shared_dot from '../../assets/images/shared_requiredinfo.gif';
import shared_line from '../../assets/images/shared_grn_line.gif';
import { useNavigate } from 'react-router-dom';


function Generator4() {

    const navigate = useNavigate(); 
    const[DDYN , setDDYN] = useState('None');
     
    const handleSize = (e) =>
    {
        setDDYN( e.target.value );
    }

    function process_page()
    {
        if(DDYN !== "None" )
        {
            navigate('/transmission/c4-1_newgenerator_process',  { state: { loc : "NY" , size : "20MW or Less" , Drop : DDYN} });

        }
        else
        {
            alert("Please check your entries in the field noted below \n \nmaking sure that all the required information has been completed \n \nWill you be selling the electric output of your generator to either the ISO or a marketer?")
        }
    }

    return(
        <>
        <div className = "row mt-3">
                <div className = "col-lg-5">
                    <p className = "Section">
                        Section 1 of 2
                    </p>
                </div>
                <div className = "col-lg-3">
                    <small>
                    (<img alt="" className = "dot" src = {shared_dot}></img> = required information)
                    </small>
                </div>
            </div>
            <div className = "row mt-1">
            <img alt="" style = {{width : "65%" , height : "2px"}} src = {shared_line}></img>
            </div>
            <div className = "row">
            </div>
            <div className = "row mt-3 mb-3">   
                <div className = "col-lg-4">
                <img alt="" className = "dot" src = {shared_dot}></img> Will you be selling the electric output of your generator to the New York ISO or a marketer?
                </div>
                <div className = "col-lg-3">
                <select  value={DDYN}  onChange = {handleSize}>
                    <option value="None">Select One</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Don't Know">Don't Know</option>
                </select>
                </div>
                <div className = "row mt-4">
                    <img alt="" style = {{width : "55%" , height : "2px"}} src = {shared_line}></img>
                    <button onClick = {process_page} style = {{maxWidth : "9%" , minWidth : "25%"  ,  marginTop : "-25px" , background: "#FFFFFF",  border: "1px solid #A9C7EC", color: "#006CB5",  fontweight: "bold"}}><small>Submit</small></button>
                </div>
            </div>
            </>
    );
}

export default Generator4