
import React from 'react';
import Helmet from 'react-helmet';
import Img_button_back from '../../../assets/images/button_back.gif';
import Img_shared_bullet_diamond from '../../../assets/images/shared_bullet_diamond.gif';


const P_Bottom = {
    "marginBottom":"0.7em"
}
function HighVoltageAct() {
    return (
        <div >
            <Helmet>
                <title>National Grid</title>
            </Helmet>

            <div className='row' style={{ paddingLeft: '10px', paddingTop: '5px', fontSize: '11px', fontFamily: 'Verdana, sans-serif' }}>
                <div style={{ minWidth: '92%', width: '250px' }}>
                    <div className='row'>
                        <div className='col-12'>
                            <p style={P_Bottom}>
                                <img src={Img_shared_bullet_diamond} width="9" height="12" border="0" alt="flowchart" style={{ verticalAlign: 'top', marginTop: '4px' }} /> <span className="Catv_PageHead"><b>High Voltage Proximity Act</b></span>
                            </p>
                            <div className='row' style={{ paddingLeft: '10px' }} >
                                <p style={P_Bottom}>
                                    <b>CODE RULE 57 AND EXTRACTS OF THE LABOR LAW </b>
                                </p>
                                <p style={P_Bottom}>
                                    <b>SECTION 202-h. High-voltage proximity.</b>
                                </p>
                                <p style={P_Bottom}>
                                    <b>1. This section may be known as the "high-voltage proximity act".</b>
                                </p>
                                <p style={P_Bottom}>
                                    <b>2. Definitions. For the purpose of this section: </b>  (a) "High-voltage lines"" means electrical conductors installed above ground and having a voltage differential in excess of six hundred volts between any pair of conductors or between any conductor and ground. In the case of alternating current, the voltage shall be measured in R.M.S. value. This definition shall not include approved armored cable used to supply power to portable equipment and insulated power cables enclosed in approved metallic raceways. (b) "Dangerous proximity" means a distance within ten feet of high-voltage lines, or within such greater distances as are set forth in the current editions and any subsequent revisions of the regulations of the United States Occupational Safety and Health Administration (29 CFR parts 1910 and 1926), the New York industrial code (12 NYCRR part 23) and the national electrical safety code.
                                </p>
                                <p style={P_Bottom}>
                                    <b>3. Prohibited activity.</b> (a) No employer or supervising agent of an employer shall require or permit an employee to, and no self-employed individual, independent contractor having no employees or homeowner shall, participate in the operation, erection, transportation, handling, or storage of any tools, machinery, equipment, supplies,
                                    materials or apparatus, or the moving of any building, if in the course of such operation, erection, transportation, handling, storage or moving it is possible for such tools, machinery, equipment, supplies, materials, apparatus or building, to come within dangerous proximity of a high-voltage line; or participate in any activity which would
                                    cause the employee, self-employed individual, independent contractor or homeowner to come within dangerous proximity of a high-voltage line; unless precautionary action has been taken to protect against the danger from contact with such high-voltage line, either by de-energizing such high-voltage line and grounding it where necessary, or other
                                    effective methods or devices which have been approved in advance by the owner or person in charge of such high-voltage lines for the particular case and for the particular location. (b) Employers whose employees operate, erect, transport, handle or store any tools, machinery, equipment, supplies, materials or apparatus, or move any building,
                                    which in the course of such operation, erection, transportation, handling, storing or moving might come within dangerous proximity of a high-voltage line, shall advise such employees of the dangers inherent in such work, highlight precautions which are to be taken under such circumstances and encourage employees to communicate with and advise
                                    employers or their supervising agents of conditions which would require precautionary action by the employer as required by paragraph (a) of this subdivision. (c) In no case shall the required clearance be provided by moving or displacing any conductor, except where the same is temporarily relocated pursuant to arrangements made with the owner
                                    or person in charge of the high-voltage line, and such actions are performed by such owner or person in charge. (d) All high-voltage lines shall be considered as energized high-voltage lines until assurance has been given that they are otherwise by qualified representatives of the owners or persons in charge of such lines.
                                </p>

                                <p style={P_Bottom}>
                                    <b>4. Warning sign required.</b> (a) The owner, agent, lessee, bailee, user, or employer responsible for the operation of equipment capable of coming within dangerous proximity of a high-voltage line in the course of its operation, shall post and maintain in plain view of the operator on each piece of such equipment, an approved durable warning
                                    sign legible at a distance of twelve feet reading "Danger Unlawful To Operate Any Part Of This Equipment Within 10 Feet of High-Voltage Lines". Additional warning signs shall be placed on various parts of the equipment providing similar warnings to others in the vicinity of the high-voltage lines. Notwithstanding the foregoing, all such posted
                                    warnings shall specify the actual distance by which the term "dangerous proximity" is defined by paragraph (b) of subdivision two of this section. The owner, agent, lessee, bailee, user, or employer responsible for the operations of equipment shall provide such other warning signs on equipment or at the work site as may be required by regulations
                                    promulgated hereunder. The requirement that warning signs be posted shall not apply to railway equipment operating on railway right-of-way in relation to high-voltage conductors of such railway system under conditions for which exemption is granted under subdivision eight of this section. (b) If for any reason and for any time period, the operator
                                    of the equipment is unable to assess visually the clearance of the equipment from overhead high-voltage lines, a second person shall be designated to observe the clearance and provide timely warning to the equipment operator.
                                </p>
                                <p style={P_Bottom}>
                                    <b>5. Notification to power company and responsibility for safeguards.</b> Whenever any activity is to be performed requiring precautionary action under this section, the employer, contractor or other person responsible for the activity shall promptly notify the owner or person in charge of the high-voltage line of the intended activity, such
                                    notification to be submitted at least five normal work days before the activity is to be performed. The owner or person responsible for the high-voltage line shall perform all necessary precautionary actions, and the employer, contractor or other person responsible for the activity shall be responsible for all costs of such precautionary actions.
                                    Under no circumstances shall activities requiring precautionary actions be undertaken before such precautionary actions have been completed.
                                </p>
                                <p style={P_Bottom}>
                                    <b>6. Enforcement.</b> The commissioner shall administer and enforce the provisions of this section and is hereby empowered to prescribe and promulgate rules and regulations consistent herewith. Such regulations shall include a requirement that clearances greater than the dangerous proximity distance must be maintained where, in the judgment of the board, safety so requires.
                                </p>
                                <p style={P_Bottom}>
                                    <b>7. Civil Penalty.</b> (a) Any person violating any of the provisions of this section shall be liable for a penalty of not less than one hundred dollars nor more than one thousand dollars to be collected in a civil action by a summary proceeding. Any violation of this section by an officer, agent or employee shall be a violation by the employer
                                    if such employer had knowledge of and actual control over the cause of such violation. Where the violation is of a continuing nature, each day during which it continues shall constitute an additional, separate and distinct offense. (b) The commissioner is hereby authorized and empowered to compromise and settle any claim for a penalty under
                                    this section in such amount in the discretion of the commissioner as may appear appropriate and equitable under the circumstances.
                                </p>
                                <p style={P_Bottom}>
                                    <b>8. Exceptions.</b> (a) This section shall not be construed as applying to, shall not apply to, and is not intended to apply to, the construction, reconstruction, operations, and maintenance of overhead electrical conductors and their supporting structures and associated equipment by authorized and qualified electrical workers; nor to the
                                    authorized and qualified employees of any person engaged in the construction, reconstruction, operation, and maintenance of overhead electrical circuits or conductors and their supporting structures and associated equipment of rail transportation systems, or electrical generating, transmission, distribution, and communication systems. This
                                    exception when applied to railway systems shall not be construed as permitting operation of standard rail equipment, which is normally used in the transportation of freight or passengers or both and the operation of relief trains, or other equipment in emergencies, or in maintenance of way service, within dangerous proximity of and high-voltage
                                    conductor of such railway system; but this section shall be construed as prohibiting normal repair or construction operations within dangerous proximity of any high-voltage conductor by other than qualified and authorized persons or employees under the direct supervision of an authorized person who is familiar with the hazards involved, unless
                                    there has been compliance with the safety provisions hereof. (b) This section shall not be construed as applying to motor vehicle transportation across or along a public road or highway where the combined vehicle and load shall not be in excess of thirteen and one-half feet high and thirteen feet wide. Nevertheless, this section shall apply
                                    to motor vehicle transportation across or along a public road or highway during such time as the combined vehicle and load shall for any reason and for any period of time be in excess of thirteen and one-half feet high and thirteen feet wide.
                                </p>
                                <p style={P_Bottom}>
                                    <b>9. Severability.</b> In case any provisions of this section shall be adjudged unconstitutional or void for any reason, such adjudication shall not affect any of the other provisions of this section.
                                </p>

                                <p style={P_Bottom}>
                                    <b>PART 57</b> <b>HIGH VOLTAGE PROXIMITY</b>
                                </p>
                                <p style={P_Bottom}>
                                    (Statutory authority: Labor Law, &#167; 202-h) <br />
                                    Sec.<br />
                                    57.1 Title and citation<br />
                                    57.2 Purpose and intent of Part (rule)<br />
                                    57.3 Application<br />
                                    57.4 Definitions<br />
                                    57.5 Precautionary action<br />
                                    57.6 Employee information<br />
                                    57.7 High-voltage line proximity procedure<br />
                                    57.8 Warning signs<br />
                                    57.9 Civil penalty<br />
                                    57.10 Severability
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b><br />
                                    Part (&#167; 57.1-57.10) filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>

                                <p style={P_Bottom}>
                                    <b>&#167; 57.1Title and citation.</b>
                                </p>
                                <p style={P_Bottom}>
                                    Within and for the purposes of the Department of Labor, this Part (rule) may be known as Industrial Code Rule No. 57, relating to hazards to untrained persons working or moving materials or equipment in proximity to high-voltage power lines. It may be cited as Code Rule 57 High Voltage Proximity as an alternative and without prejudice to its designation and citation established by the Secretary of State.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.2 Purpose and intent of Part (rule).</b>
                                </p>

                                <p style={P_Bottom}>
                                    (a) Legislative findings. The Legislature has found that untrained persons working or moving materials or equipment in proximity to high-voltage power lines are endangered in the absence of suitable precautions, and that the quality of electrical service is likewise endangered by the absence of such precautions.
                                </p>
                                <p style={P_Bottom}>
                                    (b) Purpose and intent. It is the purpose and intent of this Part (rule) to reduce the risks to untrained persons working or moving materials or equipment in proximity to high-voltage power lines, and to help insure the quality of electrical service by requiring compliance by said persons with certain procedures that will insure reasonable protection to said persons and to the high-voltage power lines in proximity to work locations or where materials or equipment are moved.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                     <b>&#167; 57.3 Application.</b>
                                </p>
                                <p style={P_Bottom}>
                                    (a) This Part (rule) shall apply throughout the State of New York to any employer, any agent of an employer who supervises employees, self-employed individuals, independent contractors having no employees and homeowners, subject to the exceptions set forth in subdivision (b) of this section.
                                </p>
                                <p style={P_Bottom}>
                                    (b) The provisions of this Part (rule) shall not apply to: (1) the construction, reconstruction, operations, and maintenance of overhead electrical conductors and their supporting structures and associated equipment by authorized and qualified electrical workers;
                                </p>
                                <p style={P_Bottom}>
                                    (2) the authorized and qualified employees of any person engaged in the construction, reconstruction, operation, and maintenance of overhead electrical circuits or conductors and their supporting structures and associated equipment of rail transportation systems or electrical generating, transmission, distribution, and communication systems; and
                                </p>
                                <p style={P_Bottom}>
                                    (3) motor vehicle transportation across or along a public road or highway where the combined vehicle and load is not at such time in excess of 13� feet high and 13 feet wide. Note: The exception set forth in paragraph (b)(2) of this section, when applied to railway systems, shall be construed as permitting operation of stan- dard rail equipment, which is normally used in the transportation of freight or passengers or both and the operation of relief trains, or other equipment in emergencies, or in maintenance of way service, within dangerous proximity of any high-voltage conductor of such railway system. Normal repair or construction operations within dangerous proximity of any high-voltage conductor shall be prohibited under this Part unless performed by properly qualified and authorized persons or employees under the direct supervision of an authorized person famil- iar with the hazards involved, unless there has been compliance with the safety provisions set forth in this Part.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.4 Definitions.</b>
                                </p>
                                <p style={P_Bottom}>
                                    As used in or in connection with this Part (rule) the following terms shall mean:
                                </p>
                                <p style={P_Bottom}>
                                    (a) High-voltage lines. Electrical conductors installed aboveground and having a voltage differential in excess of 600 volts between any pair of conductors or between any conductor and ground. In the case of alternating current, the voltage shall be measured in R.M.S. value. All high voltage lines shall be considered as energized high-voltage lines until assurance has been given that they are otherwise by qualified representatives of the owners or persons in charge of such lines. Exception: This definition shall not include approved armored cable used to supply power to portable equipment and insulated power cables enclosed in approved metallic raceways.
                                </p>
                                <p style={P_Bottom}>
                                    (b) Dangerous proximity. As defined in Labor Law, section 202-h, dangerous proximity shall be the distance within 10 feet of high-voltage lines, or within such greater distances as are set forth in the 1990 edition of the regulations of the United States Occupational Safety and Health Administration (29 CFR parts 1910 and 1926), the New York Industrial Code (12 NYCRR Part 23) and the National Electrical Safety Code, 1990 edition, published by the American National Standards Institute, 11 West 42nd Street, New York, NY 10036.
                                </p>
                                <p style={P_Bottom}>
                                    (c) R.M.S. value. The greatest effective difference of potential between any two conductors of the circuit concerned.
                                </p>
                                <p style={P_Bottom}>
                                    (d) Prohibited activity. The operation, erection, transportation, handling, or storage of any tools, machinery, equipment, supplies, material or apparatus, or the moving of any building, if in the course of such operation, erection, transportation, handling, storage or moving of such objects it is possible to come within dangerous proximity of a high-voltage line.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.5 Precautionary action.</b>
                                </p>
                                <p style={P_Bottom}>
                                    No individual covered by the provisions of this Part (rule) shall engage in, or require a person employed by him/her to engage in any prohibited activity unless precautionary action has been taken to protect against the danger from contact with a high-voltage line, either by de-energizing such high-voltage line and grounding it where necessary, or by other effective methods or devices which have been approved in advance for the particular case and for the particular location by the owner or persons in charge of such high-voltage lines.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.6 Employee information.</b>
                                </p>
                                <p style={P_Bottom}>
                                    (a) Before allowing any employee to engage in prohibited activity, employers subject to the provisions of this Part (rule) shall: (1) advise such employee of the dangers inherent in the operation, erection, transportation, handling, storage or moving of any tools, machinery, equipment, supplies, materials, apparatus or building in dangerous proximity to high-voltage lines;
                                </p>
                                <p style={P_Bottom}>
                                    (2) highlight precautions which are to be taken prior to or in conjunction with engaging in such prohibited activity;
                                </p>
                                <p style={P_Bottom}>
                                    (3) encourage employees to communicate with and advise employers or their supervising agents of conditions which would involve precautionary action by the employer as required under section 57.5 of this Part (rule).
                                </p>
                                <p style={P_Bottom}>
                                    (b) The information required under paragraphs (a)(1) and (3) of this section shall be provided to the employee in writing and shall be clear, concise and easily understood. Such written information shall contain the location and phone number where the employer or his/her supervising agent may be reached for the purpose of reporting conditions which would involve precautionary action.
                                </p>
                                <p style={P_Bottom}>
                                    (c) The information required under paragraph (a)(2) of this section shall be provided to the employee verbally or in writing and shall be related to the specific circumstances of the prohibited activity involved.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.7 High-voltage line proximity procedure.</b>
                                </p>
                                <p style={P_Bottom}>
                                    (a) At any site where any person or equipment may possibly come within dangerous proximity of a high-voltage line, such approach shall not be made until the following procedure has been complied with: (1) The employer, contractor or other person making such approach or who is responsible for such activity, shall promptly notify the owner or
                                    person in charge of such high-voltage line in writing at least five normal work days before such approach is to be made. If the notification is made by regular mail, three extra days notice shall be given. Exception: In any emergency situation involving imminent danger to the life, health or safety of any person, the person responsible for
                                    such activity is not required to comply with this provision. (2) Within three normal working days following the receipt of such written notice, the owner or person in charge of the high-voltage line shall respond to the person making such approach or who is responsible for such activity of the procedure to be followed prior to performing any
                                    work in dangerous proximity to such high-voltage line.
                                </p>
                                <p style={P_Bottom}>
                                    (3) The owner or person responsible for the high-voltage line shall perform all necessary precautionary actions to be taken to protect against the danger from contact with such high-voltage line, either by de-energizing such high-voltage line and grounding it where necessary, or by approving in advance other effective methods or devices for
                                    the particular cases and for the particular locations.
                                </p>
                                <p style={P_Bottom}>
                                    (4) The employer, contractor or other person responsible for the activity within dangerous proximity of such high-voltage line shall be responsible for all costs incurred in connection with such precautionary action including determining what precautionary measures are necessary and preparations for implementing them whether or not such precautionary measures are actually implemented.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.8 Warning signs.</b>
                                </p>
                                <p style={P_Bottom}>
                                    (a) The owner, agent, lessee, bailee, user, or employer responsible for the operations of equipment capable of coming within dangerous proximity of a high-voltage line in the course of its operation, shall post and maintain in plain view of the operator on each piece of such equipment, an approved durable warning sign legible at a distance
                                    of 12 feet. Every such warning sign shall bear the following legend in black letters on a yellow background:
                                </p>
                                <p style={P_Bottom}>DANGER<br />
                                    Unlawful To Operate Any Part<br />
                                    Of This Equipment Within 10 Feet Of<br />
                                    High-Voltage Lines
                                </p>
                                <p style={P_Bottom}>
                                    (b) All posted warning signs shall specify the actual distance by which the term "dangerous proximity" is defined for the particular case and location to which this Part (rule) applies.
                                </p>
                                <p style={P_Bottom}>
                                    (c) Additional warning signs shall be placed on various parts of the equipment and at the work site providing similar warnings to others in the vicinity of the high-voltage lines.
                                </p>
                                <p style={P_Bottom}>
                                    (d) If for any reason and for any time period, the operator of the equipment is unable to assess visually the clearance of the equipment from overhead high-voltage lines, a second person shall be designated to observe the clearance and provide timely warning to the equipment operator.
                                </p>

                                <p style={P_Bottom}><b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                                <p style={P_Bottom}>
                                    <b>&#167; 57.9 Civil penalty.</b>
                                </p>
                                <p style={P_Bottom}>
                                    Any person violating any of the provisions of this section shall be liable for a penalty of not less than $100 nor more than $1,000 to be collected in a civil action by a summary proceeding. Any violation of this section by an officer, agent or employee shall be a violation by the employer if such employer had knowledge of and actual control over the cause of such violation. Where the violation is of a continuing nature, each day during which it continues shall constitute an additional, separate and distinct offense.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>

                                <p style={P_Bottom}>
                                    <b>&#167; 57.10 Severability.</b>
                                </p>
                                <p style={P_Bottom}>
                                    If any provision of this Part (rule) or the application thereof to any person or circumstance is held invalid, such invalidity shall not affect other provisions or applications of this Part (rule) which can be given effect without the invalid provisions or applications and to this end the provisions of this Part (rule) are declared to be severable.
                                </p>
                                <p style={P_Bottom}>
                                    <b>Historical Note</b>
                                    Sec. filed Sept. 15, 1992 eff. Sept. 30, 1992.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ minWidth: '8%', width: '60px' }}>
                    <div style={{ paddingTop: '5px', paddingLeft: '10px', paddingRight: '10px' }}>
                        <a href="policies">
                            <img src={Img_button_back} width="51" height="15" border="0" alt="backbutton" />
                        </a>
                    </div>
                </div>
            </div>
            <p style={P_Bottom}>&nbsp;</p>
        </div >
    );
}
export default HighVoltageAct;