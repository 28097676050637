export const contactList =
{
    "Albany, City Of":
        [{
            sContact: "Jon Moscovic",
            sPhone: "518-433-3359",
            sEmail: "Jon.Moscovic@us.ngrid.com",
            sFax: "518-433-5144",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1125 Broadway",
            sCityState: "Albany, NY  12204-2505",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Portville, Town Of":
        [{
            sContact: "Judith Giminski",
            sPhone: "716-375-4234",
            sEmail: "Judith.Guminski@us.ngrid.com",
            sFax: "716-372-1934",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1411 West Henley St.",
            sCityState: "Olean, NY  14760-2511",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Dunkirk, City Of": [{
        sContact: "Brian Seibert",
        sPhone: "716-673-7302",
        sEmail: "Brian.Seibert@us.ngrid.com",
        sFax: "716-831-5215",
        sDisti: "Niagara Mohawk",
        sDept: "Telecom Attachments",
        sMailingAddress: "401 Water St.",
        sCityState: "Fredonia, NY  14063-9599",
        sPdfForm: "pfd_forma.pdf"
    }],
    "Cortland, City  Of": [{
        sContact: "Mike Bogdan",
        sPhone: "315-428-5614",
        sEmail: "Michael.Bogdan@us.ngrid.com",
        sFax: "315-460-8953",
        sDisti: "Niagara Mohawk",
        sDept: "Telecom Attachments",
        sMailingAddress: "7496 Round Pond Rd.",
        sCityState: "N. Syracuse, NY 13212",
        sPdfForm: "pfd_forma.pdf"
    }],
    "Amherst, Town Of":
        [{
            sContact: "George Phillips",
            sPhone: "716-831-7703",
            sEmail: "George.Phillips@us.ngrid.com",
            sFax: "716-855-3692",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "93 Dewey Ave.",
            sCityState: " Buffalo, NY  14214-2495",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Batavia, City Of":
        [{
            sContact: "George Phillips",
            sPhone: "716-831-7703",
            sEmail: "George.Phillips@us.ngrid.com",
            sFax: "716-855-3692",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "93 Dewey Ave.",
            sCityState: " Buffalo, NY  14214-2495",
            sPdfForm: "pfd_forma.pdf"

        }],
    "Herkimer, Village of T Herkimer":
        [{
            sContact: "Mike Bogdan",
            sPhone: "315-428-5614",
            sEmail: "Michael.Bogdan@us.ngrid.com",
            sFax: "315-460-8953",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "7496 Round Pond Rd.",
            sCityState: "N. Syracuse, NY 13212",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Watertown, City Of":
        [{
            sContact: "Mike Bogdan",
            sPhone: "315-428-5614",
            sEmail: "Michael.Bogdan@us.ngrid.com",
            sFax: "315-460-8953",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "7496 Round Pond Rd.",
            sCityState: "N. Syracuse, NY 13212",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Cazenovia, Village of T Cazenovia":
        [{
            sContact: "Mike Bogdan",
            sPhone: "315-428-5614",
            sEmail: "Michael.Bogdan@us.ngrid.com",
            sFax: "315-460-8953",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "7496 Round Pond Rd.",
            sCityState: "N. Syracuse, NY 13212",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Newfane, Town Of":
        [{
            sContact: "George Phillips",
            sPhone: "716-831-7703",
            sEmail: "George.Phillips@us.ngrid.com",
            sFax: "716-855-3692",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "93 Dewey Ave.",
            sCityState: " Buffalo, NY  14214-2495",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Utica, City Of": [{
        sContact: "Mike Bogdan",
        sPhone: "315-428-5614",
        sEmail: "Michael.Bogdan@us.ngrid.com",
        sFax: "315-460-8953",
        sDisti: "Niagara Mohawk",
        sDept: "Telecom Attachments",
        sMailingAddress: "7496 Round Pond Rd.",
        sCityState: "N. Syracuse, NY 13212",
        sPdfForm: "pfd_forma.pdf"
    }],
    "Solvay, Village of T Geddes":
        [{
            sContact: "Mike Bogdan",
            sPhone: "315-428-5614",
            sEmail: "Michael.Bogdan@us.ngrid.com",
            sFax: "315-460-8953",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "7496 Round Pond Rd.",
            sCityState: "N. Syracuse, NY 13212",
            sPdfForm: "pfd_forma.pdf"

        }],
    "Minetto, Town Of":
        [{
            sContact: "Mike Bogdan",
            sPhone: "315-428-5614",
            sEmail: "Michael.Bogdan@us.ngrid.com",
            sFax: "315-460-8953",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "7496 Round Pond Rd.",
            sCityState: "N. Syracuse, NY 13212",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Rensselaer, City Of":
        [{
            sContact: "Jon Moscovic",
            sPhone: "518-433-3359",
            sEmail: "Jon.Moscovic@us.ngrid.com",
            sFax: "518-433-5144",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1125 Broadway",
            sCityState: "Albany, NY  12204-2505",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Clifton Park, Town Of":
        [{
            sContact: "Jon Moscovic", sPhone: "518-433-3359",
            sEmail: "Jon.Moscovic@us.ngrid.com",
            sFax: "518-433-5144",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1125 Broadway",
            sCityState: "Albany, NY  12204-2505",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Niskayuna, Town Of":
        [{
            sContact: "Jon Moscovic",
            sPhone: "518-433-3359",
            sEmail: "Jon.Moscovic@us.ngrid.com",
            sFax: "518-433-5144",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1125 Broadway",
            sCityState: "Albany, NY  12204-2505",
            sPdfForm: "pfd_forma.pdf"
        }],
    "Glens Falls, City Of":
        [{
            sContact: "Jon Moscovic",
            sPhone: "518-433-3359",
            sEmail: "Jon.Moscovic@us.ngrid.com",
            sFax: "518-433-5144",
            sDisti: "Niagara Mohawk",
            sDept: "Telecom Attachments",
            sMailingAddress: "1125 Broadway",
            sCityState: "Albany, NY  12204-2505",
            sPdfForm: "pfd_forma.pdf"
        }],
    "NA":
        [{
            sContact: "",
            sPhone: "",
            sEmail: "",
            sFax: "",
            sDisti: "",
            sDept: "",
            sMailingAddress: "",
            sCityState: "",
            sPdfForm: ""
        }]
}
export default contactList;