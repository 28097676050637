import React from "react";
import styles from "../../styles/transmission.module.css";
import English_Public from "../transmission/non_html/English_Public_Hearing_Notice.pdf"
import English_DPU from "../transmission/non_html/English_DPU_Hearing_Notice.pdf"
import Espanol_Aviso from "../transmission/non_html/Espanol_Aviso_De_Audiencia_Publica.pdf"
import Espanol_DPU from "../transmission/non_html/Espanol_DPU_Aviso_De_Audiencia_Publica.pdf"
export default function GoldenRockProject() {
  return (
    <div style={{ padding: "10px" }}>
      <div id="transmissionleftnav" className={styles.transmissionleftnav}>
        <div className={styles.LeftSideBox}></div>
      </div>
      <div id="actualcontent" className={styles.clearfix}>
        <div className={styles.div_innerContent}>
          <span className={styles.divpagehead}>
            Golden Rock Substation & Transmission Line Project.
          </span>
          <br />
          <div className={styles.textindent}>
            <strong>
              National Grid is committed to providing safe and reliable energy
              to our customers. In line with this commitment, we are undertaking
              the Golden Rock Substation and Transmission Line Project.
            </strong>
          </div>
          <br />
          <strong>
            A strong electric transmission and distribution system is vital to
            our region's safety, security, and economic prosperity.
          </strong>
          National Grid and Liberty Utilities are proposing equipment upgrades
          to the Golden Rock Substation and regional transmission and
          distribution lines to meet increasing electric demand, improve overall
          system reliability and support economic development in the Salem area.
          <br />
          <br />
          <strong>What:</strong> Proposed equipment upgrades include a second
          115/13 kV transformer and four 13 kV distribution feeders, at National
          Grid's Golden Rock Substation in Salem, NH. National Grid will also be
          rebuilding the transmission line that serves the Golden Rock
          Substation to add a second 115 kV circuit that supports the new
          substation equipment.
          <br />
          <br />
          <strong>Who:</strong> Liberty Utilities is the electric service
          provider for the Salem, New Hampshire area. As one of the region's
          electricity providers, National Grid provides transmission service to
          Liberty Utilities. <br />
          <br />
          <strong>Where:</strong> The substation work will occur at National
          Grid's existing substation located at 16 Hampshire Road, Salem, New
          Hampshire. The transmission line work will take place along a roughly
          one-mile existing transmission line right-of-way (ROW) paralleling the
          Spicket River in Methuen, Massachusetts. The locations of the
          substation and ROW are shown on the aerial photograph on the back of
          this sheet.
          <br />
          <br />
          <strong>Community Collaboration:</strong> National Grid and Liberty
          Utilities are committed to keeping residents, businesses, local
          officials and community groups informed about and engaged in the
          project. If you have any questions, please feel free to reach out to
          the contacts listed below - we are here for you!
          <br />
          <br />
        </div>
        <div className={styles.div_right_side}>
          <span className={styles.div_subHeadingText}>Related Information</span>
          <div className={styles.div_rightside_content}>
            <p className={styles.rightside_content_p}>
              <b>
                To view our Application to the Massachusetts Department of
                Public Utilities, please visit the DPU's file room at{" "}
                <a href="https://eeaonline.eea.state.ma.us/DPU/Fileroom/dockets/bynumber" target="_blank" rel="noreferrer">
                  https://eeaonline.eea.state.ma.us/
                </a>{" "}
                search for docket 19-16
              </b>
              <b>
                <br />
                <br />
                Public Hearing Notice{" "}
                <a href={English_Public} target="_blank" rel="noreferrer">English </a>
                <a href={Espanol_Aviso} target="_blank" rel="noreferrer">Espanol</a>
              </b>
              <br />
              <br />
              <b>
                
                DPU Notice of Public Comment Hearing{" "}<br />
                <a href={English_DPU} target="_blank" rel="noreferrer">English </a>
                <a href={Espanol_DPU} target="_blank" rel="noreferrer">Espanol</a>
              </b>
              <br />
              <br />
              <b>Stakeholder relations Lead:</b>
              <br /> Kendra McAuliffe
              <br />
              781-460-8619
              <br />
              <a href="mailto:Kendra.McAuliffe@nationalgrid.com">
                Kendra.McAuliffe@nationalgrid.com
              </a>
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}